import React from "react"
import {
    Row,
    Col,
    ModalHeader, 
    ModalBody,
    ModalFooter,
    Button,
    Alert
} from "reactstrap"
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux'
import action_middleware from '../../../redux/action-creator'
import agent from '../../../services/api-endpoint'
import {
    CHANGE_OLD_PASSWORD
} from '../../../redux/actions/actionTypes'
import Notification from '../../../components/Custom/Notification'



class ChangePasswordCustomer extends React.Component {
    state = {
        old_password: '',
        password: '',
        confirm_password: '',
        loading: false,
        notification: null
    }
    componentDidMount = () => this.mount = true
    componentWillUnmount = () => this.mount = false
    handleChange = e => {
        console.log(e.target.name, e.target.value)
        let {name, value} = e.target
        this.setState({
            [name]: value
        })
    }
    updatePassword = e => {
        e.preventDefault()
        const {old_password, password, confirm_password} = this.state
        this.setState({loading: true}, () => {
            this.props.action_middleware( CHANGE_OLD_PASSWORD, agent.Auth.change_old_password( {old_password, password, confirm_password}, this.props.token )
            ).then(store_state => {
                this.setState({
                    loading: false,
                    notification: store_state.notification,
                    old_password: '',
                    password: '',
                    confirm_password: ''
                })
                if(store_state.temp.isPassChanged){
                    setTimeout(() => {
                        if(this.mount) this.props.toggle()
                    }, 3500)
                }
            })
        })
    }
    onDismiss = () => {
        this.setState({notification: null})
        // this.props.toggle()
    }


    render(){
        let {notification} = this.state
        return(
            <form onSubmit={this.updatePassword}>
                <ModalHeader toggle={this.props.toggle}>Change Password</ModalHeader>
                <ModalBody>
                    {
                        (notification && 'change_password' in notification) && 
                        <Notification delay="5000" onExpire={this.onDismiss}>
                            <div className="alert-section">
                                <Alert
                                    color={notification.change_password.status}
                                    isOpen={notification.change_password && true}
                                    toggle={this.onDismiss}
                                    className ="fadeIn slow"
                                >
                                    <strong>{notification.change_password.msg}</strong>
                                </Alert>
                            </div>
                        </Notification>
                    }
                    <Row>
                        <Col md="12">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        //id="old_pass"
                                        label="Old Password"
                                        placeholder="Enter Old Password"
                                        type="password"
                                        name="old_password"
                                        value={this.state.old_password}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                        <Col md="12">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        //id="new_pass"
                                        label="New Password"
                                        placeholder="Enter New Password"
                                        type="password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                        <Col md="12">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        //id="confirm_pass"
                                        label="Confirm Password"
                                        placeholder="Enter Confirm Password"
                                        type="password"
                                        name="confirm_password"
                                        value={this.state.confirm_password}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button 
                        color='primary' 
                        // onClick={this.updatePassword} 
                        className="change-pass-btn ml-auto"
                    >
                        {this.state.loading ? 'Updating...' : 'Update'}
                    </Button>
                </ModalFooter>
            </form>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.auth.token
    }
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordCustomer)