import {
    LOGIN_OTP_REQUEST, 
    REG_OTP_REQUEST,
    CHANGE_OLD_PASSWORD
} from '../actions/actionTypes'
import agent from '../../services/api-endpoint'


let initialState = {
    // sent_otp: false
};


const TempReducer = (state=initialState, action) => {

    switch (action.type) {

        case LOGIN_OTP_REQUEST:
        case REG_OTP_REQUEST: {
            let { success, payload } = action
            if(success){
                return{
                    ...state,
                    sent_otp:  payload.success
                }
            }
        }

        case CHANGE_OLD_PASSWORD: {
            let { success, payload } = action
            if(success){
                return {
                    isPassChanged: payload.success
                }
            }
        }
        
        default:
            return state;
    }
}

export default TempReducer