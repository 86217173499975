import React, {useState} from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Container,
    Col,
    NavLink,
    Row,
    FormGroup,
    Label,
    Input,
    FormText,
    Alert
} from "reactstrap";
import queryString from 'query-string'

//material form added 
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";

import Notification from '../../components/Custom/Notification'
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import action_middleware from '../../redux/action-creator'
import {
    CHANGE_PASSWORD,
    CLEAR_NOTIFICATION_MSG
} from '../../redux/actions/actionTypes'
import agent from '../../services/api-endpoint'

class ChangePassword extends React.Component {
    state = {
        value: null,
        isValid: false,
        password: '',
        confirm_password: '',
        loading: false,
        notification: null
    };
    componentDidMount = () => {
        // document.documentElement.scrollTop = 0;
        // document.scrollingElement.scrollTop = 0;
        document.body.classList.add("page");

        const value = queryString.parse(this.props.location.search)
        if(value.token){
            this.setState({ 
                value,
                isValid: 'token' in value
            })
        }
    }
    componentWillUnmount = () => document.body.classList.remove("page")
    
    handleChange = e => {
        let {name, value} = e.target
        this.setState({ [name]: value })
    }
    handleChangePassword = e => {
        e.preventDefault()
        const {value, password, confirm_password} = this.state
        if(password && confirm_password){
            this.setState({
                loading: true,
                notification: null
            }, () => {
                let obj = Object.assign(value, {password, confirm_password})
                this.props.action_middleware(CHANGE_PASSWORD, agent.Auth.change_password(obj)).then(() => {
                    this.setState({ 
                        loading: false,
                        notification: this.props.notification,
                        password: '',
                        confirm_password: ''
                    })
                })
            })
        }
    }
    onDismiss = () => this.setState({notification: null})


    render() {
        const {notification} = this.state
        return (
            <>
                <CustomNavbar />
                <div className="wrapper">
                    <div className="main">
                        <div className="space-100" />
                        <section className="section registration-sec">
                            <Container>
                                <div className="store-owener-login-main-section">
                                    <Row>
                                        <Col md='12'>
                                            <div className="login-item sign-in ">
                                                {
                                                    !this.state.isValid ?

                                                    <div className="missing-section-main">
                                                        <Row>
                                                            <Col>
                                                                <h1><span>No One </span><br/>Knows Insider</h1>
                                                                <p>
                                                                    Oop, that link is broken. Page doesn’t exist or some other error occured. If you are registered store owner, Go to register page. please 
                                                                </p>
                                                                <Link to={agent.site_slug.CUSTOMER_SIGN_UP}>
                                                                    <button 
                                                                        className="btn btn-primary missing-btn"
                                                                    >Click here to Login</button>
                                                                </Link>
                                                            </Col>
                                                            <Col>
                                                                <img 
                                                                    src={require("assets/img/password.png")} 
                                                                    className="d-block" 
                                                                    alt="..."
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    :
                                                
                                                    <Card className="card-change-pass auth-card animated fadeIn slow">
                                                        <Form className="form" onSubmit={this.handleChangePassword}>
                                                            <CardHeader className="text-center auth-card-header">
                                                                <CardTitle tag="h4">Change Password</CardTitle>
                                                            </CardHeader>
                                                            <CardBody className="auth-card-body">
                                                                {
                                                                    (notification && 'change_password' in notification) && 
                                                                    <Notification delay="5000" onExpire={this.onDismiss}>
                                                                        <div className="alert-section">
                                                                            <Alert
                                                                                color={notification.change_password.status}
                                                                                isOpen={notification.change_password && true}
                                                                                toggle={this.onDismiss}
                                                                                className ="fadeIn slow"
                                                                            >
                                                                                <strong>{notification.change_password.msg}</strong>
                                                                            </Alert>
                                                                        </div>
                                                                    </Notification>
                                                                }
                                                                <Grid container spacing={1} alignItems="flex-end">
                                                                    <Grid item sm={1}>
                                                                        <Icon className="fas fa-key" />
                                                                    </Grid>
                                                                    <Grid item sm={11}>
                                                                        <TextField
                                                                            id="input-with-icon-grid"
                                                                            label="Password"
                                                                            placeholder="Enter Password"
                                                                            type="password"
                                                                            fullWidth
                                                                            name="password"
                                                                            value={this.state.password}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={1} alignItems="flex-end">
                                                                    <Grid item sm={1}>
                                                                        <Icon className="fas fa-key" />
                                                                    </Grid>
                                                                    <Grid item sm={11}>
                                                                        <TextField
                                                                            id="input-with-icon-grid"
                                                                            label="Re-enter Password"
                                                                            placeholder="Re-enter Password"
                                                                            type="password"
                                                                            fullWidth
                                                                            name="confirm_password"
                                                                            value={this.state.confirm_password}
                                                                            onChange={this.handleChange}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </CardBody>
                                                            <CardFooter className="text-center auth-card-footer">
                                                                <Button
                                                                    block
                                                                    className="btn-round auth-btn"
                                                                    color="primary"
                                                                    size="lg"
                                                                >
                                                                    {this.state.loading ? 'Processing...' : 'Submit'}
                                                                </Button>
                                                            </CardFooter>
                                                        </Form>
                                                    </Card>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                
                            </Container>
                        </section>
                    </div>
                </div>
                <CustomFooter />
            </>
        );
    }
}
const mapStateToProps = state => {
	return {
        notification: state.notification,
	}
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
