import React from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    CardImg,
    Button,
    Alert
} from "reactstrap";
import ShippingCardDetails from './shipping-card'
import shippingAddIcon from "../../../assets/img/shiffing_location.png";
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import agent from '../../../services/api-endpoint'
import { bindActionCreators } from 'redux'
import action_middleware from '../../../redux/action-creator'
import {
    DELETE_SHIPPING_ADDRESS,
    CLEAR_NOTIFICATION_MSG
} from '../../../redux/actions/actionTypes'
import Notification from '../../../components/Custom/Notification'
import SpinnerLoader from '../../../assets/img/loader/spinner-loader.gif'


  
const swalParentsClass = Swal.mixin({
  customClass: {
    title: 'customer-delete-title',
    container:'customer-swal-container',
    confirmButton: 'btn btn-confirm-delete',
    cancelButton: 'btn btn-delete'
  },
  buttonsStyling: false
})
const swalChildClass = Swal.mixin({
  customClass: {
    title: 'customer-delete-title',
    container:'customer-swal-container',
    confirmButton: 'btn btn-confirm',
  },
  buttonsStyling: false
})

class AllShippingAddress extends React.Component{

    deleteShippingAddress = addressId => {
        swalParentsClass.fire({
            title: 'Do you want to remove selected address?',
            text: 'You won\'t be able to revert this !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Remove it !'
        }).then(result => {
            if(result.value){
                Swal.fire({
                    html: `<img src=${SpinnerLoader} /><p style="color: #000">Please keep patient...</p>`,
                    showConfirmButton: false
                })
                this.props.action_middleware(DELETE_SHIPPING_ADDRESS, agent.Profile.deleteShippingAddress(addressId, this.props.token))
                .then(store_state => {
                    swalChildClass.fire({
                        title: "Removed!",
                        text: "Address has been removed.",
                        icon: "success",
                        confirmButtonText: "okay !"
                    });
                })
            }
        })
    }
    onDismiss = () => this.props.action_middleware(CLEAR_NOTIFICATION_MSG, ['shipping_address'])

    render() {
        const {user, toggleView, notification} = this.props

        return(
            <Row>
                <div style={{width: '100%', padding: '0 10px'}}>
                {
                    (notification && 'shipping_address' in notification) &&
                    <Notification delay="5000" onExpire={this.onDismiss} >
                        <div className="alert-section">
                            <Alert
                                color={notification.shipping_address.status}
                                isOpen={notification.shipping_address && true}
                                toggle={this.onDismiss}
                                className="fadeIn slow"
                            >
                                <strong>{notification.shipping_address.msg}</strong>
                            </Alert>
                        </div>
                    </Notification>
                }
                </div>
                <div className="all-customers-shipping-address-card">
                    {
                        (user.shippingAddress || []).map((item, index) =>{
                            return(
                                <Col md="4" key={index} className="shipping-new-col">
                                    <ShippingCardDetails 
                                        item ={item} 
                                        toggleView = {this.props.toggleView} 
                                        deleteShippingAddress = {this.deleteShippingAddress}
                                    />
                                </Col>
                            )
                        })
                    }
                    <Col md="4" className="shipping-new-col">
                        <Card className="shipping-card">
                            <CardBody className="shipping-card-content">
                                <div className="add-new-content">
                                    <CardImg
                                        top
                                        alt="..."
                                        src={shippingAddIcon}
                                    />
                                    <div className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
                                        <Button 
                                            className="shipping-card-new-addr-btn"
                                            onClick={() =>toggleView('newAddress')}
                                            >
                                            Add New address
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </Row>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        token: state.auth.token,
        notification: state.notification
    }
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(AllShippingAddress)