import React from "react";

import {
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter, Button
} from "reactstrap";
import agent from '../../../../../services/api-endpoint';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  SET_CURRENT_STYLE_ITEM,
  SET_VISIBLE_STYLE_ITEM_NUMBER
} from '../../../../../redux/actions/actionTypes'
import action_middleware from '../../../../../redux/action-creator'
import squash_loader from '../../../../../assets/img/loader/splash-liquid-circle.gif'
// import AddProductAddModal from "../add-product-modal"
// import InfoProductModal from "../info-product-modal"



class StyleCatalog extends React.Component {
  constructor(){
    super()
    this.state = {
      itemsToShow: 15,
      loading: false,
      hasMore: true,
    }
    this.observer = React.createRef();
    this.lastElementRef = node => {
      window.hasMoreProduct = this.state.hasMore
      if (this.state.loading) return
      if (this.observer.current) this.observer.current.disconnect()
      this.observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && this.state.hasMore) {
          // console.log('StyleCatalog entries', entries[0])
          this.setState({ loading: true })
          let {itemsToShow} = this.state
          itemsToShow = itemsToShow + 15
          let hasMore = this.props.filtered_style_items.length > itemsToShow
          
          setTimeout(() => {
            this.setState({
              itemsToShow,
              hasMore,
              loading: false
            })
            this.props.action_middleware(SET_VISIBLE_STYLE_ITEM_NUMBER, itemsToShow)
          }, 1000)
        }
      })
      if (node) this.observer.current.observe(node)
    }
  }


  handleAddIconClick = item => {
    this.props.action_middleware(SET_CURRENT_STYLE_ITEM, item).then(() => {
      this.props.toggleModal(!this.props.showModal)
      // this.setState({
      //   showProductModal: !this.state.showProductModal
      // });
    })
  }

  handleInfoIconClick = item => {
    this.props.action_middleware(SET_CURRENT_STYLE_ITEM, item).then(() => {
      this.props.infoToggleModal(!this.props.infoShowModal)
      // this.setState({
      //   showInfoModal: !this.state.showInfoModal
      // });
    })
  }

  render() {
    
    const {filtered_style_items, products, selected_products, filter, sortBy} = this.props

    let items = agent.filterBy(filtered_style_items, filter)
    items = agent.sortArrayOfObjectByProperty(items, sortBy)
    
    return (
      <>
      <Row>
        {
          items.length === 0 && 
          <p className="col-md-12 no-product-found-notification">Sorry, no product found</p>
        }
        {
          items.slice(0, this.state.itemsToShow).map((item, index) => {
            
            let flag = (item.uniqueStyleName in selected_products) && selected_products[item.uniqueStyleName].length > 0

            return (
                <Col md="4" key={index}>
                    <div 
                      className={`search-item-card ` + (flag ? 'selected' : '')} 
                      ref={this.state.itemsToShow == index+1 ? this.lastElementRef : undefined}
                    >
                        <div className="category-item">
                            <div className="badge-added-section">
                              <p>Added</p>
                            </div>
                            <div className="brand-logo-style">
                              <img
                                src={`${agent.settings.ssactiveware_root_url}/${item.brandImage}`}
                                alt={item.brandName}
                              />
                            </div>
                            <div className="category">
                              <img
                                src={`${agent.settings.ssactiveware_root_url}/${item.styleImage}`}
                                alt={item.title}
                              />
                              <div className="category-title">{item.title}</div>
                              <div className="badge badge-success">
                                  <span className="dollar-badge">$</span> 
                                  {item.final_price}
                              </div>
                            </div>
                        </div>
                        <div className="hover-plus-icon">
                          <div className="icon-wrapper">
                            <span>
                              <i 
                                className="tim-icons icon-simple-add" 
                                onClick={() => this.handleAddIconClick(item)}
                              />
                            </span>
                            {
                              flag &&
                              <span>
                                <i 
                                  className="fa fa-info"
                                  onClick={() => this.handleInfoIconClick(item)}
                                />
                              </span>
                            }
                          </div>
                        </div>
                    </div>
                </Col>
            )
          })
        }
        {
          this.state.loading &&
          <div className="loader">
            <img src={squash_loader} />
          </div>
        }
      </Row>
      </>
    );
  }
}
const mapStateToProps = state => {
	return {
		filtered_style_items: state.baseSelection.filtered_style_items,
		products: state.baseSelection.products,
    selected_products: state.baseSelection.selected_products,
    filter: state.baseSelection.filter,
    sortBy: state.baseSelection.sortBy,
	}
}
const mapDispatchToProps = dispatch => ({
  action_middleware: bindActionCreators(action_middleware, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(StyleCatalog)
