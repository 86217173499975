import {
    LOGIN,
    LOGIN_OTP_REQUEST,
    REG_OTP_REQUEST,
    CUSTOMER_REGISTER,
    UPDATE_PROFILE,
    SEND_EMAIL_FOR_RESET_PASSWORD,
    CLEAR_NOTIFICATION_MSG,
    CHANGE_PASSWORD,
    CHANGE_OLD_PASSWORD,
    ADD_SHIPPING_ADDRESS,
    UPDATE_SHIPPING_ADDRESS
} from '../actions/actionTypes'
import agent from '../../services/api-endpoint'


const NotificationReducer = (state={}, action) => {

    switch (action.type) {

        case LOGIN: {
            if(action.success){
                let {success, message} = action.payload
                if(!success){
                    return {
                        ...state,
                        'login': {
                            type: 'login',
                            status: 'danger',
                            msg: message
                        }
                    }
                }
            }
        }

        case LOGIN_OTP_REQUEST: {
            if(action.success){
                let {success, message} = action.payload
                return {
                    ...state,
                    'login': {
                        type: 'login',
                        status: success ? 'success' : 'danger',
                        msg: message
                    }
                }
            }
        }

        case REG_OTP_REQUEST: {
            if(action.success){
                let {success, message} = action.payload
                let err_msg = Object.keys(action.payload.error || []).slice(0, 1).map(key => action.payload.error[key])
                return {
                    ...state,
                    'cus_register': {
                        type: 'st_register',
                        status: success ? 'success' : 'danger',
                        msg: success ? message : err_msg
                    }
                }
            }
        }
            
        // case STORE_OWNER_REGISTER: {
        //     let {payload} = action
        //     // let msg = Object.keys(reg_payload.error || []).map(key => reg_payload.error[key])
        //     let msg = Object.keys(payload.error || []).slice(0, 1).map(key => payload.error[key])
        //     return {
        //         ...state,
        //         'st_register': {
        //             type: 'st_register',
        //             status: payload.success ? 'success' : 'danger',
        //             msg: payload.success ? agent.responseMsg.registration : msg
        //         }
        //     }
        // }
        case CUSTOMER_REGISTER: {
            let {payload} = action
            // let msg = Object.keys(reg_payload.error || []).map(key => reg_payload.error[key])
            let msg = Object.keys(payload.error || []).slice(0, 1).map(key => payload.error[key])
            return {
                ...state,
                'cus_register': {
                    type: 'cus_register',
                    status: payload.success ? 'success' : 'danger',
                    msg: payload.success ? agent.responseMsg.registration : msg
                }
            }
        }
        case SEND_EMAIL_FOR_RESET_PASSWORD:
            let rp_success = action.payload
            return {
                ...state,
                'reset_pass': {
                    type: 'reset_pass',
                    status: rp_success.success ? 'success' : 'danger',
                    msg: rp_success.message
                }
            }
        case UPDATE_PROFILE: {
            let { payload } = action
            let err_msg = Object.keys(payload.error || []).slice(0, 1).map(key => payload.error[key])
            return {
                ...state,
                'update_profile': {
                    type: 'update_profile',
                    status: payload.success ? 'success' : 'danger',
                    msg: payload.success ? 'Profile data is successfully updated.' : err_msg
                }
            }
        }
        case CHANGE_PASSWORD:
        case CHANGE_OLD_PASSWORD: {
            let { payload } = action
            let err_msg = Object.keys(action.payload.error || []).slice(0, 1).map(key => action.payload.error[key])
            return {
                ...state,
                'change_password': {
                    type: 'change_password',
                    status: payload.success ? 'success' : 'danger',
                    msg: payload.message ? payload.message : err_msg
                }
            }
        }
        case ADD_SHIPPING_ADDRESS: {
            const {success} = action.payload
            return {
                ...state,
                'shipping_address' : {
                    type: 'shipping_address',
                    status: success ? 'success' : 'danger',
                    msg: success ? 'Shipping address is successfully created.' : 'Sorry, something went wrong. Please try later.'
                }
            }
        }
        case UPDATE_SHIPPING_ADDRESS: {
            const {success} = action.payload
            return {
                ...state,
                'shipping_address' : {
                    type: 'shipping_address',
                    status: success ? 'success' : 'danger',
                    msg: success ? 'Shipping address is successfully updated.' : 'Sorry, something went wrong. Please try later.'
                }
            }
        }
        case CLEAR_NOTIFICATION_MSG:
            action.payload.forEach(item => {
                if(item in state){
                    delete state[action.payload];
                }
            })
            return { ...state }
        default:
            return state;
    }
}

export default NotificationReducer