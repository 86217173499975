import {
    FETCH_BASECAT_WITH_STYLE,
    SAVE_SELECTED_BASECATEGORY,
    CHANGE_CREATE_STORE_STEP,
    SET_CURRENT_STYLE_ITEM,
    FETCH_PRODUCT_BY_STYLE,
    SET_SELECTED_PRODUCT,
    SET_STYLE_FILTER,
    RESET_STYLE_FILTER,
    ALL_PRODUCT_SELECTED,
    ALL_PRODUCT_UNSELECTED,
    SET_STYLE_SORT_KEY,
    SET_VISIBLE_STYLE_ITEM_NUMBER
    // STEP_BACK_TO_CATEGORY_SELECTION,
    // SHOW_STYLE_TO_ADD_LOGO,
    // STEP_BACK_TO_STYLE_SELECTION,
} from '../../redux/actions/actionTypes'
import agent from '../../services/api-endpoint'
import decrypt from '../../services/decryption'


let initialState = {
    create_store_step: 'select_gear',
    baseCategory: null, 
    rootstyles: null,
    selectedBasecat: [],
    filtered_style_items: [],
    products: [],
    current_style_item: null,
    selected_products: {},
    filter: {},
    sortBy: 'alphabet',
    itemToShow: 15
    // current_style_products: null,
    // show_styles: false,
    // store_tab_ui: 'selection',
}

// modifying basecat array to view object
const modifyCatObj = data => {
    let mod_obj = data.map(el => {
        let o = Object.assign({}, {'title': el});
        o.checked = false;
        o.src = el in agent.basecategoryImg ? agent.basecategoryImg[el] : 'https://ssactivewear.com/Images/Style/16_fs.jpg';
        // o.id = (o.title.toLowerCase()).replace(/[^a-zA-Z0-9]/g,'_').replace(/__/g,'_');
        return o;
    })
    return mod_obj;
}

// filtering styles based on category selection
const filterStyle = (selectedBaseCat, rootStyles) => {
    let styleItems = [];
    // console.log('filterStyle', selectedBaseCat, rootStyles)
    Object.keys(rootStyles).map(key => {
        if(selectedBaseCat.includes(key)){
            // console.log('filtered style key', key)
            styleItems = [...styleItems, ...rootStyles[key]]
        }
    });
    return styleItems
}

const filterStyleProductByColor = products => {
    let flags = {};
    let newProducts = products.filter(item => {
        if (flags[item.colorName]) {
            return false;
        }
        flags[item.colorName] = true;
        return true;
    });
    return newProducts
}

const BaseSelectionReducer = (state=initialState, action) => {

    switch (action.type) {

        case FETCH_BASECAT_WITH_STYLE:
            let {success, payload} = action;
            if(success && payload && 'data' in payload)
            {
                // console.log(payload.data)
                const decrypted_data = decrypt(payload.data)
                // console.log(decrypted_data)
                let baseCat = Object.keys(decrypted_data);
                return {
                    ...state,
                    baseCategory: modifyCatObj(baseCat.sort()),
                    rootstyles: decrypted_data,
                    selectedBasecat: baseCat,
                }
            }

        case SAVE_SELECTED_BASECATEGORY:
            let filtered_styles = filterStyle(action.payload, state.rootstyles)
            return {
                ...state,
                selectedBasecat: action.payload,
                // show_styles: true,
                filtered_style_items: filtered_styles
            }

        case CHANGE_CREATE_STORE_STEP:
            return {
                ...state,
                create_store_step: action.payload
            }

        case SET_CURRENT_STYLE_ITEM:
            return {
                ...state,
                current_style_item: action.payload
            }

        case FETCH_PRODUCT_BY_STYLE:
            if(action.success && action.payload && 'data' in action.payload)
            {
                // console.log(action.payload)
                const data = decrypt(action.payload.data)
                // const data = action.payload.data
                // console.log(data)
                let filtered_prod = filterStyleProductByColor(data.data)
                let products = Object.assign({}, state.products)
                products[state.current_style_item.uniqueStyleName] = filtered_prod

                let selected_prd = Object.assign({}, state.selected_products)
                if( ! (Object.keys(selected_prd)).includes(state.current_style_item.uniqueStyleName) ){
                    selected_prd[state.current_style_item.uniqueStyleName] = filtered_prod.map(p => p.gtin)
                }

                return {
                    ...state,
                    // current_style_products: filterStyleProductByColor(action.payload.data),
                    products,
                    selected_products: selected_prd
                };
            }

        case SET_SELECTED_PRODUCT:
            // console.log(action.payload)
            let {products, selected_products, current_style_item} = state,
                {style, product, checked} = action.payload

            if(checked){
                let a = selected_products[current_style_item.uniqueStyleName] || []
                a.push(product.gtin)
                selected_products[current_style_item.uniqueStyleName] = [...a]
            }
            else{
                let r = Object.assign({}, selected_products)
                r[current_style_item.uniqueStyleName] = r[current_style_item.uniqueStyleName].filter(item => item !== product.gtin)
                selected_products = {...r}
            }
            return {
                ...state,
                selected_products,
            }

        case SET_STYLE_FILTER:
            return {
                ...state,
                filter: action.payload
            }

        case RESET_STYLE_FILTER:
            return{
                ...state,
                filter: {}
            }

        case SET_STYLE_SORT_KEY:
            return {
                ...state,
                sortBy: action.payload
            }

        case ALL_PRODUCT_SELECTED: {
            let selected_products = Object.assign({}, state.selected_products),
                products = Object.assign({}, state.products),
                {style} = action.payload

            selected_products[style.uniqueStyleName] = products[style.uniqueStyleName].map(p => p.gtin)
            return {
                ...state,
                selected_products
            }
        }
        case ALL_PRODUCT_UNSELECTED: {
            let selected_products = Object.assign({}, state.selected_products),
                {style} = action.payload

            delete selected_products[style.uniqueStyleName];
            return {
                ...state,
                selected_products
            }
        }

        case SET_VISIBLE_STYLE_ITEM_NUMBER:
            return {
                ...state,
                itemToShow: action.payload
            }

        // case STEP_BACK_TO_CATEGORY_SELECTION:
        //     return {
        //         ...state,
        //         show_styles: false,
        //     }

        // case SHOW_STYLE_TO_ADD_LOGO:
        //     return{
        //         ...state,
        //         show_styles: false,
        //         store_tab_ui: 'addLogo'
        //     }
        
        // case STEP_BACK_TO_STYLE_SELECTION:
        //     return {
        //         ...state,
        //         show_styles: true,
        //         store_tab_ui: 'selection'
        //     }
        
        default:
            return state;
    }
}

export default BaseSelectionReducer