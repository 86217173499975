function importAll(r) {
    return r.keys().map(r)
}

const logos = {
    "Baseball": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/Baseball", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Baseball"
    },
    "Basketball": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/Basketball", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Basketball"
    },
    "Football": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/Football", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Football"
    },
    "Freshman": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/Freshman", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Freshman"
    },
    "Junior": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/Junior", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Junior"
    },
    "Lacrosse": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/Lacrosse", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Lacrosse"
    },
    "NorthPenn": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/North Penn", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "North Penn"
    },
    "Senior": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/Senior", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Senior"
    },
    "Soccer": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/Soccer", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Soccer"
    },
    "Sophomore": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/Sophomore", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Sophomore"
    },
    "Swimming": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/Swimming", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Swimming"
    },
    "Tennis": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/Tennis", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Tennis"
    },
    "test": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/test", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Test"
    },
    "Wrestling": {
        "svgLogos": importAll(require.context("../../assets/img/svg-logos/Wrestling", false, /\.(png|jpe?g|svg)$/)),
        "icon": "",
        "name": "Wrestling"
    }
}

export default logos