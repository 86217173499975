import React from "react";

// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";

import CheckBox from "components/Custom/CheckBox";
import Select from "react-select";
import agent from '../../../services/api-endpoint';

const data = [
  {
    title: "Athletics",
    src:
      "http://summercamp.s.namvllc.com/images/thumbs/0016767_athletics_370.png",
  },
  {
    title: "Outerwear",
    src:
      "http://summercamp.s.namvllc.com/images/thumbs/0009022_outerwear_370.png",
  },
  {
    title: "Wovens/ Dress Shirts",
    src:
      "http://summercamp.s.namvllc.com/images/thumbs/0016762_wovens-dress-shirts_370.png",
  },
  {
    title: "Sweatpants",
    src:
      "http://summercamp.s.namvllc.com/images/thumbs/0009021_sweatpants_370.png",
  },
  {
    title: "Sweatshirts",
    src:
      "http://summercamp.s.namvllc.com/images/thumbs/0009023_sweatshirts_370.png",
  },
  {
    title: "Accessories",
    src:
      "http://summercamp.s.namvllc.com/images/thumbs/0009018_accessories_370.png",
  },
  {
    title: "Headwear",
    src:
      "http://summercamp.s.namvllc.com/images/thumbs/0009025_headwear_370.png",
  },
  {
    title: "T-Shirts",
    src:
      "http://summercamp.s.namvllc.com/images/thumbs/0009020_t-shirts_370.png",
  },
];

class CatalogCategory extends React.Component {
  state = {
    cat_item: [],
    isAllSelected: false,
  };
  componentDidMount() {
    // const cat_item = data.map((el) => {
    //   let o = Object.assign({}, el);
    //   o.checked = false;
    //   return o;
    // });
    // this.setState({ cat_item });
  }

  // componentDidUpdate = (prevProps, prevState) => {
  //   console.log('catalog componentDidUpdate', prevProps) 
  //   if(this.props.items && ('data' in this.props.items)){
  //     this.setState({ cat_item: this.props.items.data })
  //   }
  // }

  onCheckBoxChange = (checkName, isChecked) => {
    console.log(checkName, isChecked)
    let isAllChecked = checkName === "all" && isChecked;
    let isAllUnChecked = checkName === "all" && !isChecked;
    const checked = isChecked;

    const cat_item = this.state.cat_item.map((item, index) => {
      if (isAllChecked || item.title === checkName) {
        return Object.assign({}, item, {
          checked,
        });
      } else if (isAllUnChecked) {
        return Object.assign({}, item, {
          checked: false,
        });
      }

      return item;
    });

    let isAllSelected =
      cat_item.findIndex((item) => item.checked === false) === -1 ||
      isAllChecked;
    //console.log(isAllSelected)

    this.setState({
      cat_item,
      isAllSelected,
    });
  };

  updateCheckbox = (id) => {
    let cat_item = [...this.state.cat_item];
    cat_item.forEach((item, index) => {
      if (index === id) {
        item.checked = !item.checked;
      }
    });
    const isAllSelected = cat_item.every((item) => item.checked);
    this.setState({ cat_item, isAllSelected });
  };

  checkItem = (id) => {
    this.updateCheckbox(id);
  };

  // checkboxHandler = (id) => {
  //   this.updateCheckbox(id);
  // };

  // checkedAll = () => {
  //   if ( !this.state.checked_all ) {
  //     let cat_item = [...this.state.cat_item];
  //     cat_item.forEach(item => {
  //       item.checked = true;
  //     });
  //     this.setState({ cat_item, checked_all: true });
  //   } else {
  //     let cat_item = [...this.state.cat_item];
  //     cat_item.forEach(item => {
  //       item.checked = false;
  //     });
  //     this.setState({ cat_item, checked_all: false });
  //   }

  //   //console.log(event.target);
  // };

  // CheckBoxOptions = (title, check) => {
  //   return (
  //     <>
  //       <input
  //         name={title}
  //         value={title}
  //         id={`checkbox${title}`}
  //         onChange={(e) => this.onCheckBoxChange(title, e.target.checked)}
  //         checked={check}
  //         type="checkbox"
  //       />
  //       <label htmlFor={`checkbox${title}`}></label>
  //     </>
  //   );
  // };

  render() {
    return (
      <>
        <Row>
          <div className="selection-instruction">
            <p>Simply<span className="checkbox-img sprite-img"></span>uncheck any item you do not wish to offer for sale on your site. You can limit the color options for an item by unchecking the color box(s).<span className="color-item-img sprite-img"></span></p>
          </div>
        </Row>
        <Row className="align-items-center justify-content-between">
          <Col md="4">
            <div className="select-all">
              <input
                className="select-all-checkbox"
                id="styled-checkbox-2"
                name="select-all"
                value="ALL"
                type="checkbox"
                onChange={(e) =>
                  this.onCheckBoxChange("all", e.target.checked)
                }
                checked={this.state.isAllSelected}
              />
              <label htmlFor="styled-checkbox-2">
                Select All
              </label>
            </div>
            
          </Col>
          <Col md="4">
            <div className="sort-by">
              <p>Sort By: </p>
              <Select
                className="react-select sort-by-select"
                classNamePrefix="react-select"
                name="singleSelect"
                value={this.state.singleSelect}
                onChange={(value) =>
                  this.setState({ singleSelect: value })
                }
                options={[
                  {
                    value: "",
                    label: "Sort By",
                    isDisabled: true,
                  },
                  { value: "2", label: "Most Relevant" },
                  { value: "3", label: "High Price" },
                ]}
                placeholder="Sort By"
              />
            </div>
          </Col>
        </Row>
        <Row>
          {/* {this.state.cat_item.map((item, index) => { */}
          {this.props.items.map((item, index) => {
            
            return (
              <Col md="3" key={index}>
                <div
                  className={`item-box ${
                    item.checked && "checked"
                  }`}
                  onClick={() => this.checkItem(index)}
                >
                  <div className="category-item">
                    <div className="category">
                      <CheckBox title={item.title} onCheck={this.onCheckBoxChange} check={item.checked} />
                      <img
                        src={`${agent.settings.ssactiveware_root_url}/${item.colorFrontImage}`}
                        alt={`Picture for category ${item.title}`}
                      />
                      <span className="category-title"> {item.piecePrice} </span>
                      <span className="category-title"> Brand: {item.brandName} </span>
                      <span className="category-title"> Style Name: {item.styleName} </span>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </>
    );
  }
}

export default CatalogCategory;
