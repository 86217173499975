import {
    LOGIN,
    LOGOUT,
    OTP_REQUEST,
    STORE_OWNER_REGISTER,
    CUSTOMER_REGISTER,
    UPDATE_PROFILE,
    ADD_SHIPPING_ADDRESS,
    EDIT_SHIPPING_ADDRESS,
    CLEAR_EDIT_SHIPPING_ADDRESS,
    UPDATE_SHIPPING_ADDRESS,
    DELETE_SHIPPING_ADDRESS
} from '../actions/actionTypes'


let initialState = {
    isLogged: false,
    user: null,
    token: null,
    role: null
};


const AuthReducer = (state=initialState, action) => {

    switch (action.type) {

        case CUSTOMER_REGISTER:
            return{
                ...state
            }

        // case OTP_REQUEST: {
        //     let { success, payload } = action
        //     if(success){
        //         return{
        //             ...state,
        //             sent_otp:  payload.mail_send_success
        //         }
        //     }
        // }
        case LOGIN: {
            let { success, payload } = action
            if(success && payload.success){
                return {
                    ...state,
                    isLogged: payload.success,
                    token: payload.success ? payload.token : null,
                    user: payload.success ? payload.user : null,
                    role: payload.success ? payload.user.role : null,
                }
            }
        }
            
        case LOGOUT:
            return {
                ...state,
                isLogged: false,
                token: null,
                user: null,
                role: null
            }

        case UPDATE_PROFILE: {
            let { success, payload } = action
            if(success && payload.success){
                return {
                    ...state,
                    user: payload.user
                }
            }
        }

        case ADD_SHIPPING_ADDRESS: {
            const {success} = action.payload
            return {
                ...state,
                user: success ? { ...state.user, shippingAddress: action.payload.allAddress } : state.user
            }
        }

        case EDIT_SHIPPING_ADDRESS: {
            return {
                ...state,
                edited_address: action.payload
            }
        }

        case CLEAR_EDIT_SHIPPING_ADDRESS: {
            delete state.edited_address
            return {
                ...state
            }
        }

        case UPDATE_SHIPPING_ADDRESS: {
            let {user} = state
            if(action.payload.success)
                user = {...state.user, shippingAddress: action.payload.allAddress}
            return {
                ...state,
                user
            }
        }

        case DELETE_SHIPPING_ADDRESS: {
            const {shippingAddress} = action.payload
            let user = {...state.user, shippingAddress}
            return {
                ...state,
                user
            }
        }
        
        default:
            return state;
    }
}

export default AuthReducer