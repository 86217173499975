import React, { useState } from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    NavLink,
    Alert
} from "reactstrap";

//material form added 
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Notification from '../../components/Custom/Notification'
import agent from '../../services/api-endpoint'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    LOGIN,
    LOGIN_OTP_REQUEST
} from '../../redux/actions/actionTypes'
import action_middleware from '../../redux/action-creator'

import validate from "../../services/validation" //Validation


class CustomerLogin extends React.Component {
    state = {
        email: '',
        password: '',
        otp: '',
        agree_terms: false,
        loading: false,
        notification: null,
        showOtpForm: false,
    }
    handleChange = e => {
        let { name, value } = e.target
        this.setState({
            [name]: value
        })
    }
    handleForm = () => this.props.handleFormChange('showForgetPass', !this.props.showForgetPass)
    handleLogin = (e) => {
        e.preventDefault()
        const { email, password, agree_terms, showOtpForm, otp } = this.state
        if(showOtpForm){
            this.setState({ notification: null }, () => {
                this.setState({ loading: true }, () => {
                    this.props.action_middleware(LOGIN, agent.Auth.verify_otp({ email, OTP: otp }))
                    .then(store_state => {
                        if(!store_state.auth.isLogged){
                            this.setState({
                                loading: false,
                                notification: store_state.notification
                            })
                        }
                    })
                })
            })
        }
        else{
            /**Skipping agree_terms since it's a checkboc */
            const form_values = {
                email, password
            }
            const no_error = validate("login", form_values)
            console.log(no_error)

            if (email && password) {

                if (agree_terms) {
                    if (email !== '' && password !== '') {
                        if (typeof (Storage) !== "undefined") {
                            let expirationDate = new Date(new Date().getTime() + (60000 * 2))
                            let newValue = {
                                a: email,
                                b: password,
                                c: true,
                                expirationDate: expirationDate.toISOString()
                            }
                            window.sessionStorage.setItem('obj', JSON.stringify(newValue))
                        }
                    }
                }
                else { window.sessionStorage.removeItem('obj') }

                this.setState({ loading: true, notification: null }, () => {
                    this.props.action_middleware(LOGIN_OTP_REQUEST, agent.Auth.login({ email, password }))
                    .then(store_state => {
                        if(!store_state.auth.isLogged){
                            this.setState({
                                loading: false,
                                notification: store_state.notification,
                                password: '',
                                showOtpForm: store_state.temp.sent_otp
                            })
                        }
                    })
                })
            }
        }
    }
    onDismiss = () => this.setState({ notification: null })


    render() {
        const {notification, showOtpForm} = this.state
        
        return (
            <Card className="card-login auth-card animated fadeIn slow">
                <Form className="form" onSubmit={this.handleLogin}>
                    <CardHeader className="text-center auth-card-header">
                        <CardTitle tag="h4">Customer Login</CardTitle>
                    </CardHeader>
                    <CardBody className="auth-card-body">
                        <p className="sub-title">If you have an account, sign in with your email address.</p>

                        {
                            (notification && 'login' in notification) &&
                            <Notification delay="5000" onExpire={this.onDismiss}>
                                <div className="alert-section">
                                    <Alert
                                        color={notification.login.status}
                                        isOpen={notification.login && true}
                                        toggle={this.onDismiss} // for cross icon
                                        className="fadeIn slow"
                                    >
                                        <strong>{notification.login.msg}</strong>
                                    </Alert>
                                </div>
                            </Notification>
                        }

                        {
                            showOtpForm ?
                            <>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={1}>
                                    <Icon className="fas fa-user-circle" />
                                </Grid>
                                <Grid item sm={11}>
                                    <TextField
                                        required
                                        id="input-with-icon-grid"
                                        label="Verify OTP"
                                        placeholder="Enter OTP number"
                                        type="text"
                                        name="otp"
                                        fullWidth
                                        value={this.state.otp}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                            </> :
                            <>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={1}>
                                    <Icon className="fas fa-user-circle" />
                                </Grid>
                                <Grid item sm={11}>
                                    <TextField
                                        required
                                        id="input-with-icon-grid"
                                        label="Email"
                                        placeholder="Enter Email"
                                        type="email"
                                        name="email"
                                        fullWidth
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={1}>
                                    <Icon className="fas fa-key" />
                                </Grid>
                                <Grid item sm={11}>
                                    <TextField
                                        id="input-with-icon-grid"
                                        label="Password"
                                        placeholder="Enter Password"
                                        type="password"
                                        fullWidth
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="center"
                                style={{ position: 'relative', top: '9px' }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name="agree_terms"
                                            checked={this.state.agree_terms}
                                            onChange={e => {
                                                let { name, checked } = e.target
                                                this.handleChange({ target: { name, value: checked } })
                                            }}
                                        />}
                                    label="Remember Me"
                                />
                                <NavLink className="forget-pass" onClick={this.handleForm}>
                                    Forget Your Password
                                </NavLink>
                            </Grid>
                            </>
                        }
                    </CardBody>
                    <CardFooter className="text-center auth-card-footer">
                        <Button
                            block
                            className="btn-round auth-btn"
                            color="primary"
                            type="submit"
                            size="lg"
                        >
                            <span>{this.state.loading ? 'Logging In...' : 'Login'}</span>
                        </Button>
                    </CardFooter>
                </Form>
            </Card>
        )
    }
}
const mapStateToProps = state => {
    return {
        notification: state.notification
    }
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerLogin)