import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
    Container,
    Col,
    Row,
} from "reactstrap";


import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import CustomerLogin from './login';
import CustomerForgetPassword from './forgot-password';
import CustomerRegistration from './register'
import NewCustomerAccount from './new-account'



class CustomerAuthentication extends React.Component {
    state = {
        showForgetPass: false,
        showRegistrationForm: false
    };
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        document.body.classList.add("page");
    }
    componentWillUnmount() {
        document.body.classList.remove("page");
    }

    handleChangeFormState = (key, value) => {
        this.setState({
            [key]: value
        })
    }


    render() {
        return (
            <>
                <CustomNavbar />
                <div className="wrapper" ref="wrapper">
                    <div className="main">
                        <div className="space-100" />
                        <section className="section registration-sec customer-reg-sec">
                            <Container>
                                <div className="page-title text-center">
                                    <h1 className="main-title"><span>customer</span><span>Login</span></h1>
                                    <p>Try jamaloq for free, and explore all the tools and services you need to start, run, and grow your business.</p>
                                </div>

                                <div className="store-owener-login-main-section customer-login-main-section">
                                    <Row>
                                        <Col md='5'>
                                            <div className="login-item sign-in">
                                                {
                                                    !this.state.showForgetPass 
                                                    ? <CustomerLogin 
                                                        handleFormChange={this.handleChangeFormState}
                                                        showForgetPass={this.state.showForgetPass}
                                                    />
                                                    : <CustomerForgetPassword 
                                                        handleFormChange={this.handleChangeFormState}
                                                        showForgetPass={this.state.showForgetPass}
                                                    />
                                                }
                                            </div>
                                        </Col>
                                        <Col md='7'>
                                            <div className="login-item sign-up">

                                                {
                                                    !this.state.showRegistrationForm 
                                                    ? <NewCustomerAccount 
                                                        handleFormChange={this.handleChangeFormState}
                                                        showRegistrationForm={this.state.showRegistrationForm}
                                                    /> 
                                                    : <CustomerRegistration 
                                                        handleFormChange={this.handleChangeFormState}
                                                        showRegistrationForm={this.state.showRegistrationForm}
                                                    />
                                                }
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                
                            </Container>
                        </section>
                    </div>
                </div>
                <CustomFooter />
            </>
        );
    }
}

export default CustomerAuthentication;
