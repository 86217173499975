import React from 'react';
import {
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CheckBox
} from "reactstrap";



class CategoryCard extends React.Component {

    handleSelect = e => {
        let {value} = e.target
        this.props.onSelect(value, e.target)
    }

    render() {
        const {item, isChecked} = this.props
        return (
            <div className="checkbox-position-sec">
                <div className="custom-checkbox-design-another-sec">
                    <input 
                        type='checkbox' 
                        className="custom-checkbox-design-another"
                        name ="category"
                        id={item.title}
                        checked={isChecked}
                        value={item.title}
                        onChange={this.handleSelect}
                    />
                    <label htmlFor={item.title} className="custom-checkbox-label-another">
                        <span></span>
                    </label>
                </div>
                <Card 
                    className={'category-card ' + (isChecked ? 'checked' : '')} 
                    onClick={(e) => this.props.onSelect(item.title, e.target)}
                >
                    <CardBody className="category-card-content">
                        <div className="category-thumb">
                            <CardImg
                                top
                                alt="..."
                                src={item.src}
                            />
                        </div>
                        <CardTitle className="category-card-title">{item.title}</CardTitle>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default CategoryCard;