import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import { fade, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  SET_STYLE_FILTER
} from '../../../../../../redux/actions/actionTypes'
import action_middleware from '../../../../../../redux/action-creator'



const MinusSquare = (props) => {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

const PlusSquare = (props) => {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

const CloseSquare = (props) => {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}  



const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 16,
        paddingLeft: 5,
        borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
}))((props) => <TreeItem {...props} />); 

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }
    

    handleFilterChange = e => {
        let filter = Object.assign({}, this.props.filter),
            ob = {
                type: 'baseCategory',
                checked: e.target.checked,
                value: e.target.value
            }

        if(ob.type in filter){
            if(ob.checked)
                filter[ob.type].push(ob.value)
            else
                filter[ob.type] = filter[ob.type].filter(i => i !== ob.value)
        }else
            filter[ob.type] = [ob.value]
            
        this.props.action_middleware(
            SET_STYLE_FILTER, 
            filter
        )
    }

    render() {
        const {selected_base_category, filter} = this.props

        return (
            <div className="feature-filter-sec style-sec-filter-sec">
                <h4 className="style-sec-filter-title">Category</h4>
                <TreeView
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    defaultEndIcon={<CloseSquare />}
                >
                    <StyledTreeItem nodeId="0" label="Category">
                        {
                            selected_base_category.map((item,index) => {
                                let checked = false
                                if( ('baseCategory' in filter) && filter['baseCategory'].includes(item) )
                                    checked = true
                                return(
                                    // <TreeItem 
                                    //     key={index} 
                                    //     nodeId={String(index + 1)} 
                                    //     label={item}
                                    //     onClick={() => this.handleFilterChange('category', item)}
                                    // />
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                                color="primary" 
                                                value={item} 
                                                name="category_name" 
                                                checked={checked}
                                                onChange={this.handleFilterChange}
                                            />
                                        }
                                        label={item}
                                        key={index}
                                    />
                                )
                            })
                        }
                    </StyledTreeItem>
                </TreeView>
            </div>
        )
    }
}
const mapStateToProps = state => {
	return {
		selected_base_category: state.baseSelection.selectedBasecat,
		filter: state.baseSelection.filter,
	}
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(Category)