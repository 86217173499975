import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";

import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import MyOrders from './my-orders/index';
import ShippingAddressModule from './shipping-address'
import ChangePasswordCustomer from './my-details/change-pass-modal.js';
import CustomerDetails from './my-details/index'
import agent from '../../services/api-endpoint'
import {connect} from 'react-redux'




class CustomerPortal extends React.Component {
    state = {
        vTabs: 'my-orders',
        isOpenModal: false
    };
    toggleTabs = (e, stateName, index) => {
        e.preventDefault();
        this.setState({
            [stateName]: index
        });
    };
    toggleModal = () => {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        });
    }
    render(){
        return(
            <>
                <CustomNavbar />
                <div className="wrapper" ref="wrapper">
                    <div className="main">
                        <div className="space-100" />
                        <section className="section customer-portal">
                            <Container>
                                <Row>
                                    <div className="account-info-main-sec">
                                        <Col md="3">
                                            <div className="account-info-sec">
                                                <div className="img-sec">
                                                    <img src={this.props.user.profile_image || require("assets/img/johana.jpg")} className="img-round" />
                                                </div>
                                                <div className="account-info-details">
                                                    <h4 className="userName">{this.props.user.first_name}</h4>
                                                    <div className="edit-sec">
                                                        {/* <NavLink ><i className="fas fa-edit"></i> Edit profile</NavLink> */}
                                                        <NavLink onClick={this.toggleModal}><i className="fas fa-key"></i> Change Password</NavLink>
                                                        <Link to={{
                                                            pathname: agent.site_slug.SIGN_OUT,
                                                            state: { userEvent: true }
                                                        }}
                                                        className = "nav-link"
                                                        >
                                                            <i className="fas fa-sign-out-alt"></i> Sign Out
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="9">
                                            <div className="customer-info-header">
                                                <div className="customer-info-header-left">
                                                    <h4 className="title">Welcome back <span>{this.props.user.first_name} !</span></h4>
                                                    <p className="sub-title">We promise to keep you up-to-date with the sweetest new season styles. </p>
                                                </div>
                                                <div className="customer-info-header-right">
                                                    <img src={require('assets/img/customer_panel.jpg')} alt="..." className="customer-img" />
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </Row>
                                
                                <Row>
                                    <div className="customer-portal-tab-sec">
                                        <Col md="3">
                                            <Nav
                                                className="nav-pills-primary flex-column customer-portal-sidebar"
                                                pills
                                                role="tablist"
                                            >
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                        active: this.state.vTabs === 'my-orders'
                                                        })}
                                                        onClick={e => this.toggleTabs(e, "vTabs", 'my-orders')}
                                                    >
                                                        <i className="fas fa-list-alt"></i>
                                                        <span>My Orders</span>
                                                    </NavLink>
                                                </NavItem>
                                                
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                        active: this.state.vTabs === 'shipping-address'
                                                        })}
                                                        onClick={e => this.toggleTabs(e, "vTabs", 'shipping-address')}
                                                    >
                                                        <i className="fas fa-shipping-fast"></i>
                                                        <span>Shipping Address</span>
                                                    </NavLink>
                                                </NavItem>

                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                        active: this.state.vTabs === 'my-details'
                                                        })}
                                                        onClick={e => this.toggleTabs(e, "vTabs", 'my-details')}
                                                    >
                                                        <i className="fas fa-user"></i>
                                                        <span>My Details</span>
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>
                                        </Col>
                                        <Col md="9">
                                            <TabContent activeTab={"vTabs-" + this.state.vTabs} className="customer-portal-desc-sec">
                                                <TabPane tabId="vTabs-my-orders">
                                                    <MyOrders />
                                                </TabPane>
                                                <TabPane tabId="vTabs-shipping-address">
                                                    <ShippingAddressModule/>
                                                </TabPane>
                                                <TabPane tabId="vTabs-my-details">
                                                    <CustomerDetails/>
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </div>
                                </Row>
                            </Container>

                            <Modal isOpen={this.state.isOpenModal} toggle={this.toggleModal} className={this.props.className + " change-password-owner-modal" } >
                                <ChangePasswordCustomer toggle={this.toggleModal} />
                            </Modal>
                        </section>
                    </div>
                </div>
                <CustomFooter />
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        user: state.auth.user
    }
}
export default connect(mapStateToProps, null)(CustomerPortal)