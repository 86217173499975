import React from "react";
import {
    Button,

    Col, Row
} from "reactstrap";
import icondropeer from '../../../../../../assets/img/dropper.png';


class EditUploadMascot extends React.Component{
    render(){
        return (
            <Row className="upload-mascot-edit-section">
                <Col md="12">
                    <Button className="back-mascot-btn" onClick={this.props.uploadmascot}>
                        <i className="fas fa-arrow-left"></i>
                        Back
                    </Button>
                </Col>
                <Col md="12">
                    <p className="upload-mascot-edit-section-subtitle">
                        <img src={icondropeer} alt="..." className="icon-image" />
                        Use the eyedropper tool by clicking on a color you wish to remove from your image
                    </p>
                    <div className="upload-mascot-edit-image">
                        <img 
                            alt="image"
                            src={require("assets/img/shirt.png")}
                        />
                    </div>
                </Col>
                <Col md="12">
                    <fieldset className="upload-mascot-edit-image-color">
                        <legend>Change Color</legend>
                        <div className="upload-mascot-edit-main">
                            <p className="upload-mascot-edit-color-heading">Remove White?</p>
                            <div className="upload-mascot-edit-radio-sec">
                                <label className="upload-mascot-edit-color-content">
                                    <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                    <span>No</span>
                                </label>
                                <label className="upload-mascot-edit-color-content">
                                    <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                    <span>Remove All White</span>
                                </label>
                                <label className="upload-mascot-edit-color-content">
                                    <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                    <span>Remove White Background</span>
                                </label>
                            </div>
                            
                        </div>
                        <div className="upload-mascot-edit-main">
                            <p className="upload-mascot-edit-color-heading">How many colors are in your image?</p>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>1 Colors</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>2 Colors</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>3 Colors</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>4 Colors</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>5 Colors</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>6 Colors</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>7 Colors</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>8 Colors</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>9 Colors</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>10 Colors</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>11 Colors</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>12 Colors</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>Convert to black & white</span>
                            </label>
                            <label className="upload-mascot-edit-color-content upload-mascot-radio-count">
                                <input type="radio" name="radio" className="upload-mascot-radio-input"/>
                                <span>Use full-color image</span>
                            </label>
                        </div>
                    </fieldset>
                </Col>
                <Col>
                <Button 
                    className="mascot-edit-continue-btn"
                    onClick={this.props.finalizeuploadmascot}
                >
                    Continue
                </Button>
                </Col>
            </Row>
        )
    }
}
export default EditUploadMascot