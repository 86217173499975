import React from "react";import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
  } from "reactstrap";
  import TextField from '@material-ui/core/TextField';
  import TableContainer from "@material-ui/core/TableContainer";
  import Table from '@material-ui/core/Table';
  import TableBody from '@material-ui/core/TableBody';
  import TablePagination from '@material-ui/core/TablePagination';
  import Swal from 'sweetalert2'
  
  const swalParentsClass = Swal.mixin({
    customClass: {
      title: 'customer-delete-title',
      container:'customer-swal-container',
      confirmButton: 'btn btn-confirm-delete',
      cancelButton: 'btn btn-delete'
    },
    buttonsStyling: false
  })
  const swalChildClass = Swal.mixin({
    customClass: {
      title: 'customer-delete-title',
      container:'customer-swal-container',
      confirmButton: 'btn btn-confirm',
    },
    buttonsStyling: false
  })
  
  class AllOrdersTable extends React.Component {
    state = {
        search_term: '',
        page: 0,
        rowsPerPage: 5
    };
    
  
    changeHandler = event => {
      this.setState({
        search_term : event.target.value
      })
    }
  
    searchingOutputOrder = () => {
      let searchingOutput = this.props.allOrders.filter(item =>
          item.name.toLowerCase().includes(this.state.search_term.toLowerCase())
      );
      return searchingOutput;
    }
  
    handleChangePage = (event, newPage) => {
      this.setState({
        page:newPage
      })
    };
  
    handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage : +event.target.value,
        page: 0
      })
    };
    deleteOrder = id => {

      swalParentsClass.fire({
        title: 'Do you want to cancel your order ?',
        text: 'You won\'t be able to revert this !',
        icon: 'warning',
        showCancelButton: true,
        
        confirmButtonText: 'Yes, delete it !'
      }).then(result => {
        console.log(result);
        if(result.value){
          this.props.handleOrderCancelation(id);
          swalChildClass.fire({
            title: "Deleted!",
            text: "Your order has been cancelled.",
            icon: "success",
            confirmButtonText: "okay !"
          });
        }
      })
    }
  
    render() {

      const {page, rowsPerPage} = this.state
      let allFilterOrders = this.searchingOutputOrder();

      let filterOrders = allFilterOrders.length <= rowsPerPage ? allFilterOrders : allFilterOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

      return (
          <div className="orders-main-sec all-orders-table-sec">
              <Row>
                  <Col md="4" className="ml-auto">
                    <div className="orders-search-sec all-orders-search-sec">
                      <TextField 
                        id="all-orders-search" 
                        placeholder ="Search" 
                        fullWidth
                        onChange ={this.changeHandler}
                        value ={this.state.search_term}
                        />
                    </div>
                  </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <TableContainer className="orders-table-sec all-orders-table-sec table-responsive">
                            <Table className="orders-table all-orders-table">
                                <thead>
                                    <tr>
                                        <th style ={{textAlign: 'left'}}>Product</th>
                                        <th>Size</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Status</th>
                                        <th style ={{textAlign: 'right'}}>Actions</th>
                                    </tr>
                                </thead>
                                <TableBody>
                                    {filterOrders.map((item, index) => {
                                        return (
                                        <tr key={index} tabIndex={-1}>
                                            <td style ={{textAlign: 'left'}}>
                                                <div className="orders-table-first-col">
                                                    <span className="orders-img"><img src={item.src}/></span>
                                                    <div className="orders-list-info">
                                                    <span className="product-name">{item.name}</span>
                                                    <span className="brand-name">{item.brand}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                              <div className="size">{item.size}</div>
                                              </td>
                                            <td>{item.quantity}</td>
                                            <td>{item.price}</td>
                                            <td>
                                              <div className={item.status === 'pending' ? 'pending': item.status === 'delivered' ? 'deliver': 'cancelled'}>{item.status}</div>
                                              </td>
                                            <td style ={{textAlign: 'right'}}>
                                                <Button className="orders-trash-btn"
                                                  disabled={item.status === 'delivered'}
                                                  onClick ={()=>this.deleteOrder(item.id)}
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100]}
                          component="div"
                          count={allFilterOrders.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          className= "orders-table-pagination"
                        />
                        
                    </Col>
                </Row>
          </div>
      );
    }
  }
  
  export default AllOrdersTable;