import Grid from '@material-ui/core/Grid';
import ImageUpload from 'components/CustomUpload/ImageUpload';
import React from "react";
import {
    Button,
    Col,
    Row
} from "reactstrap";

class UploadMascotCondition extends React.Component {

    state ={
        checkedTerms: false,
    }
    handleChangeTerm = (event) => {
        console.log(event.target.name);
        console.log(event.target.checked);

        this.setState({
            ...this.state, 
            [event.target.name]: event.target.checked
        })
    };

    render(){
        
        return(
            <Row>
                <Col md="12">
                    <fieldset className="image-upload-term-section">
                        <legend>Image upload terms and conditions</legend>
                        <div>
                            <span className="image-upload-term">1. As the submitter of this photo, it is your responsibility to ensure that all photos you upload are your original works and intellectual property.</span>
                            <span className="image-upload-term">2. All photos posted must be relevant to face painting and family friendly.</span>
                            <span className="image-upload-term">3. No advertising or promotional image files will be approved. </span>
                        </div>
                    </fieldset>
                </Col>
                <Col md="6">
                    <div className="supported-file-type">
                        <h4 className="supported-file-title">Supported file types</h4>
                        <span className="supported-file-content">.png, .jpg, .jpeg, .gif, .bmp</span>
                    </div>
                </Col>
                <Col md="6">
                    <div className="supported-file-type">
                        <h4 className="supported-file-title">25 mb max file size</h4>
                        <span className="supported-file-content">Depending on the file size some image may take a few minutes to upload</span>
                    </div>
                </Col>
                <Col md="12">
                    <Grid container spacing={1} alignItems="flex-end" className="personalization-upload-mascot">
                        <Grid item sm={12}>
                            <ImageUpload />
                        </Grid>
                    </Grid>
                </Col>

                <Col md ="12">
                    <div className="material_checkbox_term">
                        <input 
                            id="6" 
                            type="checkbox" 
                            className="material_checkbox" 
                            checked ={this.state.checkedTerms}
                            onChange ={this.handleChangeTerm}
                            name ="checkedTerms"
                            />
                        <label htmlFor="6">I agree to the terms & conditions of uploaded artwork</label>
                    </div>
                </Col>

                <Col md="12">
                    <Button className="mascot-edit-continue-btn" onClick={this.props.edituploadmascot}>
                        Continue
                    </Button>
                </Col>
                
            </Row>
        )
    }
}
export default UploadMascotCondition