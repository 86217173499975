import {
    LOGIN,
    LOGOUT
} from '../actions/actionTypes'
import agent from '../../services/api-endpoint'


let initialState = {
    redirect_to: agent.site_slug.STORE_OWNER_SIGN_UP
};


const CommonReducer = (state=initialState, action) => {

    switch (action.type) {

        case LOGIN: {
            let {success, payload} = action
            if(success && payload.success){
                let {success, user} = payload,
                    redirect_to = (success & user.role in agent.redirectUser.auth) ? 
                                    agent.redirectUser.auth[user.role] :
                                    agent.redirectUser.guest
                
                if(success){
                    return {
                        ...state,
                        redirect_to 
                    }
                }
            }
        }

        case LOGOUT: {
            let {success, payload, data} = action
            if(success && payload.success){
                return {
                    ...state,
                    redirect_to: agent.site_slug.CUSTOMER_SIGN_UP
                }
            }
        }
        
        default:
            return state;
    }
}

export default CommonReducer