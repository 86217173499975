import React from "react";

// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Label,
  Input,
  Button,
  Pagination, PaginationItem, PaginationLink
} from "reactstrap";

import OrganizationDetailsForm from "./organization-details";
import LogoDesign from './logo-design';
import Zoom from '@material-ui/core/Zoom';


class LogoSelection extends React.Component { 
    state ={
        cardView: false 
    }
    toggleCard = () => {
        this.setState({
            cardView: !this.state.cardView
        })
    }
    handleShowInfoCard = () =>{
        this.setState({
            cardView: !this.state.cardView
        })
    }
    render() {
        const {cardView} = this.state
        return (
            <>
                <Row>
                    <div className="logo-header">
                        <Col md="3" className="header-button-sec">
                        <Button  
                                className="btn-round ml-1 btn d-flex create-store-back"
                                //onClick ={() => this.props.closeAccrodian(3)}
                                onClick ={()=>this.props.tabToggle('hTabs','selection')}
                            >   <i className="fas fa-arrow-left"></i>
                                back
                            </Button>
                        </Col>
                        <Col md="6 text-center">
                            <h1 className="title"><span>Select Your </span><br/><span>logo</span></h1>
                        </Col>
                        <Col md="3 header-button-sec">
                            <Button  
                                className="btn-round ml-1 btn ml-auto d-flex create-store-save-next"
                                // onClick ={() => this.props.openAccordion(3)}
                                onClick ={()=>this.props.tabToggle('hTabs','shareStore')}
                            > 
                                Save and Next
                                <i className="fas fa-arrow-right"></i>
                            </Button>
                        </Col>
                    </div>
                </Row>
                <section className="section logo-sec-main">
                {
                    !cardView ? 
                    (
                        <div className="store-org-details-form">
                        <Row>
                            <Col md="8" sm="10" className="ml-auto mr-auto">
                                <div className="store-form-sec">
                                    <div className="header-title">
                                        <h2 className="title">Organization Information</h2>
                                        <p className="sub-title">Sign up to continue! It only takes a few more steps to get your own store up and running. Don’t worry, we respect your privacy.</p>
                                    </div>
                                    
                                    <OrganizationDetailsForm 
                                        toggleCard={this.toggleCard} 
                                        values ={this.state}    
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    )
                    :
                    (
                        <Zoom in={cardView} timeout={500}>
                            <div className="logo-generate-section">
                                <LogoDesign handleShowInfoCard={this.handleShowInfoCard} />
                            </div>
                        </Zoom>
                    ) 
                }
                    
                </section>
                <Row>
                    <Col md="12">
                        <Button  
                            className="btn-round ml-1 ml-auto mr-auto d-flex create-store-save-next-last"
                            // onClick ={() => this.props.openAccordion(3)}
                            onClick ={()=>this.props.tabToggle('hTabs','shareStore')}
                        > 
                            Save and Next
                            <i className="fas fa-arrow-right"></i>
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}


export default LogoSelection;