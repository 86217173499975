import React from 'react';
import {
    Row,
    Col,
  } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { TwitterPicker } from 'react-color';
import reactCSS from 'reactcss';

class PersonalizeColorPicker extends React.Component {
    state = {
        displayNameColorPicker: false,
        color: {
          r: '241',
          g: '112',
          b: '19',
          a: '1',
        },
      };
    
      nameHandleClick = () => {
        this.setState({ displayNameColorPicker: !this.state.displayNameColorPicker })
      };
    
      nameHandleClose = () => {
        this.setState({ displayNameColorPicker: false })
      };
    
      nameHandleChange = (color) => {
        this.setState({ color: color.rgb })
      };
    render() {
        const styles = reactCSS({
            'default': {
              color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
              },
              swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
              },
              popover: {
                position: 'absolute',
                zIndex: '2',
              },
              cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
              },
            },
          });
        return (
            <div>
                <div style={ styles.swatch } onClick={ this.nameHandleClick }>
                    <div style={ styles.color } />
                </div>
                { this.state.displayNameColorPicker ? <div style={ styles.popover }>
                    <div style={ styles.cover } onClick={ this.nameHandleClose }/>
                    <TwitterPicker color={ this.state.color } onChange={ this.nameHandleChange } />
                    </div> : null }
                            
            </div>
        )
    }
}

export default PersonalizeColorPicker