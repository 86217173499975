import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    LOGOUT
} from '../../redux/actions/actionTypes'
import action_middleware from '../../redux/action-creator'
import { Redirect } from "react-router-dom"
import agent from '../../services/api-endpoint'



const SignOut = props => {
    
    let stateUserEvent = (props.location.state && 'userEvent' in props.location.state) ? props.location.state['userEvent'] : false
    const [userEvent, setUserEvent] = useState(stateUserEvent);

    useEffect(() => {
        
        if(props.isLogged && userEvent){
            props.action_middleware(LOGOUT, agent.Auth.logout(props.token), {role: props.role})
        }
    }, []);

    if(userEvent)
        return <></>
    else
        return <Redirect to={props.redirect_to} />
}

const mapStateToProps = state => {
    return {
        redirect_to: state.common.redirect_to,
        isLogged: state.auth.isLogged,
        token: state.auth.token,
        role: state.auth.role
    }
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(SignOut)