import React from "react";

const CheckBox = ({title, onCheck, check}) => {
  return (
    <>
      <input
        name={title}
        value={title}
        id={`checkbox${title}`}
        onChange={(e) => onCheck(title, check)}
        checked={check}
        type="checkbox"
      />
      <label htmlFor={`checkbox${title}`}></label>
    </>
  );
};
export default CheckBox;
