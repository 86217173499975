import React from "react";

// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";

import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import Catalog from "views/catalog";
// import Category from "./category";
//import BaseSelection from "./baseSelection";
import SelectGear from "./select-gear/index.jsx"
// import AddLogo from './add-logo'

// import Pagination_demo from '../store/cat_paginate';
import {connect} from 'react-redux'
import LogoSelection from "./logo-selection/index.js";
import ShareYourStore from "./share-your-store/index.js";



class BuildStore extends React.Component {
  state = {
    hTabs: 'selection',
    seletedCat: [],
  };
  componentDidMount = () => {
    // document.body.classList.add("page");
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // this.refs.wrapper.scrollTop = 0;

    // this.setState({
    //   hTabs: this.props.store_tab_ui
    // })
  }
  toggleTabs = (stateName, index) => {
    //e.preventDefault();
    this.setState({
      [stateName]: index
    });
  };

  // componentWillReceiveProps = nextProps => {
  //   console.log(nextProps.store_tab_ui, this.props.store_tab_ui)
  //   if(nextProps.store_tab_ui !== this.props.store_tab_ui){
  //     this.setState({
  //       hTabs: nextProps.store_tab_ui
  //     })
  //   }
  // }
  componentWillUnmount() {
    document.body.classList.remove("page");
  }

  

  render() {

    const {create_store_step} = this.props

    return (
      <>
        <CustomNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="main">
            <div className="space-100" />
            <section className="section store-sec">
              <Container>

              <Row className="align-items-md-center justify-content-md-center">
                  <Col md="12">
                    <Nav
                      className="nav-pills-primary justify-content-center create-store-step-main"
                      pills
                      role="tablist"
                    >
                      <NavItem className="step-item">
                        <NavLink
                          className={classnames({
                            // active: this.state.hTabs === 'selection',
                            active: create_store_step === 'select_gear',
                          })}
                          // onClick={(e) => this.toggleTabs(e, "hTabs", 'selection')}
                          //href="#pablo"
                        >
                          <div className="step-item-tag">1.</div>
                          <span>Select <br/>Your <br/>Gear</span>
                          <div className="bar-fill">
                            <span className="progress-bar-arrow"></span>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className="step-item">
                        <NavLink
                          className={classnames({
                            // active: this.state.hTabs === 'addLogo',
                            active: create_store_step === 'addLogo',
                          })}
                          // onClick={(e) => this.toggleTabs(e, "hTabs", 'addLogo')}
                          //href="#pablo"
                        >
                        <div className="step-item-tag">2.</div>
                          <span>Add <br/>Your <br/>Logo</span>
                          <div className="bar-fill">
                            <span className="progress-bar-arrow"></span>
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem className="step-item">
                        <NavLink
                          className={classnames({
                            // active: this.state.hTabs === 'shareStore',
                            active: create_store_step === 'shareStore',
                          })}
                          // onClick={(e) => this.toggleTabs(e, "hTabs", 'shareStore')}
                          // href="#pablo"
                        >
                          <div className="step-item-tag">3.</div>
                          <span>Share <br/>Your <br/>Store</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      className="tab-space"
                      // activeTab={"hTabs-" + this.state.hTabs}
                      activeTab={"hTabs-" + create_store_step}
                    >
                      <TabPane tabId="hTabs-select_gear">
                        {/* <BaseSelection /> */}
                        <SelectGear tabToggle ={this.toggleTabs} />
                        {/* <Category setSelectedCats={this.getSelectedCats}/> */}
                        {/* <Pagination_demo 
                          setSelectedCats={this.getSelectedCats}
                        /> */}
                      </TabPane>
                      
                      <TabPane tabId="hTabs-addLogo">
                        {/* {
                          this.props.style_item  <AddLogo style_item={this.props.style_item}/>
                          ? <></>
                          : <></>
                        } */}
                        <LogoSelection tabToggle ={this.toggleTabs} />
                        
                        {/* <Catalog 
                          seletedCat={this.state.seletedCat}
                          active={this.state.hTabs === 2 && true}
                        /> */}
                      </TabPane>
                        
                      <TabPane tabId="hTabs-shareStore">
                        <ShareYourStore tabToggle ={this.toggleTabs} />
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>

                {/* <Row className="align-items-md-center justify-content-md-center">
                  <Col md="12"> */}
                    {/* <p className="category">Horizontal tabs</p>
                    <Nav
                      className="nav-pills-primary justify-content-center"
                      pills
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.hTabs === 'selection',
                          })}
                          // onClick={(e) => this.toggleTabs(e, "hTabs", 'selection')}
                          //href="#pablo"
                        >
                          1.Select Your Gear
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.hTabs === 'addLogo',
                          })}
                          // onClick={(e) => this.toggleTabs(e, "hTabs", 'addLogo')}
                          //href="#pablo"
                        >
                          2.Add Your Logo
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.hTabs === 'shareStore',
                          })}
                          // onClick={(e) => this.toggleTabs(e, "hTabs", 'shareStore')}
                          // href="#pablo"
                        >
                          3.Share Your Store
                        </NavLink>
                      </NavItem>
                    </Nav> */}
                    {/* <TabContent
                      className="tab-space"
                      activeTab={"hTabs-" + this.state.hTabs}
                    > */}
                      {/* <TabPane tabId="hTabs-selection"> */}
                        {/* <BaseSelection /> */}
                        {/* <SelectGear /> */}
                        {/* <Category setSelectedCats={this.getSelectedCats}/> */}
                        {/* <Pagination_demo 
                          setSelectedCats={this.getSelectedCats}
                        /> */}
                      {/* </TabPane> */}
                      
                      {/* <TabPane tabId="hTabs-addLogo"> */}
                        {
                          this.props.style_item 
                          ? <></>/*<AddLogo style_item={this.props.style_item}/>*/ 
                          : <></>
                        }
                        
                        {/* <Catalog 
                          seletedCat={this.state.seletedCat}
                          active={this.state.hTabs === 2 && true}
                        /> */}
                      {/* </TabPane> */}
                        
                      {/* <TabPane tabId="hTabs-shareStore"> */}
                        {/* Completely synergize resource taxing relationships via
                        premier niche markets. Professionally cultivate
                        one-to-one customer service with robust ideas. <br />
                        <br />
                        Dynamically innovate resource-leveling customer service
                        for state of the art customer service. */}
                      {/* </TabPane> */}
                    {/* </TabContent> */}
                  {/* </Col>
                </Row> */}
                
              </Container>
            </section>
          </div>
        </div>
        <CustomFooter />
      </>
    );
  }
}
const mapStateToProps = state => {
	return {
		create_store_step: state.baseSelection.create_store_step
	}
}
// const mapDispatchToProps = dispatch => ({
// 	stepBackToCategorySelection: () =>
// 		dispatch({ type: STEP_BACK_TO_CATEGORY_SELECTION })
// });
export default connect(mapStateToProps, null)(BuildStore)
