import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'

import rootReducer from './reducers'
import { promiseMiddleware, localStorageMiddleware } from './middleware'

function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify({auth: state.auth, common: state.common})
        console.log('saveToLocalStorage', state)
        localStorage.setItem('jam_micro', serializedState)
    } catch (e) {
        console.log(e)
    }
}
function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('jam_micro')
        if (serializedState === null) return undefined
        if(serializedState !== null){
            console.log('loadFromLocalStorage', JSON.parse(serializedState))
            return JSON.parse(serializedState);
        }
    } catch (e) {
        console.log(e)
        return undefined
    }
}
const persistedState = loadFromLocalStorage()
console.log('persistedState', persistedState)

export const store = createStore(
    rootReducer, 
    persistedState,
    // {},
    applyMiddleware(/* logger, */ thunk, /* promiseMiddleware, */ localStorageMiddleware)
);
store.subscribe(() => saveToLocalStorage(store.getState()))


//console.log('Store State', store.getState());
//store.subscribe(() => console.log('store subscribe', store.getState()));