import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import defaultImage from "assets/img/emily.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

class UploadImageFile extends React.Component {
  constructor(){
    super()
    this.state = {
      file: null,
      // imagePreviewUrl: defaultAvatar
      imagePreviewUrl: defaultAvatar
    }
    this.fileInput= React.createRef();
  }
  handleImageChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0]
    
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
      // passing image base64 data to parent component ( my-details ) for update
      this.props.handleChange({target: { name: 'profile_image', value: reader.result }})
    };
    reader.readAsDataURL(file);
  }
  handleSubmit = (e) => {
    e.preventDefault();
  }
  handleClick = () => {
    this.fileInput.current.click();
  }
  handleRemove = () => {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.fileInput.current.value = null;
  }
  render() {
    const {avatar} = this.props
    return (
      <div className="fileinput text-center edit-customer-image">
        <input type="file" onChange={this.handleImageChange} ref={this.fileInput} />
        <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
          <img src={avatar || this.state.imagePreviewUrl} alt="..." className="uploaded-image" />
        </div>
        <div>
          {this.state.file === null ? (
            <Button
              color={this.props.addBtnColor}
              className={this.props.addBtnClasses + " edit-select-btn"}
              onClick={() => this.handleClick()}
            >
              {this.props.avatar ? "Add Photo" : "Select image"}
            </Button>
          ) : (
            <span>
              <Button
                color={this.props.changeBtnColor}
                className={this.props.changeBtnClasses + " edit-select-btn"}
                onClick={() => this.handleClick()}
              >
                Update image
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

UploadImageFile.defaultProps = {
  avatar: '',
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary"
};

UploadImageFile.propTypes = {
  avatar: PropTypes.string,
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ])
};

export default UploadImageFile;
