import React, { Component } from 'react'
import {
    Row,
    Col,
    Button,
    Modal, ModalHeader, ModalBody,ModalFooter,Alert
} from "reactstrap";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import EditCustomerDetails from './edit-customer-modal'
import agent from '../../../services/api-endpoint'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    UPDATE_PROFILE
} from '../../../redux/actions/actionTypes'
import action_middleware from '../../../redux/action-creator'
import UploadImageFile from './upload-image-file'
import defaultAvatar from "assets/img/placeholder.jpg";
import Notification from '../../../components/Custom/Notification'




class CustomerDetails extends Component {
    state = {
        isOpenModal: false,
        user: null,
        isLoading: false,
        isEditable: false,
        data: null,
        notification: null
    }
    componentDidMount = () =>  this.setUser()
    setUser = () => {
        const {first_name, last_name, phone, profile_image} = this.props.user,
            {address, city, country, zip, state} = this.props.user.registrationAddress,
            {data} = this.state
        this.setState({
            user: this.props.user,
            data: {...data, first_name, last_name, phone, profile_image, address, city, country, zip, state}
        })
    }
    toggleModal = () => {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        });
    }
    handleChange = e => {
        let {name, value} = e.target
        let data = {...this.state.data}
        data[name] = value
        this.setState({data})
    }
    handleUpdate = e => {
        e.preventDefault();
        this.setState({isLoading: true})
        this.props.action_middleware(UPDATE_PROFILE, agent.Auth.update_profile(this.state.data, this.props.token))
        .then(store_state => {
            this.setState({
                isLoading: false,
                isEditable: false,
                notification: this.props.notification,
            })
            this.setUser()
        })
    }
    onDismiss = () => this.setState({notification: null})

    render() {
        if(!this.state.data)
            return <></>
        else {  
            const {data, notification} = this.state,
                {profile_image} = data
            
            return (
                <>
                {
                    (notification && 'update_profile' in notification) && 
                    <Notification delay="5000" onExpire={this.onDismiss}>
                        <div className="alert-section">
                            <Alert
                                color={notification.update_profile.status}
                                isOpen={notification.update_profile && true}
                                toggle={this.onDismiss}
                                className ="fadeIn slow"
                            >
                                <strong>{notification.update_profile.msg}</strong>
                            </Alert>
                        </div>
                    </Notification>
                }
                <section className="customer-my-details-sec">
                    <Row>
                        <div className="customer-img-sec">
                            <Col md="12">
                                {
                                    this.state.isEditable ?
                                    <UploadImageFile 
                                        avatar={profile_image}
                                        handleChange={this.handleChange}
                                    /> :
                                    <img src={profile_image || defaultAvatar} alt="..." className="customer-details-img" />
                                }
                            </Col>
                        </div>
                    </Row>
                    <Row>
                        <div className="personal-info-sec">
                            <h2 className="title md-12">Personal Info</h2>
                            <Col md="6">
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item sm={12}>
                                        <TextField
                                            label="First Name"
                                            placeholder="First Name"
                                            type="text"
                                            fullWidth
                                            name="first_name"
                                            value={data.first_name}
                                            disabled={!this.state.isEditable}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Col>
                            <Col md="6">
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item sm={12}>
                                        <TextField
                                            label="Last Name"
                                            placeholder="Last Name"
                                            type="text"
                                            fullWidth
                                            name="last_name"
                                            value={data.last_name}
                                            disabled={!this.state.isEditable}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Col>
                            {/* <Col md="6">
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item sm={12}>
                                        <TextField
                                            id="email"
                                            label="Email"
                                            placeholder="Email"
                                            type="email"
                                            fullWidth
                                            name="email"
                                            value={registrationAddress.email}
                                            disabled={!this.state.isEditable}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Col> */}
                            <Col md="6">
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item sm={12}>
                                        <TextField
                                            label="Phone Number"
                                            placeholder="Phone Number"
                                            type="text"
                                            fullWidth
                                            name="phone"
                                            value={data.phone}
                                            disabled={!this.state.isEditable}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Col>
                            {/* <Col md="6">
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item sm={12}>
                                        <TextField
                                            label="Password"
                                            placeholder="Password"
                                            type="password"
                                            fullWidth
                                            name="pass"
                                        />
                                    </Grid>
                                </Grid>
                            </Col>
                            <Col md="6">
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item sm={12}>
                                        <TextField
                                            label="Repeat Password"
                                            placeholder="Repeat Password"
                                            type="password"
                                            fullWidth
                                            name="repPass"
                                        />
                                    </Grid>
                                </Grid>
                            </Col> */}
                        </div>
                    </Row>
                    <Row>
                        <div className="location-info-sec">
                            <h2 className="title md-12">Location</h2>
                            <Col md="3">
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item sm={12}>
                                        <TextField
                                            label="Postal or Zip Code"
                                            placeholder="Postal or Zip Code"
                                            type="text"
                                            fullWidth
                                            name="zip"
                                            value={data.zip}
                                            disabled={!this.state.isEditable}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Col>
                            <Col md="3">
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item sm={12}>
                                        <TextField
                                            label="City"
                                            placeholder="City"
                                            type="text"
                                            fullWidth
                                            name="city"
                                            value={data.city}
                                            disabled={!this.state.isEditable}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Col>
                            <Col md="3">
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item sm={12}>
                                        <TextField
                                            label="Country"
                                            placeholder="Country"
                                            type="text"
                                            fullWidth
                                            name="country"
                                            value={data.country}
                                            disabled={!this.state.isEditable}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Col>
                            <Col md="3">
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item sm={12}>
                                        <TextField
                                            label="State"
                                            placeholder="state"
                                            type="text"
                                            fullWidth
                                            name="state"
                                            value={data.state}
                                            disabled={!this.state.isEditable}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Col>
                            <Col md="12">
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item sm={12}>
                                        <TextField
                                            label="Address"
                                            placeholder="Address"
                                            type="text"
                                            fullWidth
                                            name="address"
                                            value={data.address}
                                            disabled={!this.state.isEditable}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Col>
                        </div>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="my-details-updates-sec">
                                {/* <Button className="my-details-update-btn" onClick={this.toggleModal}>
                                    <i className="fas fa-edit"></i>Edit Modal
                                </Button> */}
                                {
                                    !this.state.isEditable ?
                                    <Button className="my-details-edit-btn" onClick={() => this.setState({isEditable: true})}>
                                        <i className="fas fa-edit"></i>Edit
                                    </Button> :
                                    <>
                                    <Button className="my-details-update-btn" onClick={this.handleUpdate}>
                                        {this.state.isLoading ? 'Updating...' : 'Update'}
                                    </Button>
                                    {
                                        !this.state.isLoading &&
                                        <Button className="my-details-cancel-btn" onClick={() => this.setState({isEditable: false})}>
                                            Cancel
                                        </Button>
                                    }
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                    <Modal 
                        isOpen={this.state.isOpenModal} 
                        toggle={this.toggleModal} 
                        className={this.props.className + " edit-customer-details-modal" } 
                    >
                        <form>
                            <ModalHeader toggle={this.toggleModal}>Edit Customer Profile</ModalHeader>
                            <ModalBody>
                                <EditCustomerDetails />
                            </ModalBody>
                            <ModalFooter>
                            <Button 
                                color='primary' 
                                onClick={this.toggle} 
                                className="edit-customer-btn ml-auto">Update</Button>
                            </ModalFooter>
                        </form>
                    </Modal>
                </section>
                </>
            )
        }
    }
}



const mapStateToProps = state => {
    return {
        user: state.auth.user,
        token: state.auth.token,
        notification: state.notification
    }
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails)
