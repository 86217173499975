import React, { Component } from 'react'
import {
    Row,
    Col,
    Button
} from "reactstrap";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import UploadImageFile from './upload-image-file'

class EditCustomerDetails extends Component {
    render() {
        return (
            <section className="customer-my-details-sec">
                <Row>
                    <div className="customer-img-sec">
                        <Col md="12">
                            <UploadImageFile />
                        </Col>
                    </div>
                </Row>
                <Row>
                    <div className="personal-info-sec">
                        <h2 className="title md-12">Personal Info</h2>
                        <Col md="6">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        label="First Name"
                                        placeholder="First Name"
                                        type="text"
                                        fullWidth
                                        name="first_name"
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                        <Col md="6">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        label="Last Name"
                                        placeholder="Last Name"
                                        type="text"
                                        fullWidth
                                        name="last_name"
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                        <Col md="6">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        id="email"
                                        label="Email"
                                        placeholder="Email"
                                        type="email"
                                        fullWidth
                                        name="email"
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                        <Col md="6">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        label="Phone Number"
                                        placeholder="Phone Number"
                                        type="text"
                                        fullWidth
                                        name="phone_number"
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                        <Col md="6">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        label="Password"
                                        placeholder="Password"
                                        type="password"
                                        fullWidth
                                        name="pass"
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                        <Col md="6">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        label="Repeat Password"
                                        placeholder="Repeat Password"
                                        type="password"
                                        fullWidth
                                        name="repPass"
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <div className="location-info-sec">
                        <h2 className="title md-12">Location</h2>
                        <Col md="4">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        label="Postal or Zip Code"
                                        placeholder="Postal or Zip Code"
                                        type="text"
                                        fullWidth
                                        name="postal_code"
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                        <Col md="4">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        label="City"
                                        placeholder="City"
                                        type="text"
                                        fullWidth
                                        name="city"
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                        <Col md="4">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        label="Country"
                                        placeholder="Country"
                                        type="text"
                                        fullWidth
                                        name="country"
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                        <Col md="12">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        label="Address"
                                        placeholder="Address"
                                        type="text"
                                        fullWidth
                                        name="address"
                                    />
                                </Grid>
                            </Grid>
                        </Col>
                    </div>
                </Row>
            </section>
        )
    }
}

export default EditCustomerDetails
