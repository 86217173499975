import React from 'react'
import {
    Row,
    Col,
    Button  
} from "reactstrap";
import iconDrop from '../../../../../../assets/img/dropper.png';
import TableContainer from "@material-ui/core/TableContainer";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import PersonalizeColorPicker from '../personalize-color-picker'

class FinalizeUploadMascot extends React.Component{
    render(){
        return (
            <div className="upload-mascot-finalize">
                <Row>
                    <Col md="12" className="mb-2">
                        <Button className="back-mascot-btn" onClick={this.props.edituploadmascot}>
                            <i className="fas fa-arrow-left"></i>
                            Back
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className="mascot-finalize-img-sec">
                            <p className="subtitle">
                                <img src={iconDrop} alt="..." className="icon-image" />
                                Use the eyedropper tool by clicking on a color you wish to remove from your image
                            </p>
                            <div className="selected-img-sec">
                                <img src={require('assets/img/shirt.png')} alt="..." className="img-round" />
                            </div>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="mascot-finalize-desc">
                            <h3 className="title">Match and Combined colors</h3>
                            <p className="sub-title">for color accuracy, please assign your current colors to our ink color options</p>
                            <div className="finalize-color-sec">
                                <div className="finalize-color-select">
                                    <div className="color-header-row">
                                        <div className="color-header-col">
                                            <h4>Current Color</h4>
                                        </div>
                                        <div className="color-header-col">
                                            <h4>alter Color</h4>
                                        </div>
                                    </div>
                                    <div className="color-row">
                                        <div className="current-color-sec color-col">
                                            <div className="current-color"></div>
                                        </div>
                                        <div className="alter-color color-col">
                                            <PersonalizeColorPicker />
                                        </div>
                                        <div className="color-col">
                                            <Button className="upload-mascot-delet-btn ml-auto">
                                                <i className="fas fa-trash"></i>
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="color-row">
                                        <div className="current-color-sec color-col">
                                            <div className="current-color"></div>
                                        </div>
                                        <div className="alter-color color-col">
                                            <PersonalizeColorPicker />
                                        </div>
                                        <div className="color-col">
                                            <Button className="upload-mascot-delet-btn ml-auto">
                                                <i className="fas fa-trash"></i>
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="color-row">
                                        <div className="current-color-sec color-col">
                                            <div className="current-color"></div>
                                        </div>
                                        <div className="alter-color color-col">
                                            <PersonalizeColorPicker />
                                        </div>
                                        <div className="color-col">
                                            <Button className="upload-mascot-delet-btn ml-auto">
                                                <i className="fas fa-trash"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="12">
                        <Button className="mascot-edit-continue-btn">
                            Save Mascot Logo
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default FinalizeUploadMascot