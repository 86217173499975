import React from 'react';
import {
    Card,
    CardBody,
    CardImg,
    CardTitle,
    Button
} from "reactstrap";
import shippingAddressIcon from '../../../assets/img/shipping_addr.png';
import shippingCheckIcon from '../../../assets/img/shipping_check.png';
import Swal from 'sweetalert2'
import { connect } from 'react-redux'
import {EDIT_SHIPPING_ADDRESS} from '../../../redux/actions/actionTypes'
import action_middleware from '../../../redux/action-creator'
import { bindActionCreators } from 'redux'
import agent from '../../../services/api-endpoint'
import {UPDATE_SHIPPING_ADDRESS} from '../../../redux/actions/actionTypes'
import SpinnerLoader from '../../../assets/img/loader/spinner-loader.gif'


const swalParentsClass = Swal.mixin({
    customClass: {
      title: 'customer-delete-title',
      container:'customer-swal-container',
      confirmButton: 'btn btn-confirm-delete',
      cancelButton: 'btn btn-delete'
    },
    buttonsStyling: false
})
const swalChildClass = Swal.mixin({
    customClass: {
      title: 'customer-delete-title',
      container:'customer-swal-container',
      confirmButton: 'btn btn-confirm',
    },
    buttonsStyling: false
})

class ShippingCardDetails extends React.Component {

    handleEdit = item => {
        this.props.toggleView('editAddress')
        this.props.action_middleware(EDIT_SHIPPING_ADDRESS, item)
    }
    handleSetDefault = item => {
        swalParentsClass.fire({
            title: 'Do you want to set the address as Default?',
            // text: 'You won\'t be able to revert this !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, change it !'
        }).then(result => {
            if(result.value){
                Swal.fire({
                    html: `<img src=${SpinnerLoader} /><p style="color: #000">Please keep patient...</p>`,
                    showConfirmButton: false
                })
                item.default = 1
                let obj = { data: [ item ] }
                this.props.action_middleware(UPDATE_SHIPPING_ADDRESS, agent.Profile.updateShippingAddress(obj, this.props.token))
                .then(store_state => {
                    swalChildClass.fire({
                        title: "Default address is changed!",
                        // text: "Address has been removed.",
                        icon: "success",
                        confirmButtonText: "okay !"
                    });
                })
            }
        })
    }
    
    render() {
        const {item, deleteShippingAddress} = this.props
        return (
            <Card className={`shipping-card${item.default === 1 ? ' active' : ''}`}>
                <CardBody className="shipping-card-card-content">
                    <div className="shipping-card-thumb">
                        <CardImg
                            top
                            alt="..."
                            src={shippingAddressIcon}
                        />
                        <p className="shipping-card-title">
                            <span>Address</span> 
                            {
                                item.default === 1 && 
                                <span className="material_checkbox_term">
                                    <img src={shippingCheckIcon} alt = "..." />
                                </span>
                            }
                        </p>
                    </div>
                    <div className="shipping-card-desc">
                        <p className="zip">{item.zip}</p>
                        <p className="other">
                            {item.address && <span className="street">{item.address}</span>}
                        </p>
                        <p className="other">
                            {item.city && <span className="city">{item.city}</span>}
                            {item.state && <span className="state">{item.state}</span>}
                            {item.country && <span className="country">{item.country}</span>}
                        </p>
                    </div>
                    <div className="shipping-card-footer">
                        <div className={item.default !== 1 ? "shipping-btn-sec set-as-default-sec" : "shipping-btn-sec"}>
                            <Button 
                                className="shipping-card-manage-btn"
                                onClick= {() => this.handleEdit(item)}
                            >
                                <i className="fas fa-user-edit"></i>
                            </Button>
                            {
                                (item.default !== 1 && item.registrationAddress !== 1) &&
                                <Button 
                                    className="shipping-card-delete-btn"
                                    onClick = {() => deleteShippingAddress(item.id)}
                                >
                                    <i className="fas fa-trash"/>
                                </Button>
                            }
                        </div>
                        {
                            item.default !== 1 && 
                            <div 
                                className="set-as-default" 
                                onClick={() => this.handleSetDefault(item)}
                            >Set as default</div>
                        }
                    </div>
                </CardBody>
            </Card>
        )
    }
}

const mapStateToProps = state => (
    {
        token: state.auth.token
    }
)
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(ShippingCardDetails);