import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TreeItem from '@material-ui/lab/TreeItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isTemplateMiddle } from 'typescript';


class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: ["1"],
            selected: ["1"],
            // checked: true,
            features: [
                {label: "Headwear", value: "headwear", total: "324"},
                {label: "Towels", value: "towels", total: "313"},
                {label: "3/4 Sleeve", value: "3/4-sleeve", total: "10"},
                {label: "Jersey", value: "Jersey", total: "0"},
            ],
        }
    }

    handleToggle = (event, nodeIds) => {
        this.setState({
            expanded: nodeIds
        })
    }
    handleSelect = (event, nodeIds) => {
        this.setState({
            selected: nodeIds
        })
    }
    handleChange = (event, item) => {
        this.props.handleSelect(item, "features");
    };

    render() {
        return (
            <div className="feature-filter-sec">
                <TreeView
                    defaultCollapseIcon={<RemoveIcon />}
                    defaultExpandIcon={<AddIcon />}
                    expanded={this.state.expanded}
                    selected={this.state.selected}
                    onNodeToggle={this.handleToggle}
                    onNodeSelect={this.handleSelect}
                >
                    <TreeItem nodeId="1" label="Category">
                        {
                            this.state.features.map((item, index) => 
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            color="primary" 
                                            value={item.value} 
                                            name="category" 
                                            checked={this.props.selected.some(v => v.value === item.value)}
                                            onChange={e => this.handleChange(e, item)} 
                                        />

                                    }
                                    label={item.label + " (" + item.total + ")"}
                                    key={index}
                                    style={{width: '100%'}}
                                />
                            )
                        }
                        {/* <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="Any Features (324)"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="100% Cotton (313)"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="Moisture-wicking (10)"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="Dry-Mesh"
                        /> */}
                    </TreeItem>
                </TreeView>
            </div>
        )
    }
}

export default Category;