import {
    // SHOW_STYLE_TO_ADD_LOGO,
    // FETCH_PRODUCT_BY_STYLE,
    // STEP_BACK_TO_STYLE_SELECTION,
    SET_LOGO_DETAILS,
    EDIT_LOGO_DETAILS
} from '../actions/actionTypes'


let initialState = {
    texts: null,
    colors: null,
    selected_div: null,
    showUploadMascot: false,
};

const AddLogoReducer = (state=initialState, action) => {

    switch (action.type) {

        case SET_LOGO_DETAILS:
            return {
                ...state,
                texts: action.payload.texts,
                colors: action.payload.colors,
                selected_div: action.payload.selected_div,
                showUploadMascot: action.payload.showUploadMascot
            }

        case EDIT_LOGO_DETAILS:
            return {
                ...state,
                [action.payload.key]: action.payload.value
            }

        // case SHOW_STYLE_TO_ADD_LOGO:
        //     return {
        //         ...state,
        //         style_item: action.payload
        //     }

        // case FETCH_PRODUCT_BY_STYLE:
        //     //console.log('FETCH_PRODUCT_BY_STYLE', action)
        //     let {success, payload} = action
            
        //     if(success && payload && 'data' in payload){
        //         return {
        //             ...state,
        //             style_products: payload.data
        //         };
        //     }

        // case STEP_BACK_TO_STYLE_SELECTION:
        //     return {
        //         ...state,
        //         style_item: null,
        //         style_products: null
        //     }
        
        default:
            return state;
    }
}

export default AddLogoReducer