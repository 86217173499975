import React from "react";
import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import agent from '../../services/api-endpoint';
import { Table } from 'reactstrap';

class PrivacyPolicy extends React.Component {
    
    componentDidMount() {
        document.body.classList.add("page");
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.wrapper.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.remove("page");
    }

    render(){
        const {site_slug} = agent;
        return (
            <>
            <CustomNavbar />

            <div className="wrapper" ref="wrapper">
                <div className ="main">
                    <div className="space-100" />
                    <section className="all-pages privacy-policy-page section">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="all-pages-header">
                                        <h1><span>Privacy</span> <span>policy</span></h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>INTRODUCTION</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>Namaste Ventures LLC, d/b/a Campus Wear Direct, and its affiliates (collectively referred to as <strong> <q>Campus Wear Direct,</q> <q>we,</q> <q>our</q> </strong> and <strong> <q>us</q> </strong> ) are concerned about privacy issues and want you to be familiar with how we collect, use and disclose information. This Privacy Policy (this <q>Policy</q>) describes the types of information we may collect from you or that you may provide when you visit <Link to="/">www.CampusWearDirect.com/</Link> (our <strong> <q>Website</q></strong> ) and discusses our practices for collecting, using, maintaining, protecting and disclosing that information.</p>
                                        <p className="space-10"></p>
                                        <p>Please read this Policy carefully to understand our policies and practices regarding your information and how we will treat it. This Policy applies to any personal information, and, in certain applicable instances, other information, collected on our Website and collected through our business operations. We use the information we collect from you to improve our Website and to provide you with a more personalized experience on our Website. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using our Website, you agree to this Policy. This Policy may change from time to time (see <strong> CHANGES TO OUR PRIVACY POLICY </strong> ). Your continued use of our Website after we make changes is deemed to be acceptance of those changes, so please check this Policy periodically for updates</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>INFORMATION WE COLLECT ABOUT YOU</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>When we collect information from you, our primary goal is to provide you with an efficient and more personalized experience on our Website. We collect information about you, including Personally Identifiable Information (<strong> <q>PII</q> </strong> ), from the information you voluntarily provide to us and through automatic data collection technology. PII is any information that can be used on its own or with other information to personally identify you as an individual. In some jurisdictions, PII may also include technical information such as Internet Protocol ( <strong> <q>IP</q> </strong> ) addresses. PII that you may voluntarily provide to us includes, without limitation, the following:</p>
                                        <p className="space-10"></p>
                                        <ul className="return-policy-list-content">
                                            <li>Name</li>
                                            <li>E-mail address</li>
                                            <li>Phone number and</li>
                                            <li>Address</li>
                                        </ul>
                                        <p className="space-10"></p>
                                        <p>Information that we collect through automatic data collection technology includes, without limitation, information about your internet connection, IP address, referrers, search terms, page views, operating system and browser type.</p>
                                        <p>If you submit any PII relating to other people to us in connection with our Website, you represent that you have the authority to do so and to permit us to use such PII in accordance with this Policy.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>HOW WE MAY COLLECT YOUR INFORMATION</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>We may collect information in several ways, including:</p>
                                        <p className="space-10"></p>
                                        <ul className="return-policy-list-content">
                                            <li><strong> Directly from you:</strong> If you contact us, then we may collect information, including PII, through records and copies of your correspondence with us. We may also collect information, including PII, when you register to use our Website, place orders through our Website, subscribe to services, post material, request further services, report a problem with our Website or respond to our questions via e-mail, survey or feedback forms. If we collect information directly from you, then you may have the option to refuse to provide us with such information. However, if you decline to provide us information, then this may impact your ability to use our Website.</li>
                                            <li><strong> Offline:</strong> You may provide information, including PII, to us when you contact a Website representative.</li>
                                            <li><strong> Through cookies (or browser cookies): </strong> A cookie is a piece of data stored on the user&#39;s computer tied to information about the user. Cookies allow our Website to serve the user with specific information tied to the user and help facilitate ongoing access to our Website. You may refuse to accept cookies by activating the appropriate setting on your browser. However, if you select this setting, then you may be unable to access certain parts of our Website. This Policy covers the use of cookies by only us. We may use the following types of cookies on our Website:
                                                <ul className="return-policy-list-content">
                                                    <li>Essential Cookies &#8210; These cookies allow you to browse our Website and use certain features. If you disable these essential cookies, then you may be unable to use certain features.</li>
                                                    <li>Advertising Cookies &#8210; These cookies gather information about your use of our Website so that we can improve your experience and provide you with more relevant content and advertising. These cookies are also used to gather feedback on certain marketing materials.</li>
                                                    <li>Preference Cookies &#8210; These cookies allow us to recognize your device so that you do not have to provide us with the same information more than once. If you disable these preference cookies, then our Website may not be able to remember certain choices that you previously made or personalize your browsing experience.</li>
                                                    <li>Performance Cookies &#8210; These cookies collect information about how you use our Website, such as which pages you most frequently visit. We use performance cookies to provide you with a high-quality experience by doing things such as tracking page load, site response times and error messages.</li>
                                                </ul>
                                            </li>
                                            <li><strong> Through Flash cookies:</strong> Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, please see the CHOICES AND ACCESS section of this Policy.</li>
                                            <li><strong> Through server log files: </strong> Like most standard websites, our Website uses log files to collect information about your computer and internet connection, which may include information about your IP address, browser type, internet service provider, referring/exit pages, platform type, date/time stamp, and number of clicks. This information may be used to analyze trends, administer the site, track your movement in the aggregate, and gather broad demographic information for aggregate use.</li>
                                            <li><strong> Through web beacons: </strong> Web beacons are small electronic files that permit us, for example, to count the number of users who have visited certain pages of our Website, open a particular e-mail or other related website statistics.</li>
                                            <li><strong> Through internet tags: </strong> Internet tags are smaller than cookies and tell our Website server information such as the IP address and browser type related to your computer.</li>
                                            <li><strong> Through third parties:</strong> Information that we receive from our business partners and third party servicers.</li>
                                        </ul>
                                        <p className="space-10"></p>
                                        <p>We will rely on consent, which in some cases may be implied, to use technical information that is collected through our use of cookies, Flash cookies, server log files, web beacons or internet tags. You may withdraw consent at any time by contacting us as described in this Policy.</p>
                                        <p className="space-10"></p>
                                        <p>We may also use cookies, Flash cookies, web beacons, server log files and internet tags to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). Note that your browser settings may allow you to send a <q>Do Not Track</q> signal to websites you visit. If you elect to send a <q>Do Not Track</q> signal when you visit our Website, we will not track your visit to our Website. To find out more about <q>Do Not Track</q> signals, visit http://www.allaboutdnt.com.</p>
                                        <p className="space-10"></p>
                                        <p>The information we collect automatically is statistical data and may, depending on applicable law, include PII, and we may maintain it or associate it with PII we collect in other ways. It helps us to improve our Website by enabling us to estimate our audience size and usage patterns, speed up your searches, recognize you when you return to our Website and store information about your preferences.</p>
                                        <p className="space-10"></p>
                                        <p>You may also provide information to be posted on public areas of our Website, or transmitted to other users of our Website or third parties (collectively, <strong> <q>User Contributions</q> </strong> ). Your User Contributions are posted on and transmitted to others at your own risk. Although you may set certain privacy settings for such information by logging into your account profile, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of our Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>HOW WE MAY USE YOUR INFORMATION</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>To the extent permitted by applicable law, we may use information, including PII that we collect:</p>
                                        <p className="space-10"></p>
                                        <ul className="return-policy-list-content">
                                            <li>To contact you through email correspondence.</li>
                                            <li>To provide you with information, products or services that you request from us.</li>
                                            <li>To present our Website and its contents to you in a fashion customized to match your preferences.</li>
                                            <li>To personalize your experience on our Website.</li>
                                            <li>To allow you to participate in surveys, quizzes and other interactive features on our Website.</li>
                                            <li>To send administrative information to you, for example, information regarding our Website and changes to our terms, conditions, features and policies.</li>
                                            <li>To notify you of changes to any products or services we offer or provide through our Website.</li>
                                            <li>To provide you with certain promotional offers.</li>
                                            <li>To permit you to participate on message boards, chat rooms, personal web pages or profiles, forums and blogs to which you are permitted to post information and materials.</li>
                                            <li>To carry out obligations and enforce our rights arising from any contract entered into between you and us.</li>
                                            <li>To pursue our legitimate interests.</li>
                                            <li>To comply with legal process.</li>
                                            <li>To respond to request from public and government authorities.</li>
                                            <li>To enforce our <Link to="/terms-conditions">Terms and Conditions</Link>.</li>
                                            <li>To protect our operations or those of any of our affiliates.</li>
                                            <li>To protect our rights, privacy, safety or property, and/or that of our affiliates, you or others.</li>
                                            <li>To allow us to pursue available remedies or limit the damages that we sustain.</li>
                                            <li>For any other purpose with your consent.</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>DISCLOSURE OF YOUR INFORMATION</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.</p>
                                        <p className="space-10"></p>
                                        <p>To the extent permitted by applicable law, your PII may be disclosed:</p>
                                        <p className="space-10"></p>
                                        <ul className="return-policy-list-content">
                                            <li>To identify you to anyone to whom you communicate on our Website.</li>
                                            <li>To our subsidiaries and affiliates for the purposes described in this Policy.</li>
                                            <li>To contractors, service providers, and other third parties who we use to support our business.</li>
                                            <li>By you, on message boards, chat rooms, personal web pages or profiles, forums, blogs or other services to which you are able to post information and materials.</li>
                                            <li>If you participate in social sharing (such as if you connect to your social media account), to your friends associated with your social media account, to other users of the social media website, and to your social media account provider, in connection with your social sharing activity.</li>
                                            <li>To any third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of any or all portion of our business, assets or equity interests, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding.</li>
                                            <li>To comply with any court order, law or legal process, including to respond to any government or regulatory request.</li>
                                            <li>To enforce or apply our <Link to="/terms-conditions">Terms and Conditions</Link> and other agreements that we may have with you, including for billing and collection purposes.</li>
                                            <li>To third parties for their direct marketing purposes.</li>
                                            <li>If we believe disclosure is necessary or appropriate to protect the rights, property or safety of us or others.</li>
                                            <li>With your consent.</li>
                                            <li>For any other purpose disclosed by us when you provide the information.</li>
                                        </ul>
                                        <p className="space-10"></p>
                                        <p>We will not disclose or sell any PII to any unaffiliated third party for direct marketing purposes without your consent.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>THIRD PARTY COLLECTION, USE, AND DISCLOSURE OF YOUR INFORMATION</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>Our Website may contain links to various third party websites. These third party websites may collect PII and other related information. This Policy does not address, and we are not responsible for, the privacy, information or other practices of any third party, including any third party operating any site to which our Website contains a link. The inclusion of a link on our Website does not imply endorsement of the linked site by us or any of our subsidiaries or affiliates.</p>
                                        <p className="space-10"></p>
                                        <p>Additionally, some content or applications on our Website are served by third parties. These third parties may use cookies or other tracking technologies to collect information about you when you use our Website. The information they collect may be associated with your PII or they may collect information, including PII, about your online activities over time and across different websites and other online services.</p>
                                        <p className="space-10"></p>
                                        <p>We do not control these third parties&#39; tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see the CHOICES AND ACCESS section of this Policy.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>CHOICES AND ACCESS</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>We strive to provide you with choices regarding our use and disclosure of PII. We have created mechanisms to provide you with the following control over your information, including your PII:</p>
                                        <p className="space-10"></p>
                                        <ul className="return-policy-list-content">
                                            <li>You can set your browser to refuse all or some browser cookies or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, please visit the Flash player settings page on Adobe&#39;s website. If you disable or refuse cookies, please note that some parts of our Website may then be inaccessible or not function properly.</li>
                                            <li>If at any time you wish to stop receiving communication from us, please just let us know by contacting us using the contact information listed below (see <Link to="#contactinformation"> CONTACT INFORMATION </Link>).</li>
                                            <li>In addition, we will not disclose your PII to third parties, including our subsidiaries, for the third party&#39;s direct marketing purposes if we have received and processed a request from you that your PII not be shared with third parties for that purpose. If you would like to submit such a request, please contact us using the contact information listed below (see <Link to="#contactinformation"> CONTACT INFORMATION </Link>).</li>
                                        </ul>
                                        <p className="space-10"></p>
                                        <p>You can review and change your PII or other information by logging into our Website and visiting your account profile page. You may also review, correct, update, delete or otherwise limit our use of your PII or other information (such as behavioral tracking) by contacting us using the contact information listed below (see <Link to="#contactinformation"> CONTACT INFORMATION </Link>). However, please note that we cannot delete your PII except by also deleting your user account and we may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
                                        <p className="space-10"></p>
                                        <p>If you delete User Contributions, copies of your User Contributions may remain viewable in archived pages, or may have been copied or stored by other Website users.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>SECURITY</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>When users submit sensitive information via our Website, their information is protected both online and offline. Prevention of unauthorized access or disclosure of data is of the utmost importance. Physical, administrative and technical procedures are employed to safeguard all collected information.</p>
                                        <p className="space-10"></p>
                                        <p>All payment and data transactions occurring over a public network (i.e. the Internet) are encrypted using SSL technology. Specific certifier details can be inspected in your browser during a secure session (see browser-specific help for details). Our Website also uses two-step verification to confirm a particular user&#39;s identity.</p>
                                        <p className="space-10"></p>
                                        <p>Access to PII and data by our employees is limited to those persons or agents of E&V that have a specific business purpose for maintaining and processing such PII. These individuals are made aware of their responsibilities to protect the security of that PII and also uphold the principles of confidentiality and integrity. When these individuals enter our Website and access PII, they are required to go through a security process in which such individual must provide two different authentication factors to verify their identity. This process protects such individual&#39;s credentials and the information such individual may access.</p>
                                        <p className="space-10"></p>
                                        <p>The safety and security of information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. Please do not share your password with anyone. Sharing of account passwords is considered an acceptable use violation and may result in loss of access to our Website. Additionally, we urge you to be prudent about posting information in public areas of our Website where the information may be viewed by any user of our Website.</p>
                                        <p className="space-10"></p>
                                        <p>Unfortunately, new vulnerabilities arise in the realm of technology every day. Although we strive to protect your information, circumstances beyond our control may compromise that goal. As with any website, please be conscious of the data you share. If you are not comfortable providing any information, it is your right to withhold it. IN NO EVENT SHALL WE BE LIABLE FOR ANY DAMAGES (WHETHER CONSEQUENTIAL, DIRECT, INCIDENTAL, INDIRECT, PUNITIVE, SPECIAL OR OTHERWISE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, A THIRD PARTY&#39;S UNAUTHORIZED ACCESS TO YOUR INFORMATION, REGARDLESS OF WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, STRICT LIABILITY, TORT OR OTHER THEORIES OF LIABILITY, AND ALSO REGARDLESS OF WHETHER WE ARE GIVEN ACTUAL OR CONSTRUCTIVE NOTICE THAT DAMAGES WERE POSSIBLE, EXCEPT AS PROVIDED UNDER APPLICABLE LAWS.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>INDEMNITY</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>As a condition to accessing or using our Website, you agree to indemnify and hold harmless E&amp;V, its subsidiaries and affiliates and its and their respective directors, managers, officers, employees and agents against any and all liabilities, expenses (including, without limitation, attorney&#39;s fees and court costs) and damages arising out of or otherwise in connection with third party claims resulting from or otherwise in connection with your access to or use of our Website, including, without limitation, any claims alleging facts that, if true, would constitute a breach of the terms and conditions stated in this Policy.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>RETENTION PERIOD</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>We may retain your PII for the period necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required or allowed by law or to otherwise fulfill a legal obligation.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>CHANGES TO OUR PRIVACY POLICY</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>If we decide to change this Policy, we will post any changes we make on this page with a notice that this Policy has been updated on the home page of our Website. Any changes to this Policy will become effective when we post the revised Policy on our Website. The effective date for this Policy is identified at the top of this page. You are responsible for periodically visiting our Website and this Policy to check for any changes. Your use of our Website following these changes means that you accept the revised Policy.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>CHILDREN UNDER THE AGE OF 16</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>Our Website is not intended for children under 16 years of age. No one under 16 years of age may provide any PII to or on our Website. We do not knowingly collect PII from children under 16 years of age. If you are under 16 years of age, do not use or provide any information on our Website or through any of its features, register on our Website, make any purchases through the Website, use any interactive or public comment features on our Website or provide any PII to us, including, without limitation, your name, address, telephone number, email address or username you may use. In the event that we learn that we have collected PII from a child under 16 years of age, we will delete such PII as soon as possible. If you believe we might have any PII from or about a child under 16 years of age, then please contact us using the contact information listed below (see <Link to="#contactinformation"> CONTACT INFORMATION </Link>). Visit the Federal Trade Commission website for more information about the Children&#39;s Online Privacy Protection Act.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>Contact Information</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>If you have any questions, comments or concerns about these Terms and Conditions, then you may contact us in the following ways:</p>
                                        <p className="space-10"></p>
                                        <ul className="list-unstyled">
                                            <li>
                                                <i className="fas fa-map-marker-alt fa-2x"></i>
                                                <p>6441 Inkster Road, Suite 232, Bloomfield Hills, MI 48301. </p>
                                            </li>
                                            <li>
                                                <i className="fas fa-envelope mt-4 fa-2x"></i>
                                                <p>support@campuswearoutfitter.com</p>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </div>

            <CustomFooter />
            </>
        )
    }
            
}

export default PrivacyPolicy;