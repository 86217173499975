import React from "react";import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
  } from "reactstrap";
  import TextField from '@material-ui/core/TextField';
  import TableContainer from "@material-ui/core/TableContainer";
  import Table from '@material-ui/core/Table';
  import TableBody from '@material-ui/core/TableBody';
  import TablePagination from '@material-ui/core/TablePagination';

class CancelOrders extends React.Component{
    state = {
        search_term: '',
        page: 0,
        rowsPerPage: 5
    };

    componentDidMount = () => {
        let {cancelOrders} = this.props
    }
    
    
    changeHandler = event => {
      this.setState({
        search_term : event.target.value
      })
    }
  
    searchingOutputOrder = () => {
      let searchingOutput = this.props.cancelOrders.filter(item =>
          item.name.toLowerCase().includes(this.state.search_term.toLowerCase())
      );
      return searchingOutput;
    }
  
    handleChangePage = (event, newPage) => {
      this.setState({
        page:newPage
      })
    };
  
    handleChangeRowsPerPage = (event) => {
      this.setState({
        rowsPerPage : +event.target.value,
        page: 0
      })
    };

    render() {

        const {page, rowsPerPage} = this.state
        let allCancelOrders = this.searchingOutputOrder();
  
        let cancelFilterOrders = allCancelOrders.length <= rowsPerPage ? allCancelOrders : allCancelOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  
        return (
            <div className="orders-main-sec cancel-orders-table-sec">
                <Row>
                    <Col md="4" className="ml-auto">
                      <div className="orders-search-sec all-orders-search-sec">
                        <TextField 
                          id="all-orders-search" 
                          placeholder ="Search" 
                          fullWidth
                          onChange ={this.changeHandler}
                          value ={this.state.search_term}
                          />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                      <Col md="12">
                          <TableContainer className="orders-table-sec all-orders-table-sec table-responsive">
                              <Table className="orders-table all-orders-table">
                                  <thead>
                                      <tr>
                                          <th style ={{textAlign: 'left'}}>Product</th>
                                          <th>Size</th>
                                          <th>Quantity</th>
                                          <th>Price</th>
                                          <th>Status</th>
                                      </tr>
                                  </thead>
                                  <TableBody>
                                      {cancelFilterOrders.map((item, index) => {
                                          return (
                                          <tr key={index} tabIndex={-1}>
                                              <td style ={{textAlign: 'left'}}>
                                                  <div className="orders-table-first-col">
                                                      <span className="orders-img"><img src={item.src}/></span>
                                                      <div className="orders-list-info">
                                                      <span className="product-name">{item.name}</span>
                                                      <span className="brand-name">{item.brand}</span>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td>
                                                <div className="size">{item.size}</div>
                                                </td>
                                              <td>{item.quantity}</td>
                                              <td>{item.price}</td>
                                              <td>
                                                <div className="cancelled">
                                                    cancelled
                                                </div>
                                              </td>
                                          </tr>
                                          );
                                      })}
                                  </TableBody>
                              </Table>
                          </TableContainer>
                          
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            component="div"
                            count={cancelFilterOrders.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            className= "orders-table-pagination"
                          />
                          
                      </Col>
                  </Row>
            </div>
        );
      }
}

export default CancelOrders