import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import React from 'react';
import Slick from "react-slick";
import { Button, Col, Row } from 'reactstrap';

// import logo1 from '../../../../../assets/img/svg-logos/test/Baseball-05-01.svg'
// import logo2 from '../../../../../assets/img/svg-logos/test/Baseball-8.svg'
// import logo3 from '../../../../../assets/img/svg-logos/test/Baseball-16-01.svg'
// import logo4 from '../../../../../assets/img/svg-logos/test/Soccer-3-01.svg'
// import logo5 from '../../../../../assets/img/svg-logos/test/Soccer-4-01.svg'
// import logo6 from '../../../../../assets/img/svg-logos/test/Soccer-6-01.svg'
// import logo7 from '../../../../../assets/img/svg-logos/test/Soccer-10-01.svg'

import logos from "../../../../../services/logos/index"

import { rgbToHex } from '../../../../../utils'  //util function to convert rbg color to hex color

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import action_middleware from '../../../../../redux/action-creator'
import {
    SET_LOGO_DETAILS
} from '../../../../../redux/actions/actionTypes'




const imgSrc = [
    {
        id: 'lg1',
        src: require('assets/img/lg1.jpg')
    },
    {
        id: 'lg2',
        src: require('assets/img/lg2.jpg')
    },
    {
        id: 'lg3',
        src: require('assets/img/lg3.jpg')
    },
    {
        id: 'lg4',
        src: require('assets/img/lg4.jpg')
    },
    {
        id: 'lg5',
        src: require('assets/img/lg5.jpg')
    },
    {
        id: 'lg6',
        src: require('assets/img/lg2.jpg')
    },
    {
        id: 'lg7',
        src: require('assets/img/lg3.jpg')
    },
    {
        id: 'lg8',
        src: require('assets/img/lg4.jpg')
    },
    {
        id: 'lg9',
        src: require('assets/img/lg5.jpg')
    },
    {
        id: 'lg10',
        src: require('assets/img/lg4.jpg')
    }
]

const svgLogosss = [
    {
        id: 'lg1',
        src: require('assets/img/svg-logos/test/Baseball-05-01.svg')
    },
    {
        id: 'lg2',
        src: require('assets/img/svg-logos/test/Baseball-8.svg')
    },
    {
        id: 'lg3',
        src: require('assets/img/svg-logos/test/Baseball-16-01.svg')
    },
    {
        id: 'lg4',
        src: require('assets/img/svg-logos/test/Soccer-3-01.svg')
    },
    {
        id: 'lg5',
        src: require('assets/img/svg-logos/test/Soccer-4-01.svg')
    },
    {
        id: 'lg6',
        src: require('assets/img/svg-logos/test/Soccer-6-01.svg')
    },
    {
        id: 'lg7',
        src: require('assets/img/svg-logos/test/Soccer-10-01.svg')
    }
]

const PrevButton = props => {
    return (
        <Button
            className="btn-round btn-icon btn-simple slick-prev slick-arrow"
            color="primary"
            aria-label="Previous"
            type="button"
            onClick={props.onClick}
        >
            <i className="tim-icons icon-minimal-left" />
        </Button>
    );
};
// custom next button for the slick component
const NextButton = props => {
    return (
        <Button
            className="btn-round btn-icon btn-simple slick-next slick-arrow"
            color="primary"
            aria-label="Next"
            type="button"
        >
            <i className="tim-icons icon-minimal-right" onClick={props.onClick} />
        </Button>
    );
};

const settings = {
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    className: "center",
    infinite: false,
    slidesToShow: 1,
    speed: 500,
    rows: 2,
    slidesPerRow: 3,
    dots: true,
    adaptiveHeight: true,
    responsive: [
        {
            breakpoint: 1350,
            settings: {
                slidesPerRow: 2,
            }
        }
    ]
};

class DesignSection extends React.Component {
    state = {
        // svgLogos: [logo1, logo2, logo3, logo4, logo5, logo6, logo7],
        svgLogos: [],
        logoCat: "",
        // svgLogos: baseball,
        enableNext: false, // whether to enable/disable next button
    }
    componentDidMount = () => {
        // this.state.svgLogos.map(filename => this.loadSVG(filename, filename.replace(".svg", '')))
        // console.log(logos)
        this.selectLogoCat(Object.keys(logos)[0])
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value })
    }

    /**Load all logos */
    loadSVG = (fileName, id) => {
        // console.log(fileName)
        const svgInlineDiv = document.getElementById(id)
        var SVGFile = fileName
        var loadXML = new XMLHttpRequest;
        function handler() {
            if (loadXML.readyState === 4 && loadXML.status === 200)
                svgInlineDiv.innerHTML = loadXML.responseText
        }
        if (loadXML != null) {
            loadXML.open("GET", SVGFile, true);
            loadXML.onreadystatechange = handler;
            loadXML.send();
        }
    }

    /**Position logo on SKU */
    skuLogoPlacement = () => {
        const sku = document.getElementById('sku-image')
        const logo = document.getElementById("overlay")
        logo.style.top = (sku.offsetHeight * 0.22) + "px"
    }

    /**
     * On selecting the logo to place on SKU and edit 
     * When a new logo is selected, all previous changes are discarded
     * */
    selectLogo = async (divID) => {
        this.skuLogoPlacement()
        this.setState({ enableNext: true })

        const svg = document.getElementById(divID).innerHTML
        document.getElementById('overlay').innerHTML = svg
        document.getElementById('overlay').style.display = 'inline-block'

        //Get color
        const colorContext = [
            [".sku-container [color-primary='fill']",
                ".sku-container [color-primary='stroke']",
                "primaryColor",
                "Primary Color"], //primary color
            [".sku-container [color-secondary='fill']",
                ".sku-container [color-secondary='stroke']",
                "secondaryColor",
                "Secondary Color"], // secondary color
            [".sku-container [color-tertiary='fill']",
                ".sku-container [color-tertiary='stroke']",
                "tertiaryColor",
                "Tertiary Color"] // tertiary color
        ]
        let colors = this.getColor(colorContext)

        //Get text
        let texts = [false, false, false]
        for (var i = 1; i <= 3; i++) {
            try {
                var q = ".sku-container [data-text='" + i + "']"
                //alert(text[0].getBoundingClientRect().width)//get width
                const text = document.querySelectorAll(q)
                texts[i - 1] = text[0].innerHTML
            } catch { }
        }

        //Check if mascot exists and is changeable
        let hasMascot = this.checkMascot()

        this.props.action_middleware(
            SET_LOGO_DETAILS,
            {
                colors,
                texts,
                selected_div: divID,
                showUploadMascot: hasMascot
            }
        )
    }

    /**Get default colors of the currently selected logo */
    getColor = context => {
        let colors = {}
        context.map(([query1, query2, target, title], key) => {
            let obj = {}
            obj.title = title
            try {//Check for fills
                const fill = document.querySelectorAll(query1)
                obj.value = rgbToHex(fill[0].style.fill)
            } catch {
                try {//Check for strokes
                    const stroke = document.querySelectorAll(query2)
                    obj.value = rgbToHex(stroke[0].style.stroke)
                } catch {
                    obj.value = false
                }
            }
            colors[target] = obj
        })
        return colors
    }

    /**Check if mascot exists and is changeable */
    checkMascot = () => {
        try {
            const mascot = document.querySelectorAll("#overlay image")
            return mascot.length > 0
        }
        catch { return false }
    }

    /**Select logo category */
    selectLogoCat = async (cat) => {
        await this.setState({ svgLogos: logos[cat].svgLogos })
        this.state.svgLogos.map(filename => this.loadSVG(filename, filename.replace(".svg", '')))
    }

    render() {

        console.log('logo part rendered')

        return (
            <div className="design-tab-main-section">
                <Row>
                    <Col md="6">
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sm={12} >
                                <FormControl fullWidth className="custom-select-desgin">
                                    <NativeSelect
                                        value=""
                                        inputProps={{
                                            'aria-label': 'Logo Preassigned'
                                        }}
                                    >
                                        {/* <option value="" disabled>Logo Preassigned</option> */}
                                        <option value="full chest">Full chest</option>
                                        <option value="left chest">Left Chest</option>
                                        <option value="sleeve print">Sleeve Print</option>
                                        <option value="embroidery">Embroidery</option>
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Col>
                    <Col md="6" className="ml-auto">
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sm={12} >
                                <FormControl fullWidth className="custom-select-desgin">
                                    <NativeSelect
                                        value=""
                                        inputProps={{
                                            'aria-label': 'Filtering By Price'
                                        }}
                                    >
                                        {/* <option value="" disabled>Filtering By Price</option> */}
                                        <option value="best selling">Best Selling</option>
                                        <option value="regular">Regular</option>
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Col>


                    <Col md="6">
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sm={12} >
                                <FormControl fullWidth className="custom-select-desgin">
                                    <NativeSelect
                                        id="logoCat"
                                        onChange={(e) => {
                                            this.selectLogoCat(e.target.value)
                                            this.handleChange(e)
                                            console.log(this.state)
                                        }}
                                        value={this.state.logoCat}
                                        inputProps={{
                                            'aria-label': 'Logo Preassigned'
                                        }}>
                                        {Object.keys(logos).map(key => {
                                            return (<option
                                                key={key}
                                                value={key}
                                            >{logos[key].name}
                                            </option>)
                                        })}

                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Col>



                </Row>
                {/**Select logo categories */}
                {/* <Row>
                    <Col md="12">
                        {Object.keys(logos).map(key => {
                            return (
                                <Button key={key} onClick={() => {
                                    this.selectLogoCat(key)
                                }}>
                                    {logos[key].name}
                                </Button>
                            )
                        })}
                    </Col>
                </Row> */}
                {/**Select logo categories */}
                <Row>
                    <Col md="12">
                        <div className="title-sec">
                            <h4 className="title">Choose Design</h4>
                        </div>
                        <div className="design-img-sec">
                            <Slick {...settings}>
                                {
                                    this.state.svgLogos.map((item, index) => (
                                        <div
                                            className="single-img"
                                            key={index}
                                            style={{}}
                                            id={item.replace(".svg", '')}
                                            onClick={() => { this.selectLogo(item.replace(".svg", '')) }}
                                        >
                                            <p className="text-dark text-center">
                                                Loading...
                                            </p>
                                            {/* <img src={item.src} alt ="..." className="img-round" /> */}
                                        </div>
                                    ))
                                }
                            </Slick>
                        </div>
                    </Col>
                </Row>
                <Button
                    className="next-design"
                    onClick={(e) => this.props.toggleTabs(e, 'iconTabs', 2)}
                    disabled={!this.state.enableNext}  /* checking if any logo is selected */
                >
                    Next
                    <i className="fas fa-arrow-right"></i>
                </Button>
            </div >
        )
    }
}

const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch),
})
export default connect(null, mapDispatchToProps)(DesignSection)