import classnames from "classnames";
import React, { Component } from 'react';
// reactstrap components
import {
    Button, 
    Card,
    CardBody, 
    CardHeader, 
    Col,
    Nav,
    NavItem,
    NavLink, 
    Row, 
    TabContent,
    TabPane
} from "reactstrap";
import AllOrdersTable from './all-orders-table'
import CancelOrders from './cancel-orders-table'



const data = [
    {
      id: 1,
      src: require("assets/img/jacket.png"),
      name: "Jacket",
      brand: "Abc",
      size: "xl",
      quantity: 1,
      price: 90,
      status: 'pending'
    },
    {
      id: 2,
      src: require("assets/img/jeans.png"),
      name: "Jeans",
      brand: "cdf",
      size: "l",
      quantity: 5,
      price: 90,
      status: 'delivered'
    },
    {
      id: 3,
      src: require("assets/img/jeans.png"),
      name: "Shirt",
      brand: "cdf",
      size: "s",
      quantity: 2,
      price: 130,
      status: 'delivered'
    },
    {
      id: 4,
      src: require("assets/img/shorts.png"),
      name: "Shirt",
      brand: "cdf",
      size: "s",
      quantity: 3,
      price: 100,
      status: 'pending'
    },
    {
      id: 5,
      src: require("assets/img/jacket.png"),
      name: "Jacket",
      brand: "Abc",
      size: "xl",
      quantity: 1,
      price: 90,
      status: 'pending'
    },
    {
      id: 6,
      src: require("assets/img/jeans.png"),
      name: "Shirt",
      brand: "cdf",
      size: "s",
      quantity: 2,
      price: 130,
      status: 'delivered'
    },
    {
      id: 7,
      src: require("assets/img/shorts.png"),
      name: "Shirt",
      brand: "cdf",
      size: "s",
      quantity: 3,
      price: 100,
      status: 'pending'
    },
    {
      id: 8,
      src: require("assets/img/jacket.png"),
      name: "Jacket12",
      brand: "Abc",
      size: "xl",
      quantity: 1,
      price: 90,
      status: 'pending'
    },
    {
      id: 9,
      src: require("assets/img/jacket.png"),
      name: "Jacket345",
      brand: "Abc",
      size: "xl",
      quantity: 1,
      price: 90,
      status: 'pending'
    },
    {
      id: 10,
      src: require("assets/img/jacket.png"),
      name: "Jacket2345",
      brand: "Abc",
      size: "xl",
      quantity: 1,
      price: 90,
      status: 'pending'
    },
    
];

class MyOrders extends Component {
    state = {
        iconTabs: 'all-orders',
        allOrders: [],
        cancelOrders: []
    }
    toggleTabs = (e, stateName, index) => {
        e.preventDefault();
        this.setState({
            [stateName]: index
        });
    }
    handleOrderCancelation = id => {
        let {cancelOrders, allOrders} = this.state
        let cancelledOrder = allOrders.filter(i => i.id === id)
        allOrders = allOrders.filter(i => i.id !== id)
        cancelOrders = cancelOrders.concat(cancelledOrder)
        this.setState({allOrders, cancelOrders})
    }
    componentDidMount() {
        const allOrders = data.map((el) => {
          let o = Object.assign({}, el);
          return o;
        });
        this.setState({ allOrders});
    }
    
    render() {

        const {iconTabs, allOrders, cancelOrders} = this.state

        return (
            <>
            <Row>
                <Col md="12">
                    <div className="all-orders-tab-section">
                        <Card>
                            <CardHeader>
                                <Nav className="nav-tabs-info orders-info-tab" role="tablist" tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                            active: iconTabs === 'all-orders'
                                            })}
                                            onClick={e => this.toggleTabs(e, "iconTabs", 'all-orders')}
                                        >
                                            <span>All orders</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({
                                            active: iconTabs === 'cancel-order'
                                            })}
                                            onClick={e => this.toggleTabs(e, "iconTabs", 'cancel-order')}
                                        >
                                            <span>Cancel Orders</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody>
                                <TabContent
                                    className="orders-info-content"
                                    activeTab={"htabs-" + iconTabs}
                                >
                                    <TabPane tabId="htabs-all-orders">
                                        <AllOrdersTable 
                                          allOrders={allOrders}
                                          handleOrderCancelation={this.handleOrderCancelation}
                                        />
                                    </TabPane>
                                    <TabPane tabId="htabs-cancel-order">
                                        {
                                          iconTabs === 'cancel-order' &&
                                          <CancelOrders
                                            cancelOrders={cancelOrders}
                                          />
                                        }
                                        {/* <h1 style={{color: '#000'}}>Tab two</h1> */}
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
            </>
        )
    }
}

export default MyOrders
