import React from "react";
import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import agent from '../../services/api-endpoint'

class ReturnPolicy extends React.Component {
    
    componentDidMount() {
        document.body.classList.add("page");
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.wrapper.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.remove("page");
    }

    render(){
        const {site_slug} = agent;
        return (
            <>
            <CustomNavbar />

            <div className="wrapper" ref="wrapper">
                <div className ="main">
                    <div className="space-100" />
                    <section className="all-pages return-policy-page section">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="all-pages-header">
                                        <h1><span>Return</span> <span>policy</span></h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>Return Policy</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>Campus Wear Direct is happy to accept the return of most non-personalized, unwashed, unaltered merchandise. However, some logo&#39;d merchandise, electronics, and all custom-made items are not returnable or exchangeable unless there is a manufacturing defect.</p>
                                        <p className="space-10"></p>
                                        <p>Information about which items can be returned is listed in the shopping cart page on our web site, as well as all order e-mail confirmations and shipping packing lists and invoices.Please review your order before completing your purchase as some items on your order may not be returnable, and your order cannot be modified or cancelled once it is placed.</p>
                                        <p className="space-10"></p>
                                        <p>Our return guarantee applies when a returnable product has been used for the purpose intended and under normal conditions. Other restrictions apply to your return on certain items like socks, underwear, and other monogrammed apparel. These types of items cannot be returned or exchanged regardless of the date. These items are listed individually on the website, order email confirmations, and packing lists as non-returnable/exchangeable items. No refund or credit will be given for non-returnable, non-exchangeable merchandise.</p>
                                        <p className="space-10"></p>
                                        <p>Returnable merchandise in its un-washed, un-altered, non-monogrammed non-personalized condition can be returned for full credit if received within 30 days from ship date.Merchandise cannot be returned or exchanged after 31 days from shipment.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>How to Return a Product</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>You must obtain a Return Authorization Number (RA #) prior to returning any merchandise by mail, FedEx or UPS. You may obtain a RA # by utilizing our Returns link after logging into your account. The return form will direct you to indicate which item(s) you are requesting a return / exchange, how to obtain an RA #, and Return Shipping Label.</p>
                                        <p className="space-10"></p>
                                        <p>Once you have obtained your RA #, please write the number on the outside of your package and return to the return address provided. Merchandise will not be accepted for processing without an RA # on the outside of the box.</p>
                                        <p className="space-10"></p>
                                        <p>Please include a copy of your return form with your merchandise. You can print a copy of your form as part of the return authorization process. Please do not include additional merchandise that has not already been indicated as a return request. If you need to return additional merchandise you will need to obtain a new RA #.</p>
                                        <p className="space-10"></p>
                                        <p>If Campus Wear Direct has made a mistake on your order, we will credit you for insured ground return receipt delivery as part of your return credit.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>Returns, Warranties, & Shipping Discrepancies</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <ul className="return-policy-list-content">
                                            <li>ALL warranty work is performed in the US and return shipped to US addresses only.</li>
                                            <li>All shipping discrepaCampus Wear Directncies must be reported within 5 business days of receipt of merchandise. Discrepancies reported after 5 business days will not be allowed.</li>
                                            <li>All returns and warranties require a Return Authorization number (R.A.#), which may be obtained from Campus Wear Direct Returns Department. To ensure proper credit, please clearlymark the R.A. # on the outside of the box with your returned merchandise. The Returns Department will provide a Return Shipping Label for you. All product returned without an R.A. # will be subject to a minimum 15% restock fee.</li>
                                            <li>Campus Wear Direct will repair, replace or give credit on defective products at its sole discretion. As a general rule, We follows manufacturer warranty policies when determining issues regarding repair, replacement, etc.</li>
                                            <li>Returns on fuel, food items or discontinued items will not be accepted.</li>
                                            <li>Returns of items that Campus Wear Direct special orders from the manufacturer will not be allowed. This includes items not in the catalog or unusually large quantities of catalog items.</li>
                                            <li>Campus Wear Direct allows the return of non-defective items for up to 30 days after the invoice date. Any exception to this rule is at Campus Wear Direct&#39;s sole discretion. If Campus Wear Direct, in its sole discretion, allows this type of return, a restocking fee of a minimum of 15% up to a maximum of 75% could be charged-especially if the item cannot be resold (i.e. the item has been used, price stickers are affixed, packaging is damaged, discontinued items, etc.).</li>
                                            <li>Orders returned by a freight delivery carrier as refused, bad address or any other reason without prior notification or authorization will be subject to a minimum 15-75% restock fee at Campus Wear Direct&#39;s discretion.</li>
                                            <li>Warranty or defective products may take 10-15 business days for replacement.</li>
                                            <li>Non-defective returns will receive purchasing credits only. No cash refunds.</li>
                                            <li>Campus Wear Direct reserves the right to review product sold in group sales as potential accelerated wear and tear which may not qualify for normal warranty returns.</li>
                                            <li>DISCLAIMER OF WARRANTIES. CAMPUS WEAR DIRECT EXCLUDES AND DISCLAIMS ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE WITH RESPECT TO ANY GOODS SOLD TO CUSTOMER. THERE ARE NO EXPRESS OR IMPLIED WARRANTIES, WHICH EXTEND BEYOND THE WARRANTIES EXPRESSLY STATED FROM THE MANUFACTURER.</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </div>

            <CustomFooter />
            </>
        )
    }
            
}

export default ReturnPolicy;