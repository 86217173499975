/**Rules */
const rules = {
    /**For registration form */
    "register": {
        'first_name': {
            "length_min": 3,
            "type": /[a-zA-Z]+/
        },
        'last_name': {
            "length_min": 3,
            "type": /[a-zA-Z]+/
        },
        'email': {
            "length_min": 5,
            'type': /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        },
        'password': {
            "length_min": 6
        },
        'password_confirmation': {
            "length_min": 6,
            "match": ["password", "first_name"]
        },
        'store_name': {
            "length_min": 3
        }
    },

    /**For login form */
    "login": {
        'email': {
            "length_min": 5,
            'type': /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        },
        'password': {
            "length_min": 6
        }
    },

    /**Organization Form */
    "organization": {
        'organization_name': {
            "length_min": 3
        },
        'team_name': {
            "length_min": 3
        },
        'website': {
            "length_min": 3,
            "type": /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
        },
        'org_role': {
            "length_min": 3
        },
        'team_type': {
            "length_min": 3
        },
        'team_size': {
            "length_min": 3
        },
        'team_role': {
            "length_min": 3
        }
    }
}

const validatorFunction = {
    "length_min": (form_value, rule_value, form_data) => {
        // console.log('checkLength', form_value, rule_value)
        return (form_value.length >= rule_value) ? [true] : [false, `Needs to be atleast ${rule_value} characters long.`]
    },
    "type": (form_value, rule_value, form_data) => {
        // console.log("type", form_value, rule_value)
        return (rule_value.test(String(form_value).toLowerCase())) ? [true] : [false, 'Value is invalid.']
    },
    "match": (form_value, rule_value, form_data) => {
        // console.log("match", form_value, rule_value)
        let values = []
        let all_matched = true
        for (var i = 0; i < rule_value.length; i++) {
            if (form_value !== form_data[rule_value[i]]) {
                all_matched = false
                values.push(rule_value[i])
            }
        }

        let s = ''
        if (!all_matched) {
            return [false, 'Did not match with ' + values.toString() + '.']
        } else
            return [true]
    }
}

const validate = (form_type, form_data) => {
    const rule = rules[form_type]
    let validation_response = {}
    // console.log(rule)
    // console.log(Object.entries(rule))
    let no_error = true
    for (let [key, value] of Object.entries(rule)) {
        // console.log(key, value)
        let error = false
        let msg = ''
        for (let [rule_key, rule_value] of Object.entries(value)) {
            // console.log(rule_key, rule_value)
            const validation_result = validatorFunction[rule_key](form_data[key], rule_value, form_data)

            // console.log(validation_result[0], validation_result[1])
            if (!validation_result[0]) {
                no_error = false
                error = true
                if (msg === '')
                    msg = msg + validation_result[1]
                else
                    msg = msg + ' ' + validation_result[1]
            }
        }
        if (error) {
            validation_response[key] = {
                error: true,
                msg
            }
        } else {
            validation_response[key] = {
                error: false,
            }
        }
    }

    console.log(validation_response)

    return no_error
}

export default validate