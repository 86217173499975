import React from 'react'

import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import agent from '../../services/api-endpoint'

const Protected = props => {

    const {props_data, user_access} = props
    const {redirect_to} = props.common
    const {role} = props.auth
    const Target = props.component

    console.log(redirect_to)

    switch(user_access)
    {
        case role:
            return <Target {...props_data} />

        case 'guest': {
            let {isLogged} = props.auth
            if(!isLogged)
                return <Target {...props_data} />
            else
                return <Redirect to={redirect_to} />
        }
        
        case 'auth': {
            let {isLogged} = props.auth
            if(isLogged)
                return <Target {...props_data} />
            else
                return <Redirect to={redirect_to} />
        }
        
        default:
            return <Redirect to={redirect_to} />
    }
}

const mapStateToProps = state => {
	return {
        auth: state.auth,
        common: state.common
	}
}
export default connect(mapStateToProps, null)(Protected)