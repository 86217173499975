const PASSWORD = 'AsciiSystem'
const decrypt = (data) => {
    try {
        const new_data = JSON.parse(data)
        console.log(new_data.iv)
        return window.CryptoJSAesJson.decrypt(data, PASSWORD)
    } catch {
        return data
    }
}

export default decrypt