import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import OrganizationDetails from './organization-details-form-sec';
import FundraisingDetails from './fundraising-form-sec';
import Paper from '@material-ui/core/Paper';

function getSteps() {
  return ['Organization Details', 'fundraising'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <OrganizationDetails/>
    case 1:
      return <FundraisingDetails/>
    
    default:
      return 'Unknown step';
  }
}

class VerticalLinearStepper extends React.Component{
    state ={
        activeStep: 0
    }
    steps = getSteps();

    handleNext = () => {
        console.log('hello');
        this.setState({
            activeStep: this.state.activeStep + 1
        })
    };

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1
        })
    };

    handleReset = () => {
        this.setState({
            activeStep: 0
        })
    };
    render(){
        return (
            <div className="multi-steps-form">
                <form>
                    <Stepper activeStep={this.state.activeStep} orientation="vertical">
                        {this.steps.map((label, index) => (
                        <Step key={label} className="single-item">
                            <StepLabel className="step-label">{label}</StepLabel>
                            <StepContent>
                                <div className="organaization-fundraising-section">{getStepContent(index)}</div>
                                <div className="multi-steps-btn-container">
                                <Button
                                disabled={this.state.activeStep === 0}
                                onClick={this.handleBack}
                                className="billing-back-btn"
                                
                                >
                                Back
                                </Button>
                                <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleNext}
                                className="billing-next-btn"
                                >
                                {this.state.activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </div>
                            </StepContent>
                        </Step>
                        ))}
                    </Stepper>
                    {this.state.activeStep === this.steps.length && (
                        <Paper square elevation={0} className="final-steps">
                            <p className="title">All steps completed - you&apos;re finished</p>
                            <div className="final-btn-sec">
                                <Button onClick={this.handleReset} className="billing-back-final-btn">
                                    Reset
                                </Button>
                                <Button  className="billing-next-final-btn">
                                    Submit
                                </Button>
                            </div>
                        </Paper>
                    )}
                </form>
                
            </div>
          );
    }
}

export default VerticalLinearStepper