
export const action_middleware = (type, action_data, data={}) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if(isPromise(action_data)){
                action_data.then(res => {
                    dispatch({
                        type,
                        success: true,
                        payload: res,
                        data
                    })
                    resolve(getState())
                })
            }
            else{
                dispatch({
                    type,
                    payload: action_data
                })
                resolve(getState())
            }
        })
    }
}

function isPromise(v) {
    return v && typeof v.then === 'function';
}

export default action_middleware