import React, {Suspense, lazy} from "react";
import { Container, Row, Button, Col } from "reactstrap";

import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import Slick from "react-slick";

// const Image = lazy(() => import('./image'));



// custom previous button for the slick component
const PrevButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-prev slick-arrow"
      color="primary"
      aria-label="Previous"
      type="button"
      onClick={props.onClick}
    >
      <i className="tim-icons icon-minimal-left" />
    </Button>
  );
};
// custom next button for the slick component
const NextButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-next slick-arrow"
      color="primary"
      aria-label="Next"
      type="button"
    >
      <i className="tim-icons icon-minimal-right" onClick={props.onClick} />
    </Button>
  );
};

let slickSettings = {
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  className: "light slider blog-post",
  dots: false,
  infinite: false,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};



class Home extends React.Component {
  componentDidMount() {
    document.body.classList.add("page");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
  }
  componentWillUnmount() {
    document.body.classList.remove("page");
  }

  render() {
    return (
      <>
        <CustomNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="main">
            <section className="slider-sec">
              <Slick {...slickSettings}>
                <div>
                  <img
                    alt="..."
                    src={require("assets/img/0016349.jpeg")}
                  />
                  {/* <Suspense fallback={<div>Loading...</div>}>
                    <Image src={require("assets/img/0016349.jpeg")}/>
                  </Suspense> */}
                </div>
                <div>
                  <img
                    alt="..."
                    src={require("assets/img/0016348.jpeg")}
                  />
                  {/* <Suspense fallback={<div>Loading...</div>}>
                    <Image src={require("assets/img/0016348.jpeg")}/>
                  </Suspense> */}
                </div>
              </Slick>
            </section>
            <section className="video-section">
              <Container>
                <Row>
                  <Col md="6">
                    <div className="video-image">
                      <img 
                        alt="video"
                        src={require("assets/img/video.png")}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="video-content">
                      <div className="video-data">
                        <p>No Risk, All The Rewards! Camp Outfitters</p>
                      </div>
                      <div className="video-title">
                        <strong>
                          Learn How to Create your Spirit Store in Few Easy
                          Steps!!{" "}
                        </strong>
                      </div>
                      <div className="video-data">
                        <p>
                          Check out our Spirit Store video and see how easy it
                          is to create your own Spirit Store and make Money…
                        </p>
                      </div>
                      <div className="video-button">
                        <Button className="btn-round" color="danger">
                          START SHOWING YOUR SPIRIT!
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="feature-section">
              <div className="feature-upper-section">
                <div className="first-feature-area">
                  <Container>
                    <Row className="justify-content-md-end">
                      <Col md="6">
                        <div className="feature-area">
                          <div className="feature-content-main-title">
                            <strong>WHY CAMP OUTFITTERS?</strong>
                          </div>
                          <div className="first-feature-image">&nbsp;</div>
                          <div className="feature-title">
                            <span className="feature-number">01</span>{" "}
                            <span className="title-text">
                              NO STRINGS ATTACHED
                            </span>
                          </div>
                          <div className="feature-content">
                            Your Spirit Wear Store Campus Wear Direct Is Your
                            Store. You Decide What Products And What Logo
                            Options To Make Available To Your Audience. No
                            Minimums, No Contracts, No Kidding!
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className="second-feature-area">
                  <Container>
                    <Row className="justify-content-md-end">
                      <Col md="6">
                        <div className="feature-area">
                          <div className="feature-title">
                            <span className="feature-number">02</span>{" "}
                            <span className="title-text">
                              ALWAYS ONLINE 24/7
                            </span>
                          </div>
                          <div className="feature-content">
                            At Camp Outfitters Your Store Is Available 24/7 365
                            Days A Year. We Never Close! Your Customers Can Shop
                            From The Comfort Of Their Couch While You’re Earning
                            Money In Your Bathrobe!
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
              <div className="feature-lower-section">
                <div className="third-feature-area">
                  <Container>
                    <Row className="justify-content-md-start">
                      <Col md="6">
                        <div className="feature-area">
                          <div className="second-feature-image">&nbsp;</div>
                          <div className="feature-title">
                            <span className="feature-number">03</span>{" "}
                            <span className="title-text">QUICK TURNAROUND</span>
                          </div>
                          <div className="feature-content">
                            At Camp Outfitters we value time. Our team works
                            24/7 to ensure your order is delivered on time as
                            promised!
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className="fourth-feature-area">
                  <Container>
                    <Row className="justify-content-md-start">
                      <Col md="6">
                        <div className="feature-area">
                          <div className="feature-title">
                            <span className="feature-number">04</span>{" "}
                            <span className="title-text">
                              FREE LOGO OPTIONS
                            </span>
                          </div>
                          <div className="feature-content">
                            Camp Outfitters offers a variety of logo options for
                            just about any activity. Don’t see one you need?
                            Just ask us, we’ll add it to your site or you can
                            upload options yourself. It’s your site, it’s your
                            choice! Click here to{" "}
                            <a href="/contactus" target="_blank">
                              Contact Us
                            </a>
                            .
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className="button-area">
                  <Container>
                    <Row className="justify-content-md-start">
                      <Col md="6">
                        <div className="feature-area">
                          <div className="button-title">
                            <span className="title-text">
                              CREATE YOUR OWN CUSTOM STORE
                            </span>
                          </div>
                          <div className="feature-button">
                            <Button className="btn-round" color="danger">
                              START SHOWING YOUR SPIRIT!
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </section>
            <section className="step-section">
              <Container>
                <Row className="justify-content-md-center align-items-md-center">
                  <div className="step-section-title">
                    <strong>TAKE THESE EASY STEPS AND START SELLING!</strong>
                  </div>
                </Row>
                <Row className="justify-content-md-center align-items-md-center">
                    <Col md="4">
                      <div className="step-1">
                        <div className="step-image">
                          <img
                            src={require("assets/img/step1.png")}
                            alt="Step 1 image"
                          />
                        </div>
                        <div className="step-title">Select Your Gear</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="step-2">
                        <div className="step-image">
                          <img
                            src={require("assets/img/step2.png")}
                            alt="Step 2 image"
                          />
                        </div>
                        <div className="step-title">
                          Upload your Logo or Choose from our thousands of Logo
                          options
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="step-3">
                        <div className="step-image">
                          <img
                            src={require("assets/img/step3.png")}
                            alt="step 3 iamge"
                          />
                        </div>
                        <div className="step-title">
                          You’re Done! Let’s Start Selling
                        </div>
                      </div>
                    </Col>
                </Row>
                <Row className="justify-content-md-center align-items-md-center">
                  <div className="step-text">
                    Build your custom spirit wear Today. Your products, Your
                    designs, Your store. Simply share your store with your
                    students, Faculty, Parents, Alumni or whomever you like! We’ll
                    take care of everything else!
                  </div>
                  <div className="step-button">
                    <Button className="btn-round">
                      OH YEAH, AND START EARNING SOME FUNDRAISING $$ ALONG THE
                      WAY!
                    </Button>
                  </div>
                </Row>
              </Container>
            </section>
            <section className="create-print-section">
                <div className="create-print-banner">
                  <img 
                    src={require("assets/img/summer-camp-adv-banner.jpg")} 
                    alt="Create print banner image" />
                </div>
                <div className="print-1">
                  <div className="print-image">
                    <img 
                      src={require("assets/img/fundraising.jpg")} 
                      alt="fundraising"  />
                    <div className="print-number"><strong>01</strong></div>
                  </div>
                  <div className="print-content">
                    <div className="print-title">
                      <strong>START RAISING MONEY FOR A CAUSE! EARN $$ ON EVERY SALE FROM YOUR STORE. IT’S YOUR MONEY, YOU DECIDE WHERE TO SPEND IT!</strong>
                    </div>
                    <div className="print-text">
                      <p>We make Fundraising a FUN-RAISING! Our amazing platform allows you to, you can determine how much or how little you want to raise towards your fundraising goal.</p>
                    </div>
                    <div className="print-button">
                      <Button className="btn-round" color="danger">
                        BUILD YOUR STORE TODAY!
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="print-2">
                  <div className="print-content">
                    <div className="print-title"><strong>CONTROL &amp; BUILD YOUR BRAND</strong></div>
                    <div className="print-text">
                      <p>Take control of your organization’s brand. You decide how best to promote your brand to your audience!</p>
                      <p>Your designs, Your Products, Your store.</p>
                      <p>You’re In complete control.</p>
                    </div>
                    <div className="print-button">
                      <Button className="btn-round">START SHOWING YOUR SPIRIT!</Button>
                    </div>
                  </div>
                  <div className="print-image">
                     <img 
                       src={require("assets/img/print2.png")} 
                       alt="BRAND CONSISTENCY" />
                     <div className="print-number"><strong>02</strong></div>
                  </div>
               </div>
               <div className="print-3">
                 <div className="print-image">
                   <img 
                     src={require("assets/img/print3.png")}  
                     alt="ADMIN DASHBOARD" 
                    />
                   <div className="print-number"><strong>03</strong></div>
                 </div>
                 <div className="print-content">
                   <div className="print-title"><strong>CHECK YOUR STORE PERFORMANCE THROUGH INTERACTIVE YOUR DASHBOARD</strong></div>
                   <div className="print-text">
                     <p>Our interactive Admin Dashboard allows you to keep track of your sales. It informs you which items are popular and who is buying what so you can improve your sales. Your Dashboard keeps track of the money you earned through your sales or fundraising efforts.</p>
                   </div>
                   <div className="print-button">
                     <Button className="btn-round">BUILD YOUR STORE TODAY!</Button>
                    </div>
                 </div>
               </div>
            </section>
            <section className="content-section">
              <div className="content-1">
                <div className="content-title"><strong>FUNDRAISING YEAR-ROUND</strong></div>
                <div className="content-data">
                  <p>Camp Outfitters is the best campus apparel solution offering year-round fundraising. This means your store never closes. Your customer can shop 24/7-365 and you can fundraise year-round.</p>
                </div>
              </div>
              <div className="content-2">
                <div className="content-title"><strong>WHY CHOOSE US ?</strong></div>
                <div className="content-data">
                  <p>Camp Outfitters stock the most diverse range of Campus Gears on the market. We, offering the highest-quality products and provide the best delivery turnaround in the business. We’ve been at it for over 20 Years!</p>
                </div>
              </div>
              <div className="content-3">
                <div className="content-title"><strong>WHAT ELSE WE OFFER?</strong></div>
                <div className="content-data">
                  <p>Besides helping you setup your Spirit Store we provide an array of Branding and Promotional Product Options. We can create logos and assist with any type of fundraising initiative. Just ask, we’re here to help!</p>
                </div>
              </div>
            </section>
            <section className="secure-section">
              <Container>
                <Row className="justify-content-md-center align-items-md-center">
                  <Col md="3">
                    <div className="secure-image1">
                      <img 
                        src={require("assets/img/Safe-&-secure.png")} 
                        alt="Safe & secure image" />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="secure-image2">
                      <img 
                        src={require("assets/img/guarantee.png")} 
                        alt="Guarantee image" />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="secure-image3">
                      <img 
                        src={require("assets/img/free-shipping.png")} 
                        alt="Free shipping image" 
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="secure-image4">
                      <img 
                        src={require("assets/img/verisign.png")} 
                        alt="Verisign image" 
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
        <CustomFooter />
      </>
    );
  }
}

export default Home;
