import React, {useState} from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Alert,
    Container,
    Col,
    NavLink,
    Row,
    FormGroup,
    Label,
    Input,
    FormText
} from "reactstrap";

//material form added 
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';

import Notification from '../../components/Custom/Notification'
import action_middleware from '../../redux/action-creator'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    SEND_EMAIL_FOR_RESET_PASSWORD,
    CLEAR_NOTIFICATION_MSG
} from '../../redux/actions/actionTypes'
import agent from '../../services/api-endpoint'




class CustomerForgetPassword extends React.Component{
    state = {
        email: '',
        loading: false,
        notification: null
    }
    handleForm = () => {
        this.props.handleFormChange('showForgetPass', !this.props.showForgetPass)
    }
    handleChange = e => {
        const {name, value} = e.target
        this.setState({
            [name] : value
        })
    }
    handleSubmit = e => {
        e.preventDefault()
        const {email} = this.state
        if(email){
            this.setState({loading: true, notification: null}, () => {
                this.props.action_middleware(SEND_EMAIL_FOR_RESET_PASSWORD, agent.Auth.send_email_for_reset_password({email}))
                .then(() => {
                    this.setState({ 
                        loading: false,
                        notification: this.props.notification,
                        email: ''
                    })
                })
            })
        }
    }
    onDismiss = () => this.setState({notification: null})
    
    render(){
        const {notification} = this.state
        return(
            <Card className="card-forget-pass auth-card animated fadeIn slow">
                <Form className="form" onSubmit={this.handleSubmit}>
                    <CardHeader className="text-center auth-card-header">
                        <CardTitle tag="h4">Forgot Your Password</CardTitle>
                    </CardHeader>
                    
                    {
                        (notification && 'reset_pass' in notification) && 
                        <Notification delay="5000" onExpire={this.onDismiss}>
                            <div className="alert-section">
                                <Alert
                                    color={notification.reset_pass.status}
                                    isOpen={notification.reset_pass && true}
                                    toggle={this.onDismiss}
                                    className ="fadeIn slow"
                                >
                                    <strong>{notification.reset_pass.msg}</strong>
                                </Alert>
                            </div>
                        </Notification>
                    }

                    <CardBody className="auth-card-body">
                        <p className="sub-title">Please enter your email address below to receive a password reset link.</p>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sm={1}>
                                <Icon className="fas fa-envelope-open" />
                            </Grid>
                            <Grid item sm={11}>
                                <TextField
                                    id="input-with-icon-grid"
                                    label="Email"
                                    placeholder="Enter E-mail"
                                    type="email"
                                    fullWidth
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Grid>
                    </CardBody>
                    <CardFooter className="text-center auth-card-footer">
                        <Button
                            block
                            className="btn-round auth-btn"
                            color="primary"
                            // onClick={this.handleSubmit}
                            size="lg"
                        >
                            {this.state.loading ? 'Submitting...' : 'Submit'}
                        </Button>
                        <NavLink className="go-back" onClick={this.handleForm}>
                            Go Back
                        </NavLink>
                    </CardFooter>
                    
                </Form>
            </Card>
        )
    }
}
const mapStateToProps = state => {
	return {
        notification: state.notification,
	}
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForgetPassword)