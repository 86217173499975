import React from 'react';
import {
    Row,
    Col,
    Button,
    Alert
  } from "reactstrap";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux'
import {
    ADD_SHIPPING_ADDRESS
} from '../../../redux/actions/actionTypes'
import agent from '../../../services/api-endpoint'
import action_middleware from '../../../redux/action-creator'
import Notification from '../../../components/Custom/Notification'


class CustomerAddNewAddress extends React.Component{
    state = {
        shippingAddressList: [
            {
                city: '',
                state: '',
                country: '',
                zip: '',
                address: ''
            }
        ],
        notification: null,
        isLoading: false
    }

    handleAddAddress = e => {
        this.setState({
            shippingAddressList: [
                ...this.state.shippingAddressList,
                {
                    city: '',
                    state: '',
                    country: '',
                    zip: '',
                    address: ''
                }
            ]    
        })
    }
    handleRemoveAddress = (index) => {
        const address_values = [...this.state.shippingAddressList];
        address_values.splice(index, 1);
        this.setState({
            shippingAddressList: address_values
        })
    }
    handleChange = (e, index) => {
        let {name, value} = e.target
        let addressList = [...this.state.shippingAddressList]
        addressList[index][name] = value
        this.setState({
            shippingAddressList: addressList
        })
    }
    addShippingAddress = () => {
        this.setState({isLoading: true})
        const { token } = this.props
        const { shippingAddressList } = this.state

        // checking if all input has valid value
        let validation_arr = shippingAddressList.map((item, i) => {
            return Object.keys(shippingAddressList[i]).every(key => shippingAddressList[i][key] !== '')
        })
        // checking if all input has valid value

        if(!validation_arr.includes(false)){
            this.props.action_middleware(
                ADD_SHIPPING_ADDRESS, 
                agent.Profile.addShippingAddress({ data: shippingAddressList }, token)
            ).then(store_state => {
                this.setState({
                    isLoading: false
                })
                this.props.toggleView('allAddress')  // back to addesses grid view
            })
        }else{
            this.setState({
                notification: {
                    'shipping_address': {
                        type: 'shipping_address',
                        status: 'danger',
                        msg: 'Please fill up the empty fields to continue'
                    }
                },
                isLoading: false
            })
        }
    }
    onDismiss = () => this.setState({ notification: null })

    render(){
        
        const {toggleView} = this.props
        let { shippingAddressList, notification } = this.state

        return (
            <div className="customer-shipping-section shipping-common-form-sec col-sm-12">
                <Row>
                    <div className="shipping-address-lists-sec">
                        <div className="address-list-sec">
                            {
                                (notification && 'shipping_address' in notification) &&
                                <Col md="12">
                                    <Notification delay="5000" onExpire={this.onDismiss}>
                                        <div className="alert-section">
                                            <Alert
                                                color={notification.shipping_address.status}
                                                isOpen={notification.shipping_address && true}
                                                toggle={this.onDismiss}
                                                className="fadeIn slow"
                                            >
                                                <strong>{notification.shipping_address.msg}</strong>
                                            </Alert>
                                        </div>
                                    </Notification>
                                </Col>
                            }
                            <Col md="12">
                                <h3 className="title text-center">
                                    <i className="fa fa-address-card" aria-hidden="true"></i>
                                    Add New Shipping Address
                                </h3>
                            </Col>

                            <Col md="6" style={{paddingLeft: "0"}}>
                                <Button 
                                    className="add-new-customer-common-btn add-new-customer-back-btn"
                                    onClick = {()=>toggleView('allAddress') }
                                    >
                                <i className="fas fa-arrow-left"></i>
                                Back
                                </Button>
                            </Col>
                            
                            <Col md="6" style={{paddingRight: "0"}}>
                                <Button 
                                    className="add-new-customer-common-btn add-address-btn ml-auto" 
                                    onClick={() => this.handleAddAddress()}
                                >
                                    Add more address
                                    <i className="tim-icons icon-simple-add"></i>
                                </Button>
                            </Col>
                        </div>
                        { 
                            shippingAddressList.map((address, index) => (
                                <fieldset key={index} className="shipping-address-section">
                                    <legend>Address <span>{index+1}</span></legend>
                                    {
                                        shippingAddressList.length > 1 &&
                                        <IconButton 
                                            className="address-remove-btn" 
                                            onClick={() => this.handleRemoveAddress(index)} 
                                            // disabled={shippingAddressList.length === 1 ? true : false}
                                        > <CancelIcon></CancelIcon>
                                        </IconButton>
                                    }
                                    <div className="selected-address">
                                    <label className="selected-address-label">
                                        <input type="radio" name="radio" className="selected-adress-radio"/>
                                        <span>Selected</span>
                                    </label>
                                    </div>
                                    <Col md="6" className="edit-customer-address-field">
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item sm={12}>
                                                <TextField
                                                    id="edit-customer_street"
                                                    label="Street"
                                                    placeholder="Street"
                                                    type="text"
                                                    fullWidth
                                                    name="address"
                                                    value={address.address}
                                                    onChange={e => this.handleChange(e, index)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Col>
                                    <Col md="6" className="edit-customer-address-field">
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item sm={12}>
                                                <TextField
                                                    id="edit-customer_city"
                                                    label="City"
                                                    placeholder="City"
                                                    type="text"
                                                    fullWidth
                                                    name="city"
                                                    value={address.city}
                                                    onChange={e => this.handleChange(e, index)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Col>
                                    <Col md="6" className="edit-customer-address-field">
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item sm={12}>
                                                <TextField
                                                    id="edit-customer_state"
                                                    label="State"
                                                    placeholder="State"
                                                    type="text"
                                                    fullWidth
                                                    name="state"
                                                    value={address.state}
                                                    onChange={e => this.handleChange(e, index)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Col>
                                    <Col md="6" className="edit-customer-address-field">
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item sm={12}>
                                                <TextField
                                                    id="edit-customer_zip"
                                                    label="ZIP"
                                                    placeholder="ZIP"
                                                    type="text"
                                                    fullWidth
                                                    name="zip"
                                                    value={address.zip}
                                                    onChange={e => this.handleChange(e, index)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Col>
                                    <Col md="6" className="edit-customer-address-field">
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item sm={12}>
                                                <TextField
                                                    id="edit-customer_country"
                                                    label="Country"
                                                    placeholder="Country"
                                                    type="text"
                                                    fullWidth
                                                    name="country"
                                                    value={address.country}
                                                    onChange={e => this.handleChange(e, index)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Col>
                                </fieldset>
                            ))  
                        }
                        <div className="btn-sec d-flex align-items-center justify-content-center">
                            <Button className="customer-add-addr-btn ml-auto" onClick={this.addShippingAddress}>
                            {
                                this.state.isLoading ?
                                'processing...' :
                                this.state.shippingAddressList.length > 1 
                                ? "Save Addresses" 
                                : "Save Address"
                            } 
                            </Button>
                        </div>
                    </div>
                </Row>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.auth.token
    }
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddNewAddress)