import React from 'react';
import {
    Row,
    Col,
  } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const FundraisingDetails = () =>{
    return(
        <div className="fundraising-details-section">
            <Row>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <FormControl required fullWidth>
                                <InputLabel id="fundraise">Fundraise</InputLabel>
                                <Select
                                labelId="fundraise"
                                id="fundraise-select"
                                
                                //value={age}
                                //onChange={handleChange}
                                //className={classes.selectEmpty}
                                >
                                <MenuItem value="">
                                    <em>Select Fundraise</em>
                                </MenuItem>
                                <MenuItem>person 1</MenuItem>
                                <MenuItem>person 2</MenuItem>
                                <MenuItem>person 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="person_name"
                                label="Person Name"
                                placeholder="Person Name"
                                type="text"
                                fullWidth
                                name="person_name"
                            />
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="fundraise_email"
                                label="Email"
                                placeholder="Email"
                                type="email"
                                fullWidth
                                name="fundraise_email"
                            />
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="contact_number"
                                label="Contact Number"
                                placeholder="Contact Number"
                                type="text"
                                fullWidth
                                name="contact_number"
                            />
                        </Grid>
                    </Grid>
                </Col>
            </Row>
        </div>
    )
}
export default FundraisingDetails;