import React from 'react';
import CustomerAddNewAddress from './new-shipping-address';
import AllShippingAddress from './all-shipping-address';
import Zoom from '@material-ui/core/Zoom';
import CustomerEditAddress from './edit-shipping-address';
// import agent from '../../../services/api-endpoint'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import {
//     LOGIN
// } from '../../../redux/actions/actionTypes'
import action_middleware from '../../../redux/action-creator'



class ShippingAddressModule extends React.Component{
  
    state = {
        newAddress: false,
        editAddress: false,
        allAddress: true
    }

    toggleView = view => {
        let {newAddress, allAddress, editAddress} = this.state
        let obj = {newAddress, allAddress, editAddress}
        for (var key in obj) {
            if(key === view) obj[key] = true
            else obj[key] = false
        }
        this.setState({ 
            allAddress: obj.allAddress,
            editAddress: obj.editAddress,
            newAddress: obj.newAddress
        })
    }

    render(){
        const {newAddress, editAddress, allAddress} = this.state
        
        return (
            <section className="customer-shipping-address-section">
                {
                    (!newAddress && !editAddress && allAddress)
                    ? 
                    <AllShippingAddress 
                        toggleView={this.toggleView}
                    />
                    : 
                    (newAddress && !editAddress && !allAddress) 
                    ?
                    <Zoom in={newAddress} timeout={1000}>
                        <div className="custom-address-new-main-section d-flex" style={{width: '100%'}}>
                            <CustomerAddNewAddress 
                                toggleView={this.toggleView}
                            />
                        </div>
                    </Zoom>
                    : 
                    (editAddress && !newAddress && !allAddress) &&
                    <Zoom in={editAddress} timeout={1000}>
                        <div className="custom-edit-address-new-main-section d-flex" style={{width: '100%'}}>
                            <CustomerEditAddress  
                                toggleView={this.toggleView}
                            />
                        </div>
                    </Zoom>
                }
            </section>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.auth.user
    }
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressModule)