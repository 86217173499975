import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Alert,
    Row,
    Col
} from "reactstrap";

//material form added 
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Notification from '../../components/Custom/Notification'

import { bindActionCreators } from 'redux'
import action_middleware from '../../redux/action-creator'
import { connect } from 'react-redux'
import agent from '../../services/api-endpoint'
import {
    CUSTOMER_REGISTER,
    REG_OTP_REQUEST
} from '../../redux/actions/actionTypes'


const initialFormData = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone: '',
    password_confirmation: '',
    zip: '',
    city: '',
    state: '',
    country: '',
    address:'',
    checkedTerms: false,
    otp: ''
}

class CustomerRegistration extends React.Component {
    constructor(){
        super()
        this.state = {
            loading: false,
            notification: null,
            data: initialFormData
        }
    }

    handleChange = e => {
        const { name, value } = e.target
        this.setState({
            data: {...this.state.data, [name]: value}
        })
    }
    handleRegister = e => {
        e.preventDefault();
        const { data, showOtpForm } = this.state
        
        if(showOtpForm){
            this.setState({ loading: true }, () => {
                this.props.action_middleware(CUSTOMER_REGISTER, agent.Auth.verify_otp({ email: data.email, OTP: data.otp }))
                .then(store_state => {
                    this.setState({
                        loading: false,
                        notification: store_state.notification,
                        showOtpForm: false,
                        data: initialFormData
                    })
                })
            })
        }
        else
        {
            const { first_name, last_name, email, password, password_confirmation, phone, zip, city, state, country, address } = this.state.data
            if(first_name && last_name && email && password && password_confirmation && phone && zip && city && state && country && address)
            {
                this.setState({
                    loading: true,
                    notification: null
                }, () => {
                    let user_data = {first_name, last_name, email, password, password_confirmation, phone, zip, city, state, country, address}
                    this.props.action_middleware(REG_OTP_REQUEST, agent.Auth.registration(user_data))
                    .then(store_state => {
                        this.setState({ 
                            // data: initialFormData,
                            loading: false,
                            notification: this.props.notification,
                            showOtpForm: store_state.temp.sent_otp
                        })
                    })
                })
            }
        }
    }
    onDismiss = () => this.setState({ notification: null })

    render() {
        const { notification, showOtpForm, data } = this.state
        return (
            <Card className="card-registration auth-card animated fadeIn slow">
                <Form className="form" id="abc" onSubmit={this.handleRegister} ref={this.registerForm}>
                    <CardHeader className="text-center auth-card-header">
                        <CardTitle tag="h4">Customer Register</CardTitle>
                        <p className="sub-title">Creating an account has many benefits: check out faster, keep more than one address, track orders and more.</p>
                        <div className="signup-social-section text-center">
                            <p>with your social network</p>
                        </div>
                        <div className="signup-social-account text-center">
                            <Button className="btn-icon btn-round" color="google">
                                <i className="fab fa-google"></i>
                            </Button>
                            <Button className="btn-icon btn-round" color="facebook">
                                <i className="fab fa-facebook-f" />
                            </Button>
                            <Button className="btn-icon btn-round" color="twitter">
                                <i className="fab fa-twitter" />
                            </Button>
                            <Button className="btn-icon btn-round" color="linkedin">
                                <i className="fab fa-linkedin" />
                            </Button>
                            <Button className="btn-icon btn-round apple">
                                <i className="fab fa-apple"></i>
                            </Button>
                        </div>
                        <h3 className="hr-line">OR</h3>
                    </CardHeader>
                    <CardBody className="auth-card-body">
                        {
                            (notification && 'cus_register' in notification) &&
                            <Notification delay="5000" onExpire={this.onDismiss}>
                                <div className="alert-section">
                                    <Alert
                                        color={notification.cus_register.status}
                                        isOpen={notification.cus_register && true}
                                        toggle={this.onDismiss}
                                        className="fadeIn slow"
                                    >
                                        <strong>{notification.cus_register.msg}</strong>
                                    </Alert>
                                </div>
                            </Notification>
                        }
                        {
                            showOtpForm ?
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={1}>
                                    <Icon className="fas fa-user-circle" />
                                </Grid>
                                <Grid item sm={11}>
                                    <TextField
                                        required
                                        id="input-with-icon-grid"
                                        label="Verify OTP"
                                        placeholder="Enter OTP number"
                                        type="text"
                                        name="otp"
                                        fullWidth
                                        value={data.otp}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid> :
                            <>
                            <Row>
                                <Col md="6">
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item sm={1}>
                                            <Icon className="fas fa-user" />
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextField
                                                label="First Name"
                                                placeholder="Enter First Name"
                                                type="text"
                                                fullWidth
                                                name="first_name"
                                                // value={this.state.first_name}
                                                value={data.first_name}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Col>
                                <Col md="6">
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item sm={1}>
                                            <Icon className="fas fa-user" />
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextField
                                                label="Last Name"
                                                placeholder="Enter Last Name"
                                                type="text"
                                                fullWidth
                                                name="last_name"
                                                value={data.last_name}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Col>
                                <Col md="6">
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item sm={1}>
                                            <Icon className="fas fa-envelope-open" />
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextField
                                                label="Email"
                                                placeholder="Enter email address"
                                                type="email"
                                                fullWidth
                                                name="email"
                                                value={data.email}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Col>
                                <Col md="6">
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item sm={1}>
                                            <Icon className="fas fa-store" />
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextField
                                                label="Phone No"
                                                placeholder="Enter Phone No."
                                                type="text"
                                                fullWidth
                                                name="phone"
                                                value={data.phone}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Col>
                                <Col md="6">
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item sm={1}>
                                            <Icon className="fas fa-key" />
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextField
                                                label="Password"
                                                placeholder="write down password"
                                                type="password"
                                                fullWidth
                                                name="password"
                                                value={data.password}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Col>
                                <Col md="6">
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item sm={1}>
                                            <Icon className="fas fa-key" />
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextField
                                                label="Confirm Password"
                                                placeholder="re-enter password"
                                                type="password"
                                                fullWidth
                                                name="password_confirmation"
                                                value={data.password_confirmation}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Col>
                                <Col md="6">
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item sm={1}>
                                            <Icon className="fas fa-key" />
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextField
                                                label="Zip Code"
                                                placeholder="Zip Code"
                                                type="text"
                                                fullWidth
                                                name="zip"
                                                value={data.zip}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Col>   
                                <Col md="6" >
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item sm={1}>
                                            <Icon className="fas fa-key" />
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextField
                                                label="City"
                                                placeholder="City"
                                                type="text"
                                                fullWidth
                                                name="city"
                                                value={data.city}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Col>
                                <Col md="12" className="cus_address_sec">
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item sm={1}>
                                            <Icon className="fas fa-key" />
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextField
                                                label="Address"
                                                placeholder="Address"
                                                type="text"
                                                fullWidth
                                                name="address"
                                                value={data.address}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Col>
                                <Col md="6">
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item sm={1}>
                                            <Icon className="fas fa-key" />
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextField
                                                label="State"
                                                placeholder="State"
                                                type="text"
                                                fullWidth
                                                name="state"
                                                value={data.state}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Col>
                                <Col md="6">
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item sm={1}>
                                            <Icon className="fas fa-key" />
                                        </Grid>
                                        <Grid item sm={11}>
                                            <TextField
                                                label="Country"
                                                placeholder="Country"
                                                type="text"
                                                fullWidth
                                                name="country"
                                                value={data.country}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Col>
                                <Col md ="12">
                                    <div className="material_checkbox_term">
                                        <input 
                                            id="6" 
                                            type="checkbox" 
                                            className="material_checkbox" 
                                            checked ={data.checkedTerms}
                                            onChange ={e => {
                                                let {name, checked} = e.target
                                                this.handleChange({
                                                    target: {
                                                        name,
                                                        value: checked
                                                    }
                                                })
                                            }}
                                            name ="checkedTerms"
                                            />
                                        <label htmlFor="6">I agree to the terms & conditions</label>
                                    </div>
                                </Col>
                            </Row>
                            </>
                        }
                    </CardBody>
                    <CardFooter className="text-center auth-card-footer" style={{ padding: '0 15px 15px', }}>
                        <Button
                            block
                            className="btn-round auth-btn"
                            color="primary"
                            onClick={this.handleRegister}
                            size="lg"
                        >
                            {
                                !showOtpForm ?
                                this.state.loading ? 'Processing...' : 'Register' :
                                this.state.loading ? 'Verifying...' : 'Verify OTP'
                            }
                        </Button>
                    </CardFooter>

                </Form>
            </Card>
        )
    }
}

const mapStateToProps = state => {
    return {
        notification: state.notification,
    }
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerRegistration)