import React, { Component } from 'react'
import {
    Button,
    Row,
    Col,
} from "reactstrap";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Form from "reactstrap/lib/Form";
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';


/**Import validation */
import validate from "../../../../services/validation"

export class OrganizationDetailsForm extends Component {

    state = {
        'organization_name': '',
        'team_name': '',
        'website': '',
        'org_role': '',
        'team_type': '',
        'team_size': '',
        'team_role': ''
    }

    handleChange = e => {
        let { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    handleSubmit = () => {
        const { organization_name,
            team_name,
            website,
            org_role,
            team_type,
            team_size,
            team_role } = this.state

        const form_values = {
            organization_name,
            team_name,
            website,
            org_role,
            team_type,
            team_size,
            team_role
        }
        const no_error = validate("organization", form_values)
        console.log(no_error)
    }

    render() {
        return (
            <Form className="organization_info_form" onSubmit={(e) => {
                e.preventDefault()
                this.props.toggleCard()
                this.handleSubmit()
            }}>
                <Row>
                    <Col md="6">
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sm={12}>
                                <TextField
                                    id="organization_name"
                                    label="Organization Name"
                                    placeholder="Organization Name"
                                    type="text"
                                    fullWidth
                                    name="organization_name"
                                    onChange={this.handleChange}
                                    value={this.state.organization_name}
                                />
                            </Grid>
                        </Grid>
                    </Col>
                    <Col md="6">
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sm={12}>
                                <TextField
                                    id="team_name"
                                    label="Team Name"
                                    placeholder="Team Name"
                                    type="text"
                                    fullWidth
                                    name="team_name"
                                    onChange={this.handleChange}
                                    value={this.state.team_name}
                                />
                            </Grid>
                        </Grid>
                    </Col>

                    <Col md="6">
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sm={12}>
                                <TextField
                                    id="website"
                                    label="Website"
                                    placeholder="Website"
                                    type="text"
                                    fullWidth
                                    name="website"
                                    onChange={this.handleChange}
                                    value={this.state.website}
                                />
                            </Grid>
                        </Grid>
                    </Col>

                    <Col md="6">
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sm={12} >
                                <FormControl fullWidth className="custom-select-desgin">
                                    <NativeSelect
                                        inputProps={{
                                            'aria-label': 'Select Sports'
                                        }}
                                        name='org_role'
                                        onChange={this.handleChange}
                                        value={this.state.org_role}
                                    >
                                        <option value="" disabled>Select your Role</option>
                                        <option value="baseball">Baseball</option>
                                        <option value="basketBall">BasketBall</option>
                                        <option value="Soccer">Soccer</option>
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Col>

                    <Col md="6">
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sm={12}>
                                <FormControl fullWidth className="custom-select-desgin">
                                    <NativeSelect
                                        inputProps={{
                                            'aria-label': 'Team Type'
                                        }}
                                        name='team_type'
                                        onChange={this.handleChange}
                                        value={this.state.team_type}
                                    >
                                        <option value="" disabled>Select team type</option>
                                        <option value="youth">Youth</option>
                                        <option value="high school">High School</option>
                                        <option value="prep school">Prep School</option>
                                        <option value="college">College</option>
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Col>

                    <Col md="6">
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sm={12}>
                                <FormControl fullWidth className="custom-select-desgin">
                                    <NativeSelect
                                        inputProps={{
                                            'aria-label': 'Team / Organization Size'
                                        }}
                                        name='team_size'
                                        onChange={this.handleChange}
                                        value={this.state.team_size}
                                    >
                                        <option value="" disabled>Select team size</option>
                                        <option value="1 to 50">1 - 50</option>
                                        <option value="51 to 100">51 - 100</option>
                                        <option value="101 to 200">101 - 200</option>
                                        <option value="201 to 500">201 - 500</option>
                                        <option value="500+">500+ </option>
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Col>

                    <Col md="6">
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item sm={12}>
                                <FormControl fullWidth className="custom-select-desgin">
                                    <NativeSelect
                                        inputProps={{ 'aria-label': 'Your Role' }}
                                        name='team_role'
                                        onChange={this.handleChange}
                                        value={this.state.team_role}
                                    >
                                        <option value="" disabled>Select your Role</option>
                                        <option value="athletic director">Athletic Director</option>
                                        <option value="coach">Coach</option>
                                        <option value="coach">Parents</option>
                                    </NativeSelect>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Col>
                    <Col md="12">
                        <Button className="btn-org-save" type="submit">
                            Save Store details
                        </Button>
                    </Col>

                </Row>
            </Form>
        )
    }
}

export default OrganizationDetailsForm
