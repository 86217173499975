import React from 'react';
import {
    Row,
    Col,
    Button,
  } from "reactstrap";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux'
import {
    UPDATE_SHIPPING_ADDRESS,
    CLEAR_EDIT_SHIPPING_ADDRESS
} from '../../../redux/actions/actionTypes'
import action_middleware from '../../../redux/action-creator'
import { bindActionCreators } from 'redux'
import agent from '../../../services/api-endpoint'




class CustomerEditAddress extends React.Component{
    state = {
        address: null,
        isLoading: false
    }
    componentDidMount = () => {
        const {address} = this.props
        // delete address['defaultSelect']; 
        this.setState({ address })
    }
    componentWillUnmount = () => this.props.action_middleware(CLEAR_EDIT_SHIPPING_ADDRESS) // removing edit address from store state
    handleChange = (e, index) => {
        let {name, value} = e.target,
            {address} = this.state
            
        this.setState({
            address: {...address, [name]: value}
        })
    }
    updateAddress = e => {
        e.preventDefault()
        this.setState({isLoading: true})
        let obj = {
            data: [
                this.state.address
            ]
        }
        this.props.action_middleware(UPDATE_SHIPPING_ADDRESS, agent.Profile.updateShippingAddress(obj, this.props.token))
        .then(store_state => {
            this.setState({isLoading: false})
            this.props.toggleView('allAddress')
        })
    }

    render(){
        const {toggleView} = this.props
        const {address} = this.state

        return (
            <div className="customer-shipping-section shipping-common-form-sec col-sm-12">
                <Row>
                    <div className="shipping-address-lists-sec">
                        <div className="address-list-sec">
                            <Col md="12" style={{paddingRight: "0"}}>
                                <h3 className="title text-center">
                                    <i className="fa fa-address-card" aria-hidden="true"></i>
                                    Edit Shipping Address
                                </h3>
                            </Col>
                            <Col md="4" style={{paddingLeft: "0"}}>
                                <Button 
                                    className="add-new-customer-common-btn add-new-customer-back-btn"
                                    onClick = {() => toggleView('allAddress') }
                                    >
                                <i className="fas fa-arrow-left"></i>
                                Back
                                </Button>
                            </Col>
                            
                            
                        </div>
                        {
                            address &&
                            <div className="new-address-sec-form">
                                <Row>
                                    <Col md="6" className="edit-customer-address-field">
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item sm={12}>
                                                <TextField
                                                    id="edit-customer_street"
                                                    label="Street"
                                                    placeholder="Street"
                                                    type="text"
                                                    fullWidth
                                                    name="address"
                                                    value={address.address}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Col>
                                    <Col md="6" className="edit-customer-address-field">
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item sm={12}>
                                                <TextField
                                                    id="edit-customer_city"
                                                    label="City"
                                                    placeholder="City"
                                                    type="text"
                                                    fullWidth
                                                    name="city"
                                                    value={address.city}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Col>
                                    <Col md="6" className="edit-customer-address-field">
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item sm={12}>
                                                <TextField
                                                    id="edit-customer_state"
                                                    label="State"
                                                    placeholder="State"
                                                    type="text"
                                                    fullWidth
                                                    name="state"
                                                    value={address.state}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Col>
                                    <Col md="6" className="edit-customer-address-field">
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item sm={12}>
                                                <TextField
                                                    id="edit-customer_zip"
                                                    label="ZIP"
                                                    placeholder="ZIP"
                                                    type="text"
                                                    fullWidth
                                                    name="zip"
                                                    value={address.zip}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Col>
                                    <Col md="6" className="edit-customer-address-field">
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item sm={12}>
                                                <TextField
                                                    id="edit-customer_country"
                                                    label="Country"
                                                    placeholder="Country"
                                                    type="text"
                                                    fullWidth
                                                    name="country"
                                                    value={address.country}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Col>
                                </Row>
                            </div>
                        }

                        
                        <div className="btn-sec d-flex align-items-center justify-content-center">
                            <Button className="customer-Edit-addr-btn ml-auto" onClick={this.updateAddress}>
                               {this.state.isLoading ? 'Processing...' : 'Update'}
                            </Button>
                        </div>
                    </div>
                </Row>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        address: state.auth.edited_address,
        token: state.auth.token
    }
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch)
})
export default connect(mapStateToProps, mapDispatchToProps)(CustomerEditAddress)