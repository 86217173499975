import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TreeItem from '@material-ui/lab/TreeItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class LogoLocationFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: ["1"],
            selected: ["1"],
            // checked: true,
            brands: [
                {label: "General", value: "general", total: "324"},
                {label: "Left Chest", value: "leftchest", total: "313"},
                {label: "Center", value: "center", total: "20"}
            ],
        }
    }

    handleToggle = (event, nodeIds) => {
        this.setState({
            expanded: nodeIds
        })
    }
    handleSelect = (event, nodeIds) => {
        this.setState({
            selected: nodeIds
        })
    }
    handleChange = (event, item) => {
        this.props.handleSelect(item, "logo_location")
    };

    render() {

        return (
            <div className="logo-location-filter-sec">
                <TreeView
                    defaultCollapseIcon={<RemoveIcon />}
                    defaultExpandIcon={<AddIcon />}
                    expanded={this.state.expanded}
                    selected={this.state.selected}
                    onNodeToggle={this.handleToggle}
                    onNodeSelect={this.handleSelect}
                >
                    <TreeItem nodeId="1" label="Logo Location">
                        {
                            this.state.brands.map((item, index) => 
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            color="primary" 
                                            value={item.value} 
                                            name="logo_location" 
                                            checked={this.props.selected.some(v => v.value === item.value)}
                                            onChange={e => this.handleChange(e, item)} 
                                        />
                                    }
                                    label={item.label + " (" + item.total + ")"}
                                    key={index}
                                    style={{width: '100%'}}
                                />
                            )
                        }
                        {/* <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="All Brands (324)"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="Gildan (313)"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="Columbia (10)"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="Hanes (20)"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="Nike (324)"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="Anvil (313)"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="Under Armour (10)"
                        /> */}
                    </TreeItem>
                </TreeView>
            </div>
        )
    }
}

export default LogoLocationFilter;