import React from "react";
import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import agent from '../../services/api-endpoint'

class FundRaising extends React.Component {
    
    componentDidMount() {
        document.body.classList.add("page");
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.wrapper.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.remove("page");
    }

    render(){
        const {site_slug} = agent;
        return (
            <>
            <CustomNavbar />

            <div className="wrapper" ref="wrapper">
                <div className ="main">
                    <div className="space-100" />
                    <section className="all-pages fund-raising-page section">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="all-pages-header">
                                        <h1><span>Fund</span> <span>Raising</span></h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="fund-raising-content-section">
                                <Col md="6">
                                <div className="combined-img-sec">
                                    <div className="fund-raising-img-sec">
                                      <img 
                                        alt="image"
                                        src={require("assets/img/fundraising.jpg")}
                                      />
                                    </div>
                                </div>
                                    
                                </Col>
                                <Col md="6" style ={{alignSelf: 'center'}}>
                                <div className="fund-raising-desc-sec">
                                    <h3 className="title">START RAISING MONEY FOR A CAUSE! EARN $$ ON EVERY SALE FROM YOUR STORE. IT&#39;S YOUR MONEY, YOU DECIDE WHERE TO SPEND IT!</h3>
                                    <p className="desc">We make Fundraising a FUN-RAISING! Our amazing platform allows you to determine how much or how little you want to raise towards your fundraising goal.</p>
                                    <Link className="build-store" to={site_slug.CREATE_STORE} tag={Link}>
                                        <img src={require('assets/img/store_icon.png')}/>
                                        BUILD YOUR STORE
                                    </Link>
                                </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    
                </div>
            </div>

            <CustomFooter />
            </>
        )
    }
            
}

export default FundRaising;