/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
    Button,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    Card,
    CardBody,
    Form,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input
} from "reactstrap";

class CustomFooter extends React.Component {
    state = {};
    render() {
        return (
            <>
                <footer className="footer custom-footer">
                    <div className="subscribe-line">
                      <Container>
                        <Row>
                          <Col className="ml-auto mr-auto" lg="10" sm="10" md="10">
                            <Card className="card-raised card-form-horizontal footer-newsletter-section">
                              <CardBody>
                                <Form action="" method="">
                                  <Row style={{alignItems: 'center'}}>
                                    <Col md="4">
                                        <h4 className="title"><span>Subscribe to Our</span><br/><span>NewsLetter</span></h4>
                                    </Col>
                                    <Col md="8">
                                        <div className="subscribe-form-group">
                                            <InputGroup
                                            className={classnames({
                                            "input-group-focus": this.state.emailFocus2
                                            })}
                                        >
                                            <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="tim-icons icon-email-85" />
                                            </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                className="subscribe-field"
                                            placeholder="Enter Your Email"
                                            type="email"
                                            onFocus={e =>
                                                this.setState({ emailFocus2: true })
                                            }
                                            onBlur={e =>
                                                this.setState({ emailFocus2: false })
                                            }
                                            />
                                        </InputGroup>
                                            <Button
                                                className="btn-round subscribe-btn"
                                                color="primary"
                                                type="button"
                                            >
                                                Subscribe
                                            </Button>
                                        </div>
                                      
                                    </Col>
                                    
                                  </Row>
                                </Form>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    <Container>
                        <Row className="justify-content-md-center align-items-md-start footer-content">
                            <Col md="3" xs="6" className="footer-content-col">
                                <h3 className="footer-title">ABOUT</h3>
                                <Nav>
                                    <NavItem>
                                        <NavLink to="/aboutus" tag={Link}>
                                            About Us
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/" tag={Link}>
                                            Why choose us?
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                            <Col md="3" xs="6" className="footer-content-col">
                                <h3 className="footer-title">SALES & DECORATION</h3>
                                <Nav>
                                    <NavItem>
                                        <NavLink to="/fund-raising" tag={Link}>
                                            Fundraising
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/artwork-decoration" tag={Link}>
                                            Artwork & Decoration
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                            <Col md="3" xs="6" className="footer-content-col">
                                <h3 className="footer-title">GET IN TOUCH</h3>
                                <Nav>
                                    <NavItem>
                                        <NavLink to="/contact-us" tag={Link}>
                                            Contact Us
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/career" tag={Link}>
                                            Careers
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/faq" tag={Link}>
                                            Faq
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                            <Col md="3" xs="6" className="footer-content-col">
                                <h3 className="footer-title">POLICIES</h3>
                                <Nav>
                                    <NavItem>
                                        <NavLink to="/terms-conditions" tag={Link}>
                                            Terms & Conditions
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/return-policy" tag={Link}>
                                            Return policy
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/privacy-policy" tag={Link}>
                                            Privacy policy
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" xs="12">
                                <div className="btn-wrapper footer-profile">
                                    <Button
                                        className="btn-icon btn-neutral btn-round btn-simple"
                                        color="default"
                                        href="#twitter"
                                        id="tooltip39661217"
                                        target="_blank"
                                    >
                                        <i className="fab fa-twitter" />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip39661217"
                                    >
                                        Follow us
                                    </UncontrolledTooltip>
                                    <Button
                                        className="btn-icon btn-neutral btn-round btn-simple ml-1"
                                        color="default"
                                        href="#facebook"
                                        id="tooltip206037619"
                                        target="_blank"
                                    >
                                        <i className="fab fa-facebook" />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip206037619"
                                    >
                                        Like us
                                    </UncontrolledTooltip>
                                    <Button
                                        className="btn-icon btn-neutral btn-round btn-simple ml-1"
                                        color="default"
                                        href="#youtube"
                                        id="tooltip750293512"
                                        target="_blank"
                                    >
                                        <i className="fab fa-youtube" />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip750293512"
                                    >
                                        Follow us
                                    </UncontrolledTooltip>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </>
        );
    }
}

export default CustomFooter;
