//import agent from '../services/Api-Endpoint';
import {
    LOGIN,
} from './actions/actionTypes';


const promiseMiddleware = store => next => action => {
    if (isPromise(action.payload)) {
        //console.log('getPromise')
        action.payload.then(
            res => {
                //console.log('RESULT', res);
                action.success = true;
                action.payload = res;
                next(action);
            },
            error => {
                //console.log('ERROR', error);
                action.success = false;
                action.payload = null;
                next(action);
            }
        )
    }else{
        next(action);
    }
}

const localStorageMiddleware = store => next => action => {
    // if (action.type === APP_LOAD) {
    //     if (!action.payload || !action.payload.success) {
    //         localStorage.removeItem('auth_token');
    //         localStorage.removeItem('role');
    //     }
    // }
    // if(action.type === LOGIN){
    //     if(action.payload.success){
    //         localStorage.setItem('micro_data', JSON.stringify({token: action.payload.token, role: action.payload.user.role}))
    //     }
    // }
    // if(action.type === LOGOUT){
    //     localStorage.removeItem('auth_token');
	//     localStorage.removeItem('role');
    // }
    // if (action.type === USER_EXPIRED) {
    //     localStorage.removeItem('auth_token');
    //     localStorage.removeItem('role');
    // }
    next(action);
}

function isPromise(v) {
    return v && typeof v.then === 'function';
}

export { promiseMiddleware, localStorageMiddleware }