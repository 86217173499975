import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TreeItem from '@material-ui/lab/TreeItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class SizeFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: ["1"],
            selected: ["1"],
            checked: true,
            sleeves: [
                {label: "One Size", value: "one-size"},
                {label: "Adjustable", value: "adjustable"},
                {label: "L", value: "L/XL"},
            ],
        }
    }

    handleToggle = (event, nodeIds) => {
        this.setState({
            expanded: nodeIds
        })
    }
    handleSelect = (event, nodeIds) => {
        this.setState({
            selected: nodeIds
        })
    }
    handleChange = (event, item) => {
        this.props.handleSelect(item, "size");
    };

    render() {

        return (
            <div className="size-filter-sec">
                <TreeView
                    defaultCollapseIcon={<RemoveIcon />}
                    defaultExpandIcon={<AddIcon />}
                    expanded={this.state.expanded}
                    selected={this.state.selected}
                    onNodeToggle={this.handleToggle}
                    onNodeSelect={this.handleSelect}
                >
                    <TreeItem nodeId="1" label="Size">
                        {
                            this.state.sleeves.map((item, index) => 
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            color="primary" 
                                            value={item.value} 
                                            name="size" 
                                            checked={this.props.selected.some(v => v.value === item.value)}
                                            onChange={e => this.handleChange(e, item)} 
                                        />
                                    }
                                    label={item.label}
                                    key={index}
                                    style={{width: '100%'}}
                                />
                            )
                        }
                    </TreeItem>
                </TreeView>
            </div>
        )
    }
}

export default SizeFilter;