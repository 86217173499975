import {
    Button,
    Card,
    CardBody,
    CardImg,
    CardTitle,
} from "reactstrap";

import React from 'react';

import ColorPicker from "components/Custom/color-picker/index.jsx";


class ProductItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            color_picker: false,
            color_pack: [],
            selected_color: [],
        }
    }

    componentDidMount = () => {
        const { color_picker, color_pack } = this.props;
        if (color_picker && color_pack.length > 0) {
            this.setState({
                color_picker,
                color_pack
            })
        }
    }

    handleSelect = (e, color, index) => {
        let {selected_color} = this.state;
        if(selected_color.includes(color))
            selected_color = selected_color.filter(v => v !== color);
        else
            selected_color.push(color);
        this.setState({ selected_color });
    }

    render() {

        return (

            <Card className="item-card">
                <div className="item-thumb">
                    <CardImg
                        top
                        alt="..."
                        src={require("assets/img/item/tshirt.png")}

                    />
                </div>
                <CardBody className="item-card-content">
                    {
                        (this.state.color_picker && this.state.color_pack.length > 0) &&
                        (
                            <div>
                                <ColorPicker 
                                    color_pack={this.state.color_pack} 
                                    selected={this.state.selected_color}
                                    handleSelect={this.handleSelect}
                                />
                            </div>
                        )
                    }
                    <CardTitle className="item-card-title">American Apparel Fine Jersey Short Sleeve T-shirt</CardTitle>
                    <ul className="item-details">
                        <li className="item-list">
                            <div className="item">
                                <i className="fas fa-sort-amount-up"></i>
                                <div className="item-sec">
                                    <span className="value">$14.05 up</span>
                                </div>
                            </div>

                        </li>
                        <li className="item-list">
                            <div className="item">
                                <i className="fas fa-stream"></i>
                                <div className="item-sec">
                                    <span className="value">14 resources</span>
                                </div>

                            </div>

                        </li>
                    </ul>
                    <div className="view-item-btn">
                        <Button>View Item</Button>
                    </div>
                </CardBody>
            </Card>

        )
    }
}

export default ProductItem;