import React from "react";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Label,
  Input,
  Button,
  Pagination, PaginationItem, PaginationLink
} from "reactstrap";

import CategoryCard from "../../../../components/Custom/category-card/index.jsx";
import agent from '../../../../services/api-endpoint'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    FETCH_BASECAT_WITH_STYLE,
    SAVE_SELECTED_BASECATEGORY
} from '../../../../redux/actions/actionTypes';
import action_middleware from '../../../../redux/action-creator'
import squash_loader from '../../../../assets/img/loader/splash-liquid-circle.gif'




class CategorySelection extends React.Component {
    constructor(){
        super()
        this.state = {
            isAllSelected: true,
            selectedItems: null,
            sticky: false,
        }
        this.isMount = true
        this.stickyHeader = React.createRef();
    }
    componentDidMount = () => {

        if(!this.props.baseCategory){
            
            // getting backend api access
            // agent.Auth.getApiAccess()
            // .then(data => {
            //     if( data && "success" in data ){
            //         if(data.success){
            //             agent.setToken(data.token);
            //             this.props.action_middleware(FETCH_BASECAT_WITH_STYLE, agent.Style.getStylesByGroup())
            //             .then(store_state => {
            //                 if(this.isMount)
            //                     this.setState({
            //                         selectedItems: store_state.baseSelection.selectedBasecat
            //                     })
            //             })
            //         }
            //     }
            // });
            this.props.action_middleware(FETCH_BASECAT_WITH_STYLE, agent.Style.getStylesByGroup())
            .then(store_state => {
                if(this.isMount)
                    this.setState({
                        selectedItems: store_state.baseSelection.selectedBasecat
                    })
            })
        }
        else{
            this.setState({ selectedItems: this.props.selectedBasecat })
        }
        
        document.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.getWidthofParent);
        window.addEventListener("scroll", this.getWidthofParent);

        setTimeout(()=>{
            this.handleScroll();
        },100)
    }
    componentWillUnmount = () => {
        this.isMount = false;
        document.removeEventListener("scroll", this.handleScroll);
        document.removeEventListener("resize", this.getWidthofParent);
        document.removeEventListener("scroll", this.getWidthofParent);
        this.handleScroll();
    }
    onCheckBoxChange = (checkName, isChecked) => {
        // console.log('onCheckBoxChange')
        let isAllChecked = checkName === "all" && isChecked;
        let isAllUnChecked = checkName === "all" && !isChecked;

        let {selectedItems} = this.state,
            {baseCategory} = this.props
        
        if(isAllChecked || isAllUnChecked){
            baseCategory.forEach((item, index) => {
                if ((isAllChecked || item.title === checkName) && isChecked) {
                    if(!selectedItems.includes(item.title))
                        selectedItems.push(item.title)
                }
                else if(isAllUnChecked){
                    selectedItems = []
                }
            });
        }
        else{
            if(isChecked)
                selectedItems.push(checkName)
            else
                selectedItems = selectedItems.filter(item => item !== checkName)
        }
        let allSelected = baseCategory.every(item => selectedItems.includes(item.title));

        this.setState({
            selectedItems,
            isAllSelected: allSelected
        })
    }
    updateCheckbox = title => {
        
        let {selectedItems} = this.state,
            {baseCategory} = this.props

        if(selectedItems.includes(title)){
            let filtered_Selected_Items = selectedItems.filter(item => item !== title)
            let isAllSelected = baseCategory.every(item => filtered_Selected_Items.includes(item.title));
            this.setState({ 
                selectedItems: filtered_Selected_Items,
                isAllSelected
            })
        }else{
            selectedItems.push(title)
            let isAllSelected = baseCategory.every(item => selectedItems.includes(item.title));
            this.setState({
                selectedItems,
                isAllSelected
            })
        }
    }
    checkItem = title => {
        this.updateCheckbox(title);
    }
    passSelectedCats = () => {
        const {selectedItems} = this.state
        if(selectedItems.length > 0){
            this.props.action_middleware(SAVE_SELECTED_BASECATEGORY, selectedItems).then(() => {
                this.props.openAccordion(1)  /* closing category accordion and open style accordion */
            })
        }
    }
    handleScroll = () => {
        let elem_top = this.stickyHeader.current.getBoundingClientRect().top,
            card_bottom = document.querySelector('.create-store-save-next-last').getBoundingClientRect().top;

        if(elem_top <= 110 ){
          this.setState({sticky: true}, () => {
            this.stickyHeader.current.querySelector('.sticky-wrapper-row .category-header').classList.add('sticky')
          })
        }
        if(elem_top >= 111){
          this.setState({sticky: false}, () => {
            this.stickyHeader.current.querySelector('.sticky-wrapper-row .category-header').classList.remove('sticky')
          })
        }
        if(card_bottom < 670){
          this.setState({sticky: false}, () => {
            this.stickyHeader.current.querySelector('.sticky-wrapper-row .category-header').classList.remove('sticky')
          })
        }
    }
    getWidthofParent = () => {
        if(this.isMount){
            let getWidthOfParent = document.querySelector('.row .category-header').offsetWidth;
            document.querySelector('.sticky-wrapper-row .category-header').style.width = `${getWidthOfParent}px`;
        }
    }

    render() {
        const {baseCategory} = this.props,
            {selectedItems} = this.state

        const sticky_header_css = {visibility: 'hidden', position: 'absolute', top: '0'},
        default_header_css = {};
          
        if(this.state.sticky){
            default_header_css['visibility'] = 'hidden'
            sticky_header_css['visibility'] = 'visible'
            delete sticky_header_css['position']
            delete sticky_header_css['top']
        }
        
        return (
            <>
                <section className="category-section-main" ref={this.stickyHeader}>
                    <Row style={default_header_css}>
                        <div className="category-header">
                            <Col md="9 text-center category-left">
                                <p className="sub-title">
                                    It's your store! indicate which item below you had
                                    like to offer for sale. Simply uncheck any category or product in a category you
                                    would like to be removed. You can even select
                                    which color to offer.
                                </p>
                            </Col>
                            <Col md="3 header-button-sec category-right">
                                <Button  
                                    className="btn-round ml-1 btn ml-auto d-flex create-store-save-next"
                                    // onClick ={() => this.props.openAccordion(1)}
                                    onClick ={this.passSelectedCats}
                                    disabled={(this.state.selectedItems || []).length === 0}
                                > 
                                    Save and Next
                                    <i className="fas fa-arrow-right"></i>
                                </Button>
                            </Col>
                        </div>
                    </Row>

                    <Row className="sticky-wrapper-row" style={sticky_header_css}>
                        <div className="category-header">
                            <Col md="9 text-center category-left">
                                <p className="sub-title">
                                    It's your store! indicate which item below you had
                                    like to offer for sale. Simply uncheck any category or product in a category you
                                    would like to be removed. You can even select
                                    which color to offer.
                                </p>
                            </Col>
                            <Col md="3 header-button-sec category-right">
                                <Button  
                                    className="btn-round ml-1 btn ml-auto d-flex create-store-save-next"
                                    // onClick ={() => this.props.openAccordion(1)}
                                    onClick ={this.passSelectedCats}
                                    disabled={(this.state.selectedItems || []).length === 0}
                                > 
                                    Save and Next
                                    <i className="fas fa-arrow-right"></i>
                                </Button>
                            </Col>
                        </div>
                    </Row>
                </section>

                {
                    baseCategory && baseCategory.length > 0 &&
                    <Row>
                        <div className="custom-checkbox-design-sec col-md-12">
                            <input 
                                id='check-all' 
                                type='checkbox' 
                                className="custom-checkbox-design"
                                onChange={(e) => this.onCheckBoxChange("all", e.target.checked)}
                                checked={this.state.isAllSelected}
                            />
                            <label htmlFor='check-all' className="custom-checkbox-label">
                                <span></span>
                                Check All
                                <ins><i>Check All</i></ins>
                            </label>
                        </div>
                    </Row>
                }
                <Row>
                    <div className="category-card-main">
                        {
                            !baseCategory && 
                            <div className="loader">
                                <img src={squash_loader} />
                            </div>
                        }
                        {
                            baseCategory && baseCategory.length === 0 &&
                            <h3>No category found.</h3>
                        }
                        {
                            baseCategory && baseCategory.map((item, index) => {
                                let isChecked = (selectedItems || []).includes(item.title)
                                return (
                                    <Col 
                                        md="2" 
                                        lg="2"
                                        className="category-item" 
                                        key={index}
                                    > 
                                        <CategoryCard 
                                            item={item}
                                            onSelect={this.checkItem}
                                            isChecked={isChecked}
                                        />
                                    </Col>
                                )
                            })
                        }
                    </div>
                </Row>
                <Row>
                    <Col md="12">
                        <Button  
                            className="btn-round ml-1 ml-auto mr-auto d-flex create-store-save-next-last"
                            // onClick ={() => this.props.openAccordion(1)}
                            onClick ={this.passSelectedCats}
                            disabled={(this.state.selectedItems || []).length === 0}
                        > 
                            Save and Next
                            <i className="fas fa-arrow-right"></i>
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = state => {
	return {
        baseCategory: state.baseSelection.baseCategory,
        selectedBasecat: state.baseSelection.selectedBasecat
	}
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(CategorySelection);