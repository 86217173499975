import React from 'react';
import {
    Row,
    Col,
  } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const OrganizationDetails = () =>{
    return(
        <div className="organaization-details-section">
            <Row>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="first_name"
                                label="First Name"
                                placeholder="First Name"
                                type="text"
                                fullWidth
                                name="first_name"
                            />
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="last_name"
                                label="Last Name"
                                placeholder="Last Name"
                                type="text"
                                fullWidth
                                name="last_name"
                            />
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="email"
                                label="Email"
                                placeholder="Email"
                                type="email"
                                fullWidth
                                name="email"
                            />
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="company_name"
                                label="Company Name"
                                placeholder="Company Name"
                                type="text"
                                fullWidth
                                name="company_name"
                            />
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <FormControl required fullWidth>
                                <InputLabel id="country">Country</InputLabel>
                                <Select
                                labelId="country"
                                id="country-select"
                                
                                //value={age}
                                //onChange={handleChange}
                                //className={classes.selectEmpty}
                                >
                                <MenuItem value="">
                                    <em>Select Country</em>
                                </MenuItem>
                                <MenuItem>Bangladesh</MenuItem>
                                <MenuItem>Uk</MenuItem>
                                <MenuItem>USA</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <FormControl required fullWidth>
                                <InputLabel id="state">State</InputLabel>
                                <Select
                                labelId="state"
                                id="country-select"
                                
                                //value={age}
                                //onChange={handleChange}
                                //className={classes.selectEmpty}
                                >
                                <MenuItem value="">
                                    <em>Select State</em>
                                </MenuItem>
                                <MenuItem>state 1</MenuItem>
                                <MenuItem>state 2</MenuItem>
                                <MenuItem>state 1</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="zip_postal_code"
                                label="Zip / Postal Code"
                                placeholder="Zip / Postal Code"
                                type="text"
                                fullWidth
                                name="zip_postal_code"
                            />
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="city"
                                label="City"
                                placeholder="City"
                                type="text"
                                fullWidth
                                name="city"
                            />
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="address_1"
                                label="Address 1"
                                placeholder="Address 1"
                                type="text"
                                fullWidth
                                name="address_1"
                            />
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="address_2"
                                label="Address 2"
                                placeholder="Address 2"
                                type="text"
                                fullWidth
                                name="address_2"
                            />
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="phone_number"
                                label="Phone Number"
                                placeholder="Phone Number"
                                type="text"
                                fullWidth
                                name="phone_number"
                            />
                        </Grid>
                    </Grid>
                </Col>
                <Col md="6">
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item sm={12}>
                            <TextField
                                id="Fax_number"
                                label="Fax Number"
                                placeholder="Fax Number"
                                type="text"
                                fullWidth
                                name="Fax_number"
                            />
                        </Grid>
                    </Grid>
                </Col>
            </Row>
        </div>
    )
}
export default OrganizationDetails;