
import React from "react";
import { Route, Switch } from "react-router-dom";

import agent from '../services/api-endpoint'


import Home from "views/home/index.jsx";
// import Login from "views/auth/login/index.jsx";
// import SignUp from "views/auth/signup/index.jsx";
import Authentication from '../views/auth'
import BuildStore from "views/store/index.jsx";

import Dashboard from '../views/dashboard'
import ChangePassword from 'views/auth/change-password'

import Protected from '../components/Protected/protected'
import LogoGenerator from '../views/logo-generator'

import FAQ from '../views/faq'
import AboutUs from '../views/about-us'
import FundRaising from '../views/fund-raising'
import Career from '../views/career'
import ArtworkDecoration from '../views/artwork-decoration'
import ContactUs from '../views/contact-us'
import TermsConditions from '../views/terms-conditions'
import PrivacyPolicy from '../views/privacy-policy'
import ReturnPolicy from '../views/return-policy'
// import StoreOwnerPortal from '../views/store-owner-portal';
import CustomerPortal from '../views/customer-portal';
// import AdminPortal from '../views/admin-portal';

import CustomerAuthentication from '../views/auth';
import SignOut from '../views/auth/sign-out'

class AppRoute extends React.PureComponent {

  render() {
    const { site_slug } = agent
    return (
      <Switch>
        <Route path={site_slug.HOME} exact render={props => <Home {...props} />} />
        {/* <Route 
            path={site_slug.LOGIN} exact 
            render={props => <Protected props_data={props} component={Login} user_access='guest' />}
        /> */}
        {/* <Route 
          path={site_slug.STORE_OWNER_SIGN_UP} exact 
          render={props => <Protected props_data={props} component={Authentication} user_access='guest' />}
        /> */}

        <Route 
          path={site_slug.CUSTOMER_SIGN_UP} exact 
          render={props => <Protected props_data={props} component={CustomerAuthentication} user_access='guest' />}
        /> 

        <Route 
          path={agent.site_slug.CHANGE_PASSWORD} exact 
          render={props => <Protected props_data={props} component={ChangePassword} user_access='guest' />}
        />

        {/* <Route 
          path='/store-owner-portal' exact 
          render={props => <Protected props_data={props} component={StoreOwnerPortal} user_access='storeOwner' />}
        /> */}
        <Route 
          path={site_slug.CUSTOMER_PORTAL} exact 
          render={props => <Protected props_data={props} component={CustomerPortal} user_access='customer' />}
        />

        <Route 
          path={site_slug.CREATE_STORE} exact 
          render={props => <BuildStore {...props} />} 
        />

        <Route 
          path={site_slug.SIGN_OUT} exact 
          render={props => <Protected props_data={props} component={SignOut} user_access='auth' />} 
        />

        <Route path="/faq" exact render={props => <FAQ {...props} />} />
        <Route path="/aboutus" exact render={props => <AboutUs {...props} />} />
        <Route path="/fund-raising" exact render={props => <FundRaising {...props} />} />
        <Route path="/career" exact render={props => <Career {...props} />} />
        <Route path="/artwork-decoration" exact render={props => <ArtworkDecoration {...props} />} />
        <Route path="/contact-us" exact render={props => <ContactUs {...props} />} />
        <Route path="/terms-conditions" exact render={props => <TermsConditions {...props} />} />
        <Route path="/privacy-policy" exact render={props => <PrivacyPolicy {...props} />} />
        <Route path="/return-policy" exact render={props => <ReturnPolicy {...props} />} />
        {/* <Route path="/store-owner-portal" exact render={props => <StoreOwnerPortal {...props} />} />
        <Route path="/customer-portal" exact render={props => <CustomerPortal {...props} />} /> */}
        {/* <Route path="/admin-portal" exact render={props => <AdminPortal {...props} />} /> */}

        {/* testing route */}
        <Route path='/logo-generator' exact render={props => <LogoGenerator {...props} />} />
        {/* testing route */}

      </Switch>
    );
  }
}

export default AppRoute;



