import React from "react";
import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import agent from '../../services/api-endpoint'


class ArtworkDecoration extends React.Component {
    
    componentDidMount() {
        document.body.classList.add("page");
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.wrapper.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.remove("page");
    }

    render(){
        const {site_slug} = agent;
        return (
            <>
            <CustomNavbar />

            <div className="wrapper" ref="wrapper">
                <div className ="main">
                    <div className="space-100" />
                    <section className="all-pages Careers-page section">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="all-pages-header">
                                        <h1><span>ARTWORK</span><span> & DECORATION</span></h1>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section section-printing-artwork">
                        <Container>
                            <Row>
                                <Col md="7">
                                    <div className="combined-img-sec">
                                        <div className="secondary-image">
                                            <img src={require('assets/img/artwork-decoration-embroidery.jpg')} />
                                        </div>
                                    </div>
                                </Col>
                                <Col md="5" style={{alignSelf: 'center'}}>
                                    <div className="sec-des">
                                        <h1 className="title">PRINTING</h1>
                                        <p className="desc">We uses the finest imported European materials to print designs on the items listed below. Our technique delivers a featherweight, soft touch that moves with your body and stretches with the material it's printed on. All of our decoration methods are designed to be laundered and stand up to on- the-field play.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section section-printing-artwork " style ={{background: '#efefefcc'}}>
                        <Container style ={{marginTop: '60px'}}>
                            <Row>
                                <Col md="5" style={{alignSelf: 'center'}}>
                                    <div className="sec-des right">
                                        <h1 className="title">EMBROIDERY</h1>
                                        <p className="desc">We uses only the finest, high luster yarns to achieve a highly impactful embroidered logo designed to stand up to repeated laundering. All of our embroidery materials match the Sporting Goods Merchandizing Association color standards and are guaranteed to please the most discerning customer.</p>
                                    </div>
                                </Col>
                                <Col md="7">
                                    <div className="combined-img-sec right">
                                        <div className="secondary-image">
                                            <img src={require('assets/img/artwork-decoration-embroidery.jpg')} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section artwork-bottom-sec">
                        <Container>
                            <Row className="artwork-bottom-row">
                                <Col md="5">
                                    <div className="img-sec">
                                        <img src={require('assets/img/ArtworkandDeco_Graphic_Printed.png')} />
                                    </div>
                                </Col>
                                <Col md="7">
                                    <div className="desc-sec">
                                        <h2 className="title">Personalization (name and/or number) added to these garments will be printed to match the logo decoration method.</h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="artwork-bottom-row">
                                <Col md="7">
                                    <div className="desc-sec right">
                                        <h2 className="title">Items will be produced with logos in same position as seen below, for special requests please click on the chat bubble below.</h2>
                                    </div>
                                </Col>
                                <Col md="5">
                                    <div className="img-sec right">
                                        <img src={require('assets/img/ArtworkandDeco_Graphic_Embroidery.png')} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="artwork-bottom-lastrow">
                                <Col md="12">
                                    <p className="desc">Every logo is carefully examined at each stage of the decorating process. Once any backgrounds are removed and details perfected, our production team gets it ready to be applied to your selected apparel.</p>
                                    <h3 className="title text-center">We take great pride in the final product we deliver to you and your teammates.</h3>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </div>

            <CustomFooter />
            </>
        )
    }
            
}

export default ArtworkDecoration;