import React from "react";
import {
    Row,
    Col,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter, 
    Button
  } from "reactstrap";

import {
    SET_SELECTED_PRODUCT
} from '../../../../../redux/actions/actionTypes'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import action_middleware from '../../../../../redux/action-creator'
import agent from '../../../../../services/api-endpoint'
import Notification from '../../../../../components/Custom/Notification'

class InfoProductModal extends React.Component{
    state={
        notification: false,
    }
    onDismiss = () => this.setState({notification: false})
    handleProductSelect = (e, item) => {
        this.setState({notification: null})
        this.props.action_middleware(
            SET_SELECTED_PRODUCT, 
            {
                checked: e.target.checked,
                style: this.props.style_item, 
                product: item
            }
        ).then(() => this.setState({notification: true}))
    }

    render(){
        const {infoShowModal, infoToggleModal, style_item, selected_products} = this.props,
            {notification} = this.state

        let products = this.props.products[style_item.uniqueStyleName].filter(item => 
            (selected_products[style_item.uniqueStyleName] || []).includes(item.gtin)
        )
        
        return(
            <Modal
                isOpen={infoShowModal}
                toggle={infoToggleModal}
                className="Product-info-card-modal"
                fade = {false}
            >
                <div className="modal-header justify-content-center product-info-modal-header">
                    <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={infoToggleModal}
                    >
                    <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h4 className="title title-up product-info-modal-title"><span>Selected</span> products</h4>
                </div>
                <ModalBody className="product-info-modal-body">
                    <Row>
                        <Col md="12">
                            <div className="modal-item-desc">
                                <div className="title-with-prize">
                                    <h2 className="title">
                                        {style_item.brandName} - {style_item.title} - {style_item.uniqueStyleName}
                                    </h2>
                                </div>
                                <div className="remove-notification-sec">
                                    {
                                        notification && 
                                        <Notification delay="1500"  /* onExpire={this.onDismiss} */>
                                            <div className="alert-section product-delete-alert">
                                                <p>Product Removed</p>
                                            </div>
                                        </Notification>
                                    }
                                </div>
                                <div className="color-available-sec">
                                    <h5 className="title">Selected Products List</h5>
                                    {
                                        products.length !== 0 && <p className="check-subtitle">If you want to remove product, just <img src={require('assets/img/check_color.png')} className="product-img-check" /> uncheck below</p>
                                    }
                                    <ul className="color-lists-with-short-image">
                                        {
                                            products.length === 0 &&
                                            <p className="empty-msg">You have no selected products</p>
                                        }
                                        {
                                            products.map((item, i) => {
                                                    return (
                                                        <li 
                                                            className="color-item" 
                                                            key={i}
                                                        >
                                                            <div className="custom-checkbox-design-sec">
                                                                <input 
                                                                    type='checkbox' 
                                                                    className="custom-checkbox-design"
                                                                    name ="product"
                                                                    id={item.gtin}
                                                                    value={item.gtin}
                                                                    checked={true}
                                                                    onChange={e => this.handleProductSelect(e, item)}
                                                                />
                                                                <label htmlFor={item.gtin} className="custom-checkbox-label">
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                            <div className="short-image">
                                                                {
                                                                    item.colorFrontImage
                                                                    ? <img src={`${agent.settings.ssactiveware_root_url}/${item.colorFrontImage}`} className="d-block" />
                                                                    : <img src={`${agent.settings.ssactiveware_root_url}/${style_item.styleImage}`} className="d-block" />
                                                                }
                                                                {/* <img src={`${agent.settings.ssactiveware_root_url}/${item.colorFrontImage}`} className="d-block" /> */}
                                                            </div>
                                                            <div className="color-image-with-title">
                                                                <img src={`${agent.settings.ssactiveware_root_url}/${item.colorSwatchImage}`} className="d-block" />
                                                                <h5 className="color-name">asd{item.colorName}</h5>
                                                            </div>
                                                        </li>
                                                    )
                                                // }
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                {/* <div className="modal-footer product-info-modal-footer">
                    <Button
                    className="modal-add-to-product ml-auto"
                    type="button"
                    >
                    <i className="tim-icons icon-cart"></i>
                    Save Changes
                    </Button>
                </div> */}
            </Modal>
        )
    }
}

const mapStateToProps = state => {
	return {
        style_item: state.baseSelection.current_style_item,
        products: state.baseSelection.products,
        selected_products: state.baseSelection.selected_products,
        // selected_products_modified: state.baseSelection.selected_products_modified,
	}
}
const mapDispatchToProps = dispatch => ({
  action_middleware: bindActionCreators(action_middleware, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(InfoProductModal)