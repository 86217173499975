import React from "react";
import "assets/css/nucleo-icons.css";
import { Row, Col, Button } from "reactstrap";

import Select from "react-select";

import CategoryFilter from "./filter/category";
import ManufacturerFilter from "./filter/manufacturer";
import SizeFilter from "./filter/size";
import LogoImageFilter from "./filter/logo-image";
import ColorFilter from "./filter/color";
import LogoLocationFilter from "./filter/logo-location";
import LogoTypeFilter from "./filter/logo-type";
import StyleCatalog from "./style";
import AddProductAddModal from "./add-product-modal"
import InfoProductModal from "./info-product-modal"

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  CHANGE_CREATE_STORE_STEP,
  RESET_STYLE_FILTER,
  SET_STYLE_SORT_KEY
} from '../../../../redux/actions/actionTypes'
import action_middleware from '../../../../redux/action-creator'
import agent from '../../../../services/api-endpoint'




class StyleSelection extends React.Component {
  constructor(props) {
    super();
    this.state = {
      sticky: false,
      showModal: false,
      infoShowModal: false,
      sortBy: 'alphabet'
    }
    this.stickyHeader = React.createRef();
  }
  componentDidMount = () => {
    document.addEventListener("scroll", this.handleScroll);
    
    setTimeout(()=>{
      this.getWidthofParent()
      this.handleScroll();
    },1000)
    window.addEventListener("resize", this.getWidthofParent);
    
  }
  componentWillUnmount = () => {
    document.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.getWidthofParent);
    this.getWidthofParent()
  }
  handleScroll = () => {
    // console.log(this.stickyHeader.current.getBoundingClientRect().bottom, window.hasMoreProduct)
    //console.log(window.innerHeight - this.stickyHeader.current.getBoundingClientRect().bottom)
    // console.log(this.stickyHeader.current.querySelector('.style-header'))

    let elem_top = this.stickyHeader.current.getBoundingClientRect().top,
        elem_bottom = this.stickyHeader.current.getBoundingClientRect().bottom,
        search_item_sec_height = document.querySelector('.search-item-sec').getBoundingClientRect().height,
        bottom_btn_group_style = document.querySelector('.style-bottom-button-group').getBoundingClientRect().top


    if(elem_top <= 110 && elem_bottom > window.innerHeight+140){
      // console.log('add e dukse')
      this.setState({sticky: true}, () => {
        this.stickyHeader.current.querySelector('.sticky-wrapper-section').classList.add('sticky')
        this.stickyHeader.current.querySelector('.sticky-sidebar').classList.add('sticky')
      })
    }
    if(elem_top >= 111){
      // console.log('remove e dukse')
      this.setState({sticky: false}, () => {
        this.stickyHeader.current.querySelector('.sticky-wrapper-section').classList.remove('sticky')
        this.stickyHeader.current.querySelector('.sticky-sidebar').classList.add('sticky')
      })
    }
    if(elem_bottom < 675 && !window.hasMoreProduct){
      //console.log('remove e dukse')
      this.setState({sticky: false}, () => {
        this.stickyHeader.current.querySelector('.sticky-wrapper-section').classList.remove('sticky')
        this.stickyHeader.current.querySelector('.sticky-sidebar').classList.add('sticky')
      })
    }
    if(search_item_sec_height < 50){
      this.setState({sticky: false}, () => {
        this.stickyHeader.current.querySelector('.sticky-wrapper-section').classList.remove('sticky')
        this.stickyHeader.current.querySelector('.sticky-sidebar').classList.add('sticky')
      })
    }
    if(bottom_btn_group_style < window.innerHeight){
      this.setState({sticky: false}, () => {
        this.stickyHeader.current.querySelector('.sticky-wrapper-section').classList.remove('sticky')
        this.stickyHeader.current.querySelector('.sticky-sidebar').classList.add('sticky')
      })
    }
  }
  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  }
  infoToggleModal = () => {
    this.setState({
      infoShowModal: !this.state.infoShowModal
    });
  }
  handleBackClick = () => {
    this.props.closeAccrodian(2)
    this.props.action_middleware(RESET_STYLE_FILTER, {})
  }
  getWidthofParent = () => {
    let getWidthOfParent = document.querySelector('.refine-search-sec').offsetWidth;
    document.querySelector('.sticky-sidebar .refine-search-sec').style.width = `${getWidthOfParent}px`;
  }
  handleSortChange = obj => {
    this.setState({ sortBy: obj.value })
    this.props.action_middleware(SET_STYLE_SORT_KEY, obj.value)
  }

  render() {

    let {itemToShow, filtered_style_items, filter} = this.props

    const sticky_header_css = {visibility: 'hidden', position: 'absolute', top: '0'},
          default_header_css = {}
        
    if(this.state.sticky){
      default_header_css['visibility'] = 'hidden'
      sticky_header_css['visibility'] = 'visible'
      delete sticky_header_css['position']
      delete sticky_header_css['top']
    }

    // checking if any product is selected
    const selected_product_counter = [].concat.apply([], Object.values(this.props.selected_products))

    let items = agent.filterBy(filtered_style_items, filter)
    let visible_style_num = itemToShow < items.length ? itemToShow : items.length


    return (
      <>
       <section className="style-section-main" ref={this.stickyHeader}>

            <Row style={default_header_css}>
              <div 
                className="style-header" 
                //ref={this.stickyHeader}
              >
                  <Col md="3 header-button-sec">
                      <Button  
                          className="btn-round ml-1 btn d-flex create-store-back"
                          onClick={this.handleBackClick}
                      > 
                          <i className="fas fa-arrow-left"></i>
                          Back
                      </Button>
                  </Col>
                  <Col md="6 text-center">
                      {/* <h1 className="title"><span>Select Your </span><br/><span>Product</span></h1> */}
                      <h3 className="category-sub-title">Your selected categories currently offer all below products for sale.</h3>
                  </Col>
                  <Col md="3 header-button-sec">
                      <Button  
                          className="btn-round ml-1 btn ml-auto d-flex create-store-save-next"
                          //onClick ={() => this.props.openAccordion(2)}
                          onClick ={() => {
                            //this.props.tabToggle('hTabs','addLogo')
                            this.props.action_middleware(CHANGE_CREATE_STORE_STEP,'addLogo')
                          }}
                          disabled={selected_product_counter.length === 0}
                      > 
                          Save and Next
                          <i className="fas fa-arrow-right"></i>
                      </Button>
                  </Col>
              </div>
            </Row>

            <Row className="sticky-wrapper-row" style={sticky_header_css}>
              <div className="sticky-wrapper-section">
                <div 
                  className="style-header" 
                  //ref={this.stickyHeader}
                >
                    <Col md="3 header-button-sec">
                    <Button  
                            className="btn-round ml-1 btn d-flex create-store-back"
                            onClick={this.handleBackClick}
                        > 
                          <i className="fas fa-arrow-left"></i>
                            Back
                        </Button>
                    </Col>
                    <Col md="6 text-center">
                        {/* <h1 className="title"><span>Select Your </span><br/><span>Product</span></h1> */}
                        <h3 className="category-sub-title">Your selected categories currently offer all below products for sale.</h3>
                    </Col>
                    <Col md="3 header-button-sec">
                        <Button  
                            className="btn-round ml-1 btn ml-auto d-flex create-store-save-next"
                            onClick ={() => {
                              //this.props.tabToggle('hTabs','addLogo')
                              this.props.action_middleware(CHANGE_CREATE_STORE_STEP,'addLogo')
                            }}
                            disabled={selected_product_counter.length === 0}
                        > 
                            Save and Next
                            <i className="fas fa-arrow-right"></i>
                        </Button>
                    </Col>
                </div>
              </div>
            </Row>

            <Row>
              <Col lg="3" md="3">
                <div className="refine-search-sec" style={default_header_css}>
                    <CategoryFilter />
                    <ManufacturerFilter />
                    {/* <SizeFilter /> */}
                    {/* <LogoImageFilter /> */}
                    {/* <ColorFilter /> */}
                    {/* <LogoLocationFilter /> */}
                    {/* <LogoTypeFilter /> */}
                </div>

                <div className="sticky-sidebar" style={sticky_header_css}>
                  <div className="refine-search-sec">
                      <CategoryFilter />
                      <ManufacturerFilter />
                      {/* <SizeFilter /> */}
                      {/* <LogoImageFilter /> */}
                      {/* <ColorFilter /> */}
                      {/* <LogoLocationFilter /> */}
                      {/* <LogoTypeFilter /> */}
                  </div>
                </div>
              </Col>

              <Col lg="9" md="9">
                <div className="catalog-right-sec">
                  <div className="search-item-filter-count-sec">
                    <Row>
                      <Col md="12">
                      <p className="category-sub-caption">simply check any item you wish to offer for sale on your site. You can limit the color options for an item by checking the color box(s).</p>
                      </Col>
                    </Row>
                    <Row className="justify-content-md-between align-items-md-center">
                      <Col md="8">
                        <div>
                            <p>
                              Showing Items <span>{visible_style_num}</span> of <span>{items.length}</span> results
                            </p>
                         </div>
                      </Col>
                      <Col md="4">
                        <div className="sort-by">
                          <Select
                            className="react-select sort-by-select"
                            classNamePrefix="react-select"
                            name="styleSortBy"
                            defaultValue={this.state.sortBy}
                            onChange={this.handleSortChange}
                            options={[
                              {
                                value: "",
                                label: "Sort By",
                                isDisabled: true,
                              },
                              { value: "alphabet", label: "Alphabetically" },
                              { value: "price", label: "Lower to Higher Price" },
                              { value: "category", label: "Category" },
                              { value: "brand", label: "Manufacturer" },
                            ]}
                            placeholder="Sort By"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="search-item-sec">
                    <StyleCatalog
                      showModal ={this.state.showModal}
                      toggleModal ={this.toggleModal}
                      infoShowModal ={this.state.infoShowModal}
                      infoToggleModal ={this.infoToggleModal}
                    />
                    {
                      this.state.showModal && 
                      <AddProductAddModal 
                        showModal ={this.state.showModal} 
                        toggleModal ={this.toggleModal}
                      />
                    } 
                    {
                      this.state.infoShowModal && 
                      <InfoProductModal 
                        infoShowModal ={this.state.infoShowModal} 
                        infoToggleModal ={this.infoToggleModal}
                      />
                    } 
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="style-bottom-button-group">
              <Col md="6">
                <button  
                    className="btn-round ml-1 btn ml-auto mr-auto d-flex create-store-back"
                    onClick={this.handleBackClick}
                > 
                    <i className="fas fa-arrow-left"></i>
                    Back
                </button>
              </Col>
              <Col md="6">
                <button  
                    className="btn-round ml-1 btn ml-auto mr-auto d-flex create-store-save-next-last"
                    //onClick ={() => this.props.openAccordion(2)}
                    onClick ={() => {
                      // this.props.tabToggle('hTabs','addLogo')
                      this.props.action_middleware(CHANGE_CREATE_STORE_STEP,'addLogo')
                    }}
                    disabled={selected_product_counter.length === 0}
                > 
                    Save and Next
                    <i className="fas fa-arrow-right"></i>
                </button>
              </Col>
          </Row>
        </section>
      </>
    );
  }
}

const mapStateToProps = state => {
	return {
    selected_products: state.baseSelection.selected_products,
    filtered_style_items: state.baseSelection.filtered_style_items,
    filter: state.baseSelection.filter,
    itemToShow: state.baseSelection.itemToShow,
	}
}
const mapDispatchToProps = dispatch => ({
  action_middleware: bindActionCreators(action_middleware, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(StyleSelection)