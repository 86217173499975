import React from "react";
import {
    Row,
    Col,
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter, 
    Button
} from "reactstrap";

import {
    FETCH_PRODUCT_BY_STYLE,
    SET_SELECTED_PRODUCT,
    ALL_PRODUCT_SELECTED,
    ALL_PRODUCT_UNSELECTED
} from '../../../../../redux/actions/actionTypes'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import action_middleware from '../../../../../redux/action-creator'
import agent from '../../../../../services/api-endpoint'
import squash_loader from '../../../../../assets/img/loader/splash-liquid-circle.gif'
import Notification from '../../../../../components/Custom/Notification'
import Checkbox from '@material-ui/core/Checkbox';

class AddProductModal extends React.Component{ 
    constructor(){
        super()
        this.state = {
            current_sku_img: null,
            notification: false,
        }
    }
    componentDidMount = () => {
        let {products} = this.props
        let {styleId, sku, brandName, uniqueStyleName} = this.props.style_item

        if(! (uniqueStyleName in products)){
            let param = [
                {
                    key: 'style',
                    value: `${styleId},${sku},${brandName},${uniqueStyleName}`,
                    in: 'query'
                }
            ]
            this.props.action_middleware(FETCH_PRODUCT_BY_STYLE, agent.Product.getProductByStyle(param))
        }
    }
    // handleSkuImg = img => {
    //     this.setState({current_sku_img: img})
    // }
    onDismiss = () => this.setState({notification: false})
    handleProductSelect = (/* e, */ ischecked, item) => {
        this.setState({
            notification: null,
            current_sku_img: item.colorFrontImage
        })
        this.props.action_middleware(
            SET_SELECTED_PRODUCT, 
            {
                // checked: e.target.checked,
                checked: ischecked,
                style: this.props.style_item, 
                product: item
            }
        ).then(() => {
            this.setState({ notification: true })
        })
    }
    handleAllProductSelect = target => {
        this.setState({
            notification: null
        })
        const action_type = target.checked ? ALL_PRODUCT_SELECTED : ALL_PRODUCT_UNSELECTED
        this.props.action_middleware( 
            action_type,
            {style: this.props.style_item}
        ).then(() => {
            this.setState({ notification: true })
        })
    }

    render(){

        const {showModal, toggleModal, style_item, selected_products, products} = this.props,
            {current_sku_img, notification} = this.state

        const style_products =  !(style_item.uniqueStyleName in products) 
                                ? [] 
                                : products[style_item.uniqueStyleName]
                                // : products[style_item.uniqueStyleName].filter((item, i) => {
                                //     if(item.colorFrontImage) return item 
                                // })

        const isAllSelected = !(style_item.uniqueStyleName in products)
                              ? false
                              : style_item.uniqueStyleName in selected_products && products[style_item.uniqueStyleName].length === selected_products[style_item.uniqueStyleName].length

        return(
            <Modal
                isOpen={showModal}
                toggle={toggleModal}
                className="Product-added-card-modal"
                fade = {false}
            >
                <div className="modal-header justify-content-center product-added-modal-header">
                    <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                    >
                    <i className="tim-icons icon-simple-remove" />
                    </button>
                    <h4 className="title title-up product-added-modal-title"><span>Product</span> info</h4>
                </div>
                <ModalBody className="product-added-modal-body">
                    <Row>
                        <Col md="4">
                            <div className="modal-item-img">
                            {
                                current_sku_img ?
                                <img src={`${agent.settings.ssactiveware_root_url}/${current_sku_img}`} className="d-block" /> :
                                <img src={`${agent.settings.ssactiveware_root_url}/${style_item.styleImage}`} className="d-block" />
                            }
                            </div>
                            <div className="size-range">
                                <h5 className="title">Size Range:</h5>
                                <h5 className="value">S to 4XL</h5>
                            </div>
                            {
                                notification && 
                                <Notification delay="6000" /* onExpire={this.onDismiss} */>
                                    <div className="alert-section product-added-alert">
                                        <p>
                                            {`${(selected_products[style_item.uniqueStyleName] || []).length} product added`}
                                        </p>
                                    </div>
                                </Notification>
                            }
                        </Col>
                        <Col md="8">
                            <div className="modal-item-desc">
                                <div className="title-with-prize">
                                    <h2 className="title">
                                        {style_item.brandName} - {style_item.title} - {style_item.uniqueStyleName}
                                    </h2>
                                    <div className="dollar-sec">
                                        <img src={require("assets/img/dollar.png")} />
                                        <h3 className="dollar">120</h3>
                                    </div>
                                </div>
                                
                                <div className="color-available-sec">
                                    <h5 className="title">Colors Available</h5>
                                    <p className="check-subtitle">If you want to remove product, just <img src={require('assets/img/check_color.png')} className="product-img-check" /> uncheck below</p>
                                    {
                                        !(style_item.uniqueStyleName in products) &&
                                        <div className="loader">
                                            <img src={squash_loader} />
                                        </div>
                                    }

                                    {
                                        products[style_item.uniqueStyleName] && style_products.length > 0 &&
                                        <div className="custom-checkbox-design-sec col-md-12">
                                            <input 
                                                type='checkbox' 
                                                className="custom-checkbox-design"
                                                name="check-all"
                                                id="checkAllProduct"
                                                checked={isAllSelected}
                                                onChange={e => this.handleAllProductSelect(e.target)}
                                            />
                                            <label htmlFor="checkAllProduct" className="custom-checkbox-label product-added-checkall">
                                                <span></span>
                                                Check All
                                                <ins><i>Check All</i></ins>
                                            </label>
                                        </div>
                                    }

                                    <ul className="color-lists-with-short-image">
                                        {
                                            products[style_item.uniqueStyleName] && style_products.length === 0 &&
                                            <p className="no-product-available">Sorry, no color sample is available.</p>
                                        }
                                        {
                                            style_products.map((item, i) => {
                                                let isChecked = (selected_products[style_item.uniqueStyleName] || []).includes(item.gtin)
                                                return (
                                                    <li 
                                                        className="color-item" 
                                                        key={i}
                                                    >
                                                        <div className="custom-checkbox-design-sec">
                                                            <input 
                                                                type='checkbox' 
                                                                className="custom-checkbox-design"
                                                                name="product"
                                                                id={item.gtin}
                                                                // checked={selected_products.includes(item.gtin)}
                                                                checked={(selected_products[style_item.uniqueStyleName] || []).includes(item.gtin)}
                                                                value={item.gtin}
                                                                onChange={e => this.handleProductSelect(e.target.checked, item)}
                                                            />
                                                            <label htmlFor={item.gtin} className="custom-checkbox-label">
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <div 
                                                            className="content-wrapper" 
                                                            onClick={() => this.handleProductSelect(!isChecked, item)}
                                                            // onClick={() => this.handleSkuImg(item.colorFrontImage)}
                                                        >
                                                            <div className="short-image">
                                                                {
                                                                    item.colorFrontImage
                                                                    ? <img src={`${agent.settings.ssactiveware_root_url}/${item.colorFrontImage}`} className="d-block" />
                                                                    : <img src={`${agent.settings.ssactiveware_root_url}/${style_item.styleImage}`} className="d-block" />
                                                                }
                                                                {/* <img src={`${agent.settings.ssactiveware_root_url}/${item.colorFrontImage}`} className="d-block" /> */}
                                                            </div>
                                                            <div className="color-image-with-title">
                                                                <img src={`${agent.settings.ssactiveware_root_url}/${item.colorSwatchImage}`} className="d-block" />
                                                                <h5 className="color-name">{item.colorName}</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="product-desc">
                                    <h5 className="title">Product Description</h5>
                                    {ReactHtmlParser(style_item.description)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                {/* <div className="modal-footer product-added-modal-footer">
                    <Button
                    className="modal-add-to-product ml-auto"
                    type="button"
                    >
                    <i className="tim-icons icon-cart"></i>
                    Add to Product
                    </Button>
                </div> */}
            </Modal>
        )
    }
}

const mapStateToProps = state => {
	return {
        style_item: state.baseSelection.current_style_item,
        selected_products: state.baseSelection.selected_products,
        products: state.baseSelection.products
	}
}
const mapDispatchToProps = dispatch => ({
  action_middleware: bindActionCreators(action_middleware, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(AddProductModal)