import React from 'react'
import { Button, Container, Row, Col,  } from "reactstrap";
import { connect } from 'react-redux'
import {
    LOGOUT
} from '../../redux/actions/actionTypes'
import agent from '../../services/api-endpoint'

import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";

const Dashboard = props => {

    const handleLogout = () => {
        props.onLogoutAttempt(agent.Auth.logout(props.auth_token))
    }
    const style ={
        fontSize: '60px',
        lineHeight: '1.2',
        fontWeight: '600',
        color: '#414c84',
        margin: '25px 0 50px',
        textTransform: 'capitalize',

    }

    return(
        <>
        <CustomNavbar />
        <div className="wrapper">
            <div className="main">
                <div className="space-100" />
                <section className="section dashboard-sec">
                    <Container>
                        <div className="page-title text-center">
                            <h1 className="main-title" style ={style}>Welcome to Dashboard</h1>
                            <Button onClick={handleLogout}>log out</Button>
                        </div>
                    </Container>
                </section>
                
            </div>
        </div>
        <CustomFooter/>
        </>
    )
}
const mapStateToProps = state => {
	return {
		auth_token: state.auth.token
	}
}
const mapDispatchToProps = dispatch => ({
	onLogoutAttempt: payload =>
		dispatch({ type: LOGOUT, payload }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);