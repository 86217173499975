import React from "react";

// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";

import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import {connect} from 'react-redux'
import BillingFundraising from "./billing-fundraising";
import Personalize from "./personalize";



class ShareYourStore extends React.Component {
  state = {
    hTabs: 'personalize',
    seletedCat: [],
  };
  componentDidMount = () => {
    // document.body.classList.add("page");
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // this.refs.wrapper.scrollTop = 0;

    // this.setState({
    //   hTabs: this.props.store_tab_ui
    // })
  }
  toggleTabs = (stateName, index) => {
    this.setState({
      [stateName]: index
    });
  };

  // componentWillReceiveProps = nextProps => {
  //   console.log(nextProps.store_tab_ui, this.props.store_tab_ui)
  //   if(nextProps.store_tab_ui !== this.props.store_tab_ui){
  //     this.setState({
  //       hTabs: nextProps.store_tab_ui
  //     })
  //   }
  // }
  componentWillUnmount() {
    document.body.classList.remove("page");
  }

  

  render() {
    return (
      <>
        <Row>
          <div className="share-your-store-header-container">
              <Col md="12 text-center share-your-store-header">
                <Button  
                    className="btn-round ml-1 btn d-flex create-store-back-btn"
                    //onClick ={() => this.props.closeAccrodian(3)}
                    onClick ={()=>this.props.tabToggle('hTabs','addLogo')}
                >   <i className="fas fa-arrow-left"></i>
                    back
                </Button>
                  <h1 className="title"><span>Share Your </span><br/><span>Store</span></h1>
              </Col>
              
          </div>
        </Row>
        <section className="section store-sec">
            <Row className="align-items-md-center justify-content-md-center">
              <Col md="12">
                <Nav
                  className="nav-pills-primary justify-content-center share-your-step-main"
                  pills
                  role="tablist"
                >
                  <NavItem className="step-item first">
                    <NavLink
                      className={classnames({
                        active: this.state.hTabs === 'personalize',
                      })}
                    >
                      <div className="step-item-tag">1.</div>
                      <span>Personalize</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="step-item">
                    <NavLink
                      className={classnames({
                        active: this.state.hTabs === 'billing',
                      })}
                    >
                    <div className="step-item-tag">2.</div>
                      <span>Billing and Address</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  className="tab-space"
                  activeTab={"hTabs-" + this.state.hTabs}
                >
                  <TabPane tabId="hTabs-personalize">

                      <Personalize tabToggle ={this.toggleTabs}/>
                    
                  </TabPane>
                  
                  <TabPane tabId="hTabs-billing">

                      <BillingFundraising tabToggle ={this.toggleTabs}/>
                    
                  </TabPane>

                </TabContent>
              </Col>
            </Row>
        </section>
      </>
    );
  }
}

export default ShareYourStore;
