import React from "react";
import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import agent from '../../services/api-endpoint'

class Career extends React.Component {
    
    componentDidMount() {
        document.body.classList.add("page");
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.wrapper.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.remove("page");
    }

    render(){
        const {site_slug} = agent;
        return (
            <>
            <CustomNavbar />

            <div className="wrapper" ref="wrapper">
                <div className ="main">
                    <div className="space-100" />
                    <section className="all-pages Careers-page section">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="all-pages-header">
                                        <h1><span>Careers</span></h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="carrer-sec-row">
                                <Col md="6">
                                    <div className="carrer-left-image">
                                      <img 
                                        alt="image"
                                        src={require("assets/img/campusCareer.jpg")}
                                      />
                                    </div>
                                </Col>
                                <Col md="6" style ={{alignSelf: 'center'}}>
                                    <div className="fund-raising-desc">
                                        <h3 className="title">Come Join us</h3>
                                        <p className="desc">There are jobs- and then there are careers, The chance to do the kind of work that adds up to something meaningful. The opportunity to challenge yourself and learn new skill. The prospect of being surrounded by smart, ambitious, motivated people, day in day out. Thats the kind of work you can expect to do. sound appealing ? Come Join us. </p>
                                        <p className="desc red-text">No current openings at this time</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </div>

            <CustomFooter />
            </>
        )
    }
            
}

export default Career;