import React, {useState} from "react";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Container,
    Col,
    NavLink,
    Row,
    FormGroup,
    Label,
    Input,
    FormText
} from "reactstrap";

const NewCustomerAccount = props => {

    const handleForm = () => {
        props.handleFormChange('showRegistrationForm', !props.showRegistrationForm)
    }

    return(
        <Card className="card-create-account auth-card animated fadeIn slow">
            <CardHeader className="text-center auth-card-header">
                <CardTitle tag="h4">New Customer </CardTitle>
            </CardHeader>
            <CardBody className="auth-card-body">
                <p className="sub-title">Creating an account has many benefits: check out faster, keep more than one address, track orders and more.</p>
            </CardBody>
            <CardFooter className="text-center auth-card-footer" style={{padding:'0 15px 15px' }}>
                    <Button
                        block
                        className="btn-round auth-btn"
                        color="primary"
                        onClick={handleForm}
                        size="lg"
                        
                    >
                        Create An Account
                    </Button>
                </CardFooter>
        </Card>
    )
}

export default NewCustomerAccount