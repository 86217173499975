import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col
} from "reactstrap";

import CategorySelection from "./category-selection";
import StyleSelection from "./style-selection/index.jsx";
//import LogoSelection from "./logo-selection/index.js";



class SelectGear extends React.Component {
  state = {
    collapse: 1
  };
  openCollapse = collapse => {
    this.setState({
      collapse: collapse === 2 ? 2 : collapse + 1
    });
  };
  closeCollapse = collapse => {
    this.setState({
      collapse: collapse === 1 ? 1 : collapse - 1
    });
  };


  render() {
    return (
      <>
        <div className="cd-section" id="select-gear-accordion">
          <div className="custom-accordion">
              <Row>
                <Col className="ml-auto" md="12">
                  <div className="accordion">
                    <Card>
                      <CardHeader>
                        <h1 className="mb-0">
                          <Button
                            className="w-100 text-left"
                            color="link"
                            aria-expanded={this.state.collapse === 1}
                            //onClick={() => this.openCollapse(1)}
                          >
                            <p className="acrrodion-custom-title"><span>Select Your </span> <span> Category</span></p>
                            <i className="tim-icons icon-minimal-down float-right" />
                          </Button>
                        </h1>
                      </CardHeader>
                      <Collapse isOpen={this.state.collapse === 1}>
                        <CardBody>
                            <CategorySelection 
                              openAccordion = {this.openCollapse} 
                            />
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card>
                      <CardHeader>
                        <h1 className="mb-0">
                          <Button
                            className="w-100 text-left "
                            color="link"
                            aria-expanded={this.state.collapse === 2}
                            //onClick={() => this.openCollapse(2)}
                          >
                            <p className="acrrodion-custom-title">
                              <span>Select Your </span><span>Product</span>
                            </p>
                            <i className="tim-icons icon-minimal-down float-right" />
                          </Button>
                        </h1>
                      </CardHeader>
                      <Collapse isOpen={this.state.collapse === 2}>
                        <CardBody>
                          {
                            this.state.collapse === 2 && 
                            <StyleSelection 
                              openAccordion = {this.openCollapse} 
                              closeAccrodian ={this.closeCollapse} 
                              tabToggle ={this.props.tabToggle}
                            />
                          }
                        </CardBody>
                      </Collapse>
                    </Card>
                    {/* <Card>
                      <CardHeader>
                        <h1 className="mb-0">
                          <Button
                            className="w-100 text-left "
                            color="link"
                            aria-expanded={this.state.collapse === 3}
                            //onClick={() => this.openCollapse(3)}
                          >
                            <p className="acrrodion-custom-title">
                              <span>Select Your </span><span>Logo</span>
                            </p>
                            <i className="tim-icons icon-minimal-down float-right" />
                          </Button>
                        </h1>
                      </CardHeader>
                      <Collapse isOpen={this.state.collapse === 3}>
                        <CardBody>
                          <LogoSelection openAccordion = {this.openCollapse} closeAccrodian ={this.closeCollapse} />
                        </CardBody>
                      </Collapse>
                    </Card> */}
                  </div>
                </Col>
              </Row>
          </div>
        </div>
      </>
    );
  }
}

export default SelectGear;
