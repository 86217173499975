import React from "react";
import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import { Container, Row, Col } from "reactstrap";

class AboutUs extends React.Component {
    
    componentDidMount() {
        document.body.classList.add("page");
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.wrapper.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.remove("page");
    }

    render(){
        return (
            <>
            <CustomNavbar />

            <div className="wrapper" ref="wrapper">
                <div className ="main">
                    <div className="space-100" />
                    <section className="all-pages aboutus-page section">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="all-pages-header">
                                        <h1><span>About</span> <span>Us</span></h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="about-us-row">
                                <Col md="4">
                                    <div className="about-left-image">
                                      <img 
                                        alt="image"
                                        src={require("assets/img/mcAboutUs.jpg")}
                                      />
                                    </div>
                                </Col>
                                <Col md="8" style ={{alignSelf: 'center'}}>
                                    <div className="about-us-page-desc">
                                        <p className="desc">Cultivated by an avid entrepreneur always looking for a better <q>mousetrap</q>, CampusWearDirect has been a small privately held business for over 20 years. CampusWearDirect is a small privately held family company whose goal is to offer the largest selection of value priced custom merchandise available.</p>
                                        <p className="desc">CampusWearDirect provides one of the largest selections of active and performance merchandise in the business. We strive not only to provide the newest looks and trends, but to back them up with the durability and performance you demand.</p>
                                        <p className="desc">Our mission is to provide the most unique mix of products and services available. We believe the variety of our products, the functionality of our website, and the commitment of superior customer service sets us apart. We value and appreciate your business and we will do our best to continue to exceed your expectations.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </div>

            <CustomFooter />
            </>
        )
    }
            
}

export default AboutUs;