import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TreeItem from '@material-ui/lab/TreeItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


class LogoImageFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: ["1"],
            selected: ["1"],
            // checked: true,
            styles: [
                {label: "Left", value: "left"},
                {label: "Right", value: "right"},
            ],
        }
    }

    handleToggle = (event, nodeIds) => {
        this.setState({
            expanded: nodeIds
        })
    }
    handleSelect = (event, nodeIds) => {
        this.setState({
            selected: nodeIds
        })
    }
    handleChange = (event, item) => {
        this.props.handleSelect(item, "logo_image");
    };

    render() {

        return (
            <div className="logo-image-filter-sec">
                <TreeView
                    defaultCollapseIcon={<RemoveIcon />}
                    defaultExpandIcon={<AddIcon />}
                    expanded={this.state.expanded}
                    selected={this.state.selected}
                    onNodeToggle={this.handleToggle}
                    onNodeSelect={this.handleSelect}
                >
                    <TreeItem nodeId="1" label="Logo Image">
                        {
                            this.state.styles.map((item, index) => 
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            color="primary" 
                                            value={item.value} 
                                            name="style" 
                                            checked={this.props.selected.some(v => v.value === item.value)}
                                            onChange={e => this.handleChange(e, item)} 
                                        />
                                    }
                                    label={item.label}
                                    key={index}
                                    style={{width: '100%'}}
                                />
                            )
                        }
                        {/* <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="Yes"
                        />
                        <FormControlLabel
                            control={<Checkbox color="primary" name="checkedA" checked={this.checked}
                                onChange={this.handleChange} />}
                            label="No"
                        /> */}
                    </TreeItem>
                </TreeView>
            </div>
        )
    }
}

export default LogoImageFilter;