import classnames from "classnames";
import React, { Component } from 'react';
// reactstrap components
import {
    Button, Card,
    CardBody, CardHeader, Col,
    Nav,
    NavItem,
    NavLink, Row, TabContent,
    TabPane
} from "reactstrap";
import PersonalizeIcon from '../../../../assets/img/badge.png';
import designIcon from '../../../../assets/img/logo-design.png';
import DesignSection from './design-sec/index.js';
import PersonalizeSection from './personalize-sec/index';


class LogoDesign extends Component {
    state = {
        iconTabs: 1
    };
    
    toggleTabs = (e, stateName, index) => {
        e.preventDefault();
        this.setState({
            [stateName]: index
        });
    };
    
    render() {
        return (
            <>
            <Row>
                <Col md="12">
                    <Button 
                        className ="back-store-info"
                        onClick ={this.props.handleShowInfoCard}
                    >
                    <i className="fas fa-arrow-left" />
                    Store details</Button>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <div className="selected-image-main-sec">
                        <div className="selected-image-sec sku-container" style={{'textAlign': 'center', 'position': 'relative'}}>
                            <img 
                                // src ={require("assets/img/shirt.png")} 
                                src ={require("assets/img/svg-logos/sku-img.png")} 
                                alt="..." 
                                className="img-round"
                                id="sku-image"
                            />
                            <div id='overlay' style={{
                                position: 'absolute',
                                display: 'none',
                                left: "40.5%",
                                width: "20%",
                            }} ></div>
                        </div>
                    </div>
                </Col>
                <Col md="6">
                    <div className="logo-tab-section">
                        <Card>
                            <CardHeader>
                                <Nav className="nav-tabs-info logo-design-info-tab" role="tablist" tabs>
                                    <NavItem>
                                    <NavLink
                                        className={classnames({
                                        active: this.state.iconTabs === 1
                                        })}
                                        //onClick={e => this.toggleTabs(e, "iconTabs", 1)}
                                    >
                                    <img src ={PersonalizeIcon} alt = "..." />
                                        <span>Designs</span>
                                    </NavLink>
                                    </NavItem>
                                    <NavItem>
                                    <NavLink
                                        className={classnames({
                                        active: this.state.iconTabs === 2
                                        })}
                                        //onClick={e => this.toggleTabs(e, "iconTabs", 2)}
                                    >
                                        <img src ={designIcon} alt = "..." />
                                        <span>Personalize</span>
                                    </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody>
                                <TabContent
                                    className="logo-design-content"
                                    activeTab={"link" + this.state.iconTabs}
                                >
                                    <TabPane tabId="link1">
                                        <DesignSection toggleTabs={this.toggleTabs} />
                                    </TabPane>
                                    <TabPane tabId="link2">
                                        <PersonalizeSection toggleTabs={this.toggleTabs} />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
            </>
        )
    }
}

export default LogoDesign