import tshirt from '../../assets/img/category/t-shirt.jpg'
import fleece from '../../assets/img/category/Fleece.jpg'
import headwear from '../../assets/img/category/Headwear.jpg'
import Athletics from '../../assets/img/category/Athletics.jpg'
import Sportsshirt from '../../assets/img/category/sports-shirt.jpg'
import Accessories from '../../assets/img/category/accessories.jpg'
import tshirtLongsleeve from '../../assets/img/category/tshirt-longsleeve.jpg'
import outwear from '../../assets/img/category/outwear.jpg'
import bags from '../../assets/img/category/bags.jpg'
import wovens from '../../assets/img/category/wovens.jpg'
import headwearWinter from '../../assets/img/category/headwear-winter.jpg'
import workwear from '../../assets/img/category/workwear.jpg'
import wovensFall from '../../assets/img/category/wovens-fall.jpg'


const axios = require('axios')
const local_server = 'http://localhost:8000/api'
const server_dev = 'https://api.wikimesh.com/api'
const lan_server = 'http://192.168.1.17:8000/api'


axios.defaults.headers.post['Content-Type'] = 'application/json';

const api_service_log = {
  email: 'admin@gmail.com',
  password: '12345678'
  // email: process.env.REACT_APP_API_CRED_EMAIL,
  // password: process.env.REACT_APP_API_CRED_PASS
};

// static images for basecategory
const basecategoryImg = {
  'T-Shirts': tshirt,
  'Fleece': fleece,
  'Headwear': headwear,
  'Athletics': Athletics,
  'Sport Shirts': Sportsshirt,
  'Accessories': Accessories,
  'T-Shirts - Long Sleeve': tshirtLongsleeve,
  'Outerwear': outwear,
  'Bags': bags,
  'Wovens': wovens,
  'Headwear - Winter': headwearWinter,
  'Workwear': workwear,
  'Wovens - Fall': wovensFall,
}

const site_slug = {
  HOME: '/',
  // LOGIN: '/login',
  SIGN_OUT: '/sign-out',
  // STORE_OWNER_SIGN_UP: '/store-owner/register',
  CUSTOMER_SIGN_UP: '/customer/login',
  FORGET_PASSWORD: '/forget-password',
  CHANGE_PASSWORD: '/change-password',
  CREATE_STORE: '/create-store',
  // ADMIN_PORTAL: '/admin-portal',
  // STORE_OWNER_PORTAL: '/store-owner-portal',
  CUSTOMER_PORTAL: '/customer-portal',
}

const responseMsg = {
  registration: 'You are successfully registered..',
  login_otp_success: 'OTP is sent to your email. Please check it to login',
  login_otp_error: 'Sorry, there is something wrong to send otp in your email. Please try again.',
  login_success: 'You are successfully logged In.',
  login_fail: 'Sorry, Invalid credentials.'
}

const redirectUser = {
  auth: {
    // 'admin' : site_slug.ADMIN_PORTAL,
    // 'storeOwner' : site_slug.STORE_OWNER_PORTAL,
    'customer' : site_slug.CUSTOMER_PORTAL,
  },
  guest: site_slug.CUSTOMER_SIGN_UP
}


let token = null
const setToken = _token => { token = _token }


let settings = {
  ssactiveware_root_url: 'https://www.ssactivewear.com',
}

// const root_server = process.env.REACT_APP_API_URI;
const root_server = server_dev;
// const root_server = lan_server;



export const endpoint = {
  get: function (url, config = {}) {
    return axios.get(`${root_server}/${url}`, config)
      .then(res => res.data)
      .catch(err => err.response.data)
  },
  post: function (url, data = {}, config = {}) {
    return axios.post(`${root_server}/${url}`, data, config)
      .then(res => res.data)
      .catch(err => err.response.data)
  },
  put: function (url, data = {}, config = {}) {
    return axios.put(`${root_server}/${url}`, data, config)
      .then(res => res.data)
      .catch(err => err.response.data)
  },
  delete: function (url, config) {
    return axios.delete(`${root_server}/${url}`, config)
      .then(res => res.data)
      .catch(err => err.response.data)
  },
}

const Auth = {
  login: data =>
    endpoint.post('customer/login', data),
  verify_otp: data =>
    endpoint.post('verifyOTP', data),
  registration: data =>
    endpoint.post('customer/register', data),
  logout: auth_token =>
    endpoint.post('logout', {}, { headers: { Authorization: `Bearer ${auth_token}` } }),
  send_email_for_reset_password: data =>
    endpoint.post('resetPasswordEmail', data),
  change_password: (data, auth_token) =>
    endpoint.post('passwordChange', data, { headers: { Authorization: `Bearer ${auth_token}` } }),
  change_old_password: (data, auth_token) =>
    endpoint.post('oldPasswordChange', data, { headers: { Authorization: `Bearer ${auth_token}` } }),
  update_profile: (data, auth_token) =>
    endpoint.put('profileUpdate', data, { headers: { Authorization: `Bearer ${auth_token}` } })
};

const StoreOwner = {
  // register: data => 
  //   endpoint.post('storeOwner/register', data),
}

const Profile = {
  addShippingAddress: (data, token) => 
    endpoint.post('createShippingAddress', data, { headers: { Authorization: `Bearer ${token}` } }),
  updateShippingAddress: (data, token) => 
    endpoint.post('createShippingAddress', data, { headers: { Authorization: `Bearer ${token}` } }),
  deleteShippingAddress: (id, token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    }
    let slug = `id=${id}`
    return endpoint.delete(`deleteShippingAddress?${slug}`, config)
  }
}

const Category = {
  getCategories: (param) => {
    let url = 'ssactivewear/categories',
      query_str = '';
    param.forEach(obj => {
      if (obj.in === 'query')
        query_str += `${obj.key}=${obj.value}&&`;
    });
    query_str = query_str.replace(/&&+$/, '');
    if (query_str) url += `?${query_str}`;
    return endpoint.get(url, { headers: { Authorization: `Bearer ${token}` } })
  },
};

const Style = {
  getStyles: param => {
    let url = 'ssactivewear/styles',
      query_str = '';
    param.forEach(obj => {
      if (obj.in === 'query')
        query_str += `${obj.key}=${obj.value}&&`;
    });
    query_str = query_str.replace(/&&+$/, '');
    if (query_str) url += `?${query_str}`;
    return endpoint.get(url, { headers: { Authorization: `Bearer ${token}` } })
  },
  getStylesByGroup: () => 
    endpoint.get('ssactivewear/stylesGroupbyBasecategory'),
  // getStylesByGroup: () => 
  //   endpoint.get('ssactivewear/stylesGroupbyBasecategory', {headers: { Authorization: `Bearer ${token}` }}),
  // getStylesByGroup: () => {
  //   endpoint.get('ssactivewear/stylesGroupbyBasecategory').then(data => {
  //     console.log(data)
  //     return data
  //   })
  // }
}

const Product = {
  getProductByStyle: param => {
    let url = 'ssactivewear/products',
      query_str = '';
    param.forEach(obj => {
      if (obj.in === 'query')
        query_str += `${obj.key}=${obj.value}&&`;
    });
    query_str = query_str.replace(/&&+$/, '');
    if (query_str) url += `?${query_str}`;
    return endpoint.get(url, { headers: { Authorization: `Bearer ${token}` } })
  }
}



const LogoGenerator = {
  getImgTag: data =>
    endpoint.post('cloudinary', data),
}



const filterBy = (products, filters) => {
  const filterKeys = Object.keys(filters);
  return products.filter(product => {
    return filterKeys.every(key => {
      if (!filters[key].length) return true;
      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some(keyEle => filters[key].includes(keyEle));
      }
      return filters[key].includes(product[key]);
    });
  });
};

const sortArrayOfObjectByProperty = (items, sortKey) => {
  switch (sortKey) {
    case 'alphabet':
      return items.sort((a, b) => (a.title > b.title) ? 1 : -1)
    case 'price':
      return items.sort((a, b) => (parseInt(a.final_price) > parseInt(b.final_price)) ? 1 : -1)
    case 'category':
      return items.sort((a, b) => (a.baseCategory > b.baseCategory) ? 1 : -1)
    case 'brand':
      return items.sort((a, b) => (a.brandName > b.brandName) ? 1 : -1)
    default:
      return items.sort((a, b) => (a.title > b.title) ? 1 : -1)
  }
}




export default {
  endpoint,
  Auth,
  StoreOwner,
  Category,
  Style,
  Product,
  settings,
  LogoGenerator,
  basecategoryImg,
  setToken,
  site_slug,
  filterBy,
  sortArrayOfObjectByProperty,
  responseMsg,
  Profile,
  redirectUser
};