import { combineReducers } from 'redux';
import BaseSelectionReducer from './baseSelection';
import AddLogoReducer from './addLogo';
import AuthReducer from './authReducer';
import CommonReducer from './common';
import NotificationReducer from './notification';
import TempReducer from './tempReducer'


const rootReducer = combineReducers({
    baseSelection: BaseSelectionReducer,
    addLogo: AddLogoReducer,
    auth: AuthReducer,
    temp: TempReducer,
    common: CommonReducer,
    notification: NotificationReducer
});

export default rootReducer;