import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
    Button, Col, Row
} from "reactstrap";
// import { ChromePicker } from 'react-color'
// import UploadMascotModal from './upload-mascot-modal.js';
import UploadMascot from '../personalize-sec/upload-mascot';
import PersonalizeColorPicker from './personalize-color-picker.js';

import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import action_middleware from '../../../../../redux/action-creator'
import {
    EDIT_LOGO_DETAILS
} from '../../../../../redux/actions/actionTypes'


import { ColorPicker, ColorBox } from 'material-ui-color';



class PersonalizeSection extends React.Component {
    state = {
        showMascotModal: false,
        mascot: '',
        color: '#000',
        showColorBox: false
    }
    toggleMascot= () => {
        this.setState({
            showMascotModal: !this.state.showMascotModal
        });
    }
    handleColorChange = (value, key) => {
        // let regx = /^#([0-9A-F]{3}){1,2}$/i
        // console.log(regx.test(value))
        const colors = Object.assign( {}, this.props.colors ) 
        colors[key].value = value
        this.props.action_middleware(EDIT_LOGO_DETAILS, {key: 'colors', value: colors})
    }
    handleTextChange = (value, index) => {
        // console.log(value, index)
        // const texts = [].concat(this.props.texts)
        const texts = [...this.props.texts]
        texts[index] = value
        // this.setState({ texts })
        this.props.action_middleware(EDIT_LOGO_DETAILS, {key: 'texts', value: texts})
    }
    
    /**Utility function for changing the color */
    setSVGColor = (target1, target2, color) => {
        const fill = document.querySelectorAll(target1)
        for (var i = 0; i < fill.length; i++)
            fill[i].style.fill = color
        const stroke = document.querySelectorAll(target2)
        for (var i = 0; i < stroke.length; i++)
            stroke[i].style.stroke = color
    }

    /**Apply text changes to the selected logo placed on the SKU*/
    setSVGText = () => {
        const svg = document.querySelectorAll("#overlay svg")[0]
        const p = Number(svg.getBoundingClientRect().width) //width of logo placed on SKU in pixels
        const v = Number(svg.getAttribute('viewBox').split(" ")[2]) //width of logo placed on SKU in viewbox units

        //Considering there will be at most three editable text in a logo
        for (var i = 1; i <= 3; i++) {
            try {
                const text = document.querySelectorAll(".sku-container [data-text='" + i + "']")
                for (var j = 0; j < text.length; j++) {
                    const x1 = Number(text[j].getAttribute('x')) // prev x pos in viewbox unit
                    const w1 = Number(text[j].getBoundingClientRect().width) // prev width in pixels
                    text[j].innerHTML = this.props.texts[i - 1]     // getting values from props
                    const w2 = Number(text[j].getBoundingClientRect().width) // new width in pixels
                    const x2 = (2 * p * x1 + (w1 - w2) * v) / (p * 2) // new x pos in viewbox units
                    text[j].setAttribute('x', x2)
                }
            } catch {}
        }
    }


    /**Apply changes to the selected logo which is placed on the SKU */
    changeSVG = (e) => {
        e.preventDefault()
        const {colors, texts, selected_div} = this.props
        try {
            //reset the logo to prevent text alignment issues
            const svg = document.getElementById(selected_div).innerHTML
            document.getElementById('overlay').innerHTML = svg


            //set primary color
            this.setSVGColor(".sku-container [color-primary='fill']",
                ".sku-container [color-primary='stroke']",
                colors.primaryColor.value)
            //set secondary color
            this.setSVGColor(".sku-container [color-secondary='fill']",
                ".sku-container [color-secondary='stroke']",
                colors.secondaryColor.value)
            //set tertiary color
            this.setSVGColor(".sku-container [color-tertiary='fill']",
                ".sku-container [color-tertiary='stroke']",
                colors.tertiaryColor.value)


            //set text
            this.setSVGText()


            //change mascot if the logo supports it and if one was uploaded
            if (this.state.mascot !== '') {
                try {
                    document.querySelectorAll('#overlay [data-mascot="original"]')[0].style.display = 'none'
                    document.querySelector("#overlay image").setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', this.state.mascot);
                } catch {}
            }
        } catch {}
    }

    /**Convert uploaded mascot to base64 image and preview it*/
    previewFile = (e) => {
        console.log(e.target.files)
        // const preview = document.querySelector('#mascot');
        // const file = document.querySelector('input[type=file]').files[0];
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.addEventListener("load", async () => {
            // convert image file to base64 string
            // preview.src = reader.result
            await this.setState({ mascot: reader.result })
            // console.log(this.state.mascot)
            // preview.style.display = 'inline-block'
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    /**Remove user added mascot from the selected logo and use the default mascot */
    resetMascot = () => {
        try {
            document.querySelectorAll('#overlay [data-mascot="original"]')[0].style.display = 'block'
            document.querySelector("#overlay image").setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '');
            this.setState({mascot: ''})
        } catch {}
    }

    render() {

        const {colors, texts, showUploadMascot} = this.props

        return (
            <div className="personalize-main-section">
                <Row>
                    <Col md="12"> 
                        <Button 
                            className="prev-design"
                            onClick={(e)=>this.props.toggleTabs(e,'iconTabs',1)}
                        >
                            <i className="fas fa-arrow-left"></i>
                            Previous
                        </Button>
                    </Col>
                </Row>

                <Row className="personalize-grid-section-row">
                
                    <div className="colors-section">
                        {
                            Object.keys(colors || []).map((key) => {
                                return (
                                    (colors[key].value !== false) ?
                                        <div className="col-6" key={key}>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item sm={12}>
                                                    {/* <TextField
                                                        id={key}
                                                        label={colors[key].title}
                                                        placeholder= {colors[key].title}
                                                        type="text"
                                                        fullWidth
                                                        name={key} 
                                                        value={colors[key].value}
                                                        onChange={e => this.handleColorChange(e.target.value, key)}
                                                        InputProps={{
                                                            startAdornment: 
                                                                <InputAdornment position="start">
                                                                    <div className="colors-background" style ={{background: colors[key].value }}>
                                                                    </div>
                                                                </InputAdornment>,
                                                            endAdornment: 
                                                                <InputAdornment position="end">
                                                                    <i 
                                                                        className="tim-icons icon-pencil"
                                                                        style ={{cursor: 'pointer', color: '#ffae00'}}
                                                                        onClick={prev =>this.setState({
                                                                            showColorBox: !prev.showColorBox
                                                                        })}
                                                                    ></i>
                                                                </InputAdornment>,
                                                        }}
                                                    /> */}

                                                    <div style={{display: "flex", borderBottom: "1px solid #000"}}>
                                                        <ColorPicker 
                                                            hideTextfield={true}
                                                            name={key} 
                                                            value={colors[key].value}  
                                                            onChange={value => this.handleColorChange(`#${value.hex}`, key)}
                                                        />
                                                        <input
                                                            type="text" 
                                                            value={colors[key].value}
                                                            //disabled={true}
                                                            style={{ border: "none", backgroundColor: "unset"}}
                                                            onChange={e => this.handleColorChange(e.target.value, key)}
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div> : null
                                )
                            })
                        }
                    </div>

                    <div className="text-section">
                        {
                            (texts || []).map((text, index) => {
                                return (
                                    (texts[index] !== false) ?
                                        <div className="col-6" key={index}>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item sm={12}>
                                                    <TextField
                                                        id={`Text ${index + 1}`}
                                                        label={`Text ${index + 1}`}
                                                        placeholder= {`Text ${index + 1}`}
                                                        type="text"
                                                        fullWidth
                                                        name="personalize_name"
                                                        value={text}
                                                        onChange={e => {
                                                            this.handleTextChange(e.target.value, index)
                                                        }} 
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div> : null
                                )
                            })
                        }
                    </div>
                    <div className="button-section">
                        <div className="col-12">
                            <div>
                                <div className="mascot-upload-img-sec">
                                    {
                                        this.state.mascot !== '' &&
                                        <>
                                        <img 
                                            className='img-fluid' 
                                            id='mascot' 
                                            src={this.state.mascot}
                                            // style={{ display: 'none' }} 
                                            alt="Image preview..."
                                        />
                                        <button onClick={(e) => {
                                            e.preventDefault()
                                            this.resetMascot()
                                        }} className="btn btn-danger mt-1">
                                            <i className="tim-icons icon-simple-remove"></i>
                                        </button>
                                        </>
                                    }
                                </div>
                            </div>
                            <button 
                                onClick={this.changeSVG} 
                                className="btn btn-preview"
                            >Preview</button>
                            {
                                // !this.state.mascot && 
                                this.props.showUploadMascot && 
                                (<div>
                                    <label>
                                        <input 
                                            onChange={this.previewFile} 
                                            type="file" 
                                            style={{opacity: "1"}} 
                                            className="custom-file-input"
                                            id = "upload_mascot" 
                                        />
                                        <span className="btn mascot-upload-btn">
                                            Upload Mascot
                                        </span>
                                    </label>
                                </div>) 
                            }
                        </div>
                    </div>
                    
                </Row>

                {/* <Row className="personalize-grid-section-row">
                    <Col md="7">
                        <div className="personalize-grid-section-text">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        id="personalize_school"
                                        label="School"
                                        placeholder="School"
                                        type="text"
                                        fullWidth
                                        name="personalize_school"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Col>
                    <Col md="5">
                        <div className="personalize-grid-section-color">
                            <div className="color-picker-text-section">
                                <span className="select-color-heading">Font color</span>
                            </div>
                            <div className="color-picker-section">
                                <PersonalizeColorPicker />
                            </div>
                        </div>
                        <div className="personalize-grid-section-color">
                            <div className="color-picker-text-section">
                                <span className="select-color-heading">Background</span>
                            </div>
                            <div className="color-picker-section">
                                <PersonalizeColorPicker />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="personalize-grid-section-row">
                    <Col md="7">
                        <div className="personalize-grid-section-text">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        id="personalize_name"
                                        label="Name"
                                        placeholder="Name"
                                        type="text"
                                        fullWidth
                                        name="personalize_name"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Col>
                    <Col md="5">
                        <div className="personalize-grid-section-color">
                            <div className="color-picker-text-section">
                                <span className="select-color-heading">Font color</span>
                            </div>
                            <div className="color-picker-section">
                                <PersonalizeColorPicker />
                            </div>
                        </div>
                        <div className="personalize-grid-section-color">
                            <div className="color-picker-text-section">
                                <span className="select-color-heading">Background</span>
                            </div>
                            <div className="color-picker-section">
                                <PersonalizeColorPicker />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="personalize-grid-section-row">
                    <Col md="7">
                        <div className="personalize-grid-section-text">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        id="personalize_year"
                                        label="Year"
                                        placeholder="Year"
                                        type="number"
                                        fullWidth
                                        name="personalize_year"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Col>
                    <Col md="5">
                        <div className="personalize-grid-section-color">
                            <div className="color-picker-text-section">
                                <span className="select-color-heading">Font color</span>
                            </div>
                            <div className="color-picker-section">
                                <PersonalizeColorPicker />
                            </div>
                        </div>
                        <div className="personalize-grid-section-color">
                            <div className="color-picker-text-section">
                                <span className="select-color-heading">Background</span>
                            </div>
                            <div className="color-picker-section">
                                <PersonalizeColorPicker />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="personalize-grid-section-row">
                    <Col md="7">
                        <div className="personalize-grid-section-text">
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item sm={12}>
                                    <TextField
                                        id="personalize_mascot_name"
                                        label="Mascot Name"
                                        placeholder="Mascot Name"
                                        type="text"
                                        fullWidth
                                        name="personalize_mascot_name"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Col>
                    <Col md="5">
                        <div className="personalize-grid-section-color">
                            <div className="color-picker-text-section">
                                <span className="select-color-heading">Font color</span>
                            </div>
                            <div className="color-picker-section">
                                <PersonalizeColorPicker />
                            </div>
                        </div>
                        <div className="personalize-grid-section-color">
                            <div className="color-picker-text-section">
                                <span className="select-color-heading">Background</span>
                            </div>
                            <div className="color-picker-section">
                                <PersonalizeColorPicker />
                            </div>
                        </div>
                    </Col>
                </Row> */}
                
                {/* <Row>
                    <Col md="12" className="personalization-mascot-upload">
                        <Button 
                            className="mascot-upload-btn" 
                            onClick={this.toggleMascot}
                        > Upload Maskot
                        </Button>
                    </Col>
                </Row> */}

                {/**Mascot */}
                {/* <h3 className='text-center'>Mascot</h3>
                <hr />
                <div className="form-group text-center">
                    Upload Mascot: 
                    <input 
                        onChange={this.previewFile} 
                        type="file" 
                        style={{opacity: "1"}} 
                        className="custom-file-input" 
                    />
                </div>
                <div className="text-center">
                    {
                        this.state.mascot !== '' &&
                        <>
                        <img 
                            className='img-fluid' 
                            id='mascot' 
                            src={this.state.mascot}
                            // style={{ display: 'none' }} 
                            alt="Image preview..."
                        />
                        <button onClick={(e) => {
                            e.preventDefault()
                            this.resetMascot()
                        }} className="btn btn-danger mt-1">
                            Remove Mascot
                        </button>
                        </>
                    }
                </div> */}
                {/**Mascot */}
                
                {/* <UploadMascot 
                    showMascotModal={this.state.showMascotModal} 
                    toggleMascot={this.toggleMascot}
                    modalClass={this.props.className}
                /> */}
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
		texts: state.addLogo.texts,
        colors: state.addLogo.colors,
        selected_div: state.addLogo.selected_div,
        showUploadMascot: state.addLogo.showUploadMascot
	}
}
const mapDispatchToProps = dispatch => ({
    action_middleware: bindActionCreators(action_middleware, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(PersonalizeSection)