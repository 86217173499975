import React, { Component } from 'react';
import { Row, Col, Button} from "reactstrap";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import ImageUpload from 'components/CustomUpload/ImageUpload'


export class Personalize extends Component {
    render() {
        return (
            <section>
                <Row className = "billing-fundraising-main-section personalization-header-section"> 
                    <div className="billing-header">
                        <Col md="12 text-center billing-right">
                            <h2 className="title">
                                <span>Personalize your</span> <span>Store!</span>
                            </h2>
                        </Col>
                    </div>
                </Row>
                <Row className="personalize-row">
                    <Col md="12">
                        <div className="personalize-content">
                            <div className="personalize-card-content">
                                <Row>
                                    <Col md="12">
                                        <div className="personalize-content-title">
                                            <h2 className="title">Personalize your store with your Logo</h2>
                                        </div>
                                    </Col>
                                    <Col md="8">
                                        <div className="personalize-info">
                                            <p className="sub-title">Personalize your store with your own Logo. Please upload your School/Camp/Business Logo which will be visible on top of all pages of your store.</p>
                                            <p className="sub-title">Supported formats are jpg, png. It should be sized upto 300px X 300px . And should not be larger than 2 MB.</p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <ImageUpload />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="personalize-content">
                            <div className="personalize-card-content">
                                <Row>
                                    <Col md="12">
                                        <div className="personalize-content-title">
                                            <h2 className="title">Domain</h2>
                                        </div>
                                        <div className="personalize-info">
                                            <p className="sub-title">This is going to be your personal store domain. You can modify it here and get it if its not already in use.</p>
                                        </div>
                                        <div className="domain-name-sec">
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item sm={1}>
                                                    <p className="sub-title">http://</p>
                                                </Grid>
                                                <Grid item sm={5}>
                                                    <TextField
                                                        id="domain_id"
                                                        label="Domain Name"
                                                        placeholder="Domain Name"
                                                        type="text"
                                                        fullWidth
                                                        name="domain_name"
                                                    />
                                                </Grid>
                                                <Grid item sm={6}>
                                                    <p className="sub-title">.campuswear.s.namvllc.com</p>
                                                </Grid>
                                            </Grid>
                                        </div>                                    
                                        <div className="domain-info-msg">
                                            <p className="sub-title">Check Domain Name Availablity!</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="personalize-content social-media-sec">
                            <div className="personalize-card-content">
                                <Row>
                                    <Col md="12">
                                        <div className="personalize-content-title">
                                            <h2 className="title">Social Media</h2>
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="personalize-info">
                                            <p className="sub-title">We will need your Social Media pages to publish on your stores.</p>
                                        </div>
                                    </Col>
                                    <Col md="10" sm="12">
                                        <div className="domain-name-sec">
                                            <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item sm={6}>
                                                <TextField
                                                    id="facebook-id"
                                                    label="Facebook URL"
                                                    placeholder="Facebook URL"
                                                    type="text"
                                                    fullWidth
                                                    name="facebook-url"
                                                />
                                            </Grid>
                                            <Grid item sm={6}>
                                                <TextField
                                                    id="instagram-id"
                                                    label="Instagram URL"
                                                    placeholder="Instagram URL"
                                                    type="text"
                                                    fullWidth
                                                    name="instagram-url"
                                                />
                                            </Grid>
                                            </Grid>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item sm={6}>
                                                    <TextField
                                                        id="twitter-id"
                                                        label="Twitter URL"
                                                        placeholder="Twitter URL"
                                                        type="text"
                                                        fullWidth
                                                        name="twitter-url"
                                                    />
                                                </Grid>
                                                <Grid item sm={6}>
                                                    <TextField
                                                        id="linkedin-id"
                                                        label="Linkedin URL"
                                                        placeholder="Linkedin URL"
                                                        type="text"
                                                        fullWidth
                                                        name="linkedin-url"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} alignItems="flex-end" className="youtube_url">
                                            <Grid item sm={6}>
                                                <TextField
                                                    id="youtube-id"
                                                    label="Youtube URL"
                                                    placeholder="Youtube URL"
                                                    type="text"
                                                    fullWidth
                                                    name="youtube-url"
                                                />
                                            </Grid>
                                        </Grid>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="personalize-content">
                            <div className="personalize-card-content">
                                <Row>
                                    <Col md="12">
                                        <div className="personalize-content-title">
                                            <h2 className="title">Banners for your store</h2>
                                        </div>
                                        <Row>
                                            <Col md="6">
                                                <div className="personalize-banner-container">
                                                    <ImageUpload />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="personalize-banner-container">
                                                    <ImageUpload />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className = "personalization-footer-section">
                    <Col md="12 text-right">
                        <Button  
                                className="btn-round ml-1 btn personalize-btn"
                                onClick ={()=>this.props.tabToggle('hTabs','billing')}
                            >
                                Save & Next
                                <i className="fas fa-arrow-right"></i> 
                        </Button>
                    </Col>
                </Row> 
            </section>
        );
    }
}

export default Personalize;
