import React, { Component } from 'react'
import {
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col,
    Label,
    Input,
    Button,
    Pagination, PaginationItem, PaginationLink
  } from "reactstrap";
import VerticalLinearStepper from './billing-main-form'

class BillingFundraising extends Component {
    render() {
        return (
            <section className="billing-fundraising-main-section">
                <Row>
                    <div className="billing-header">
                        <Col md="12 text-center billing-right">
                            <Button  
                                    className="btn-round ml-1 perosonalization-btn"
                                    onClick ={()=>this.props.tabToggle('hTabs','personalize')}
                                >
                                    <i className="fas fa-arrow-left"></i> 
                                    Personalization
                            </Button>
                            <h2 className="title">
                                <span>Complete your</span> <span>Store!</span>
                            </h2>
                        </Col>
                        
                        <Col md="12 text-center">
                            <p className="sub-title">Wow! Great job! You're almost done. Please Complete your store information below: </p>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col md="12">
                        <div className="billing-fundraising-form-section">
                            <VerticalLinearStepper/>
                        </div>
                    </Col>
                </Row>
            </section>
        )
    }
}

export default BillingFundraising
