
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
    UncontrolledCollapse,
    NavbarBrand,
    Navbar,
    NavItem,
    Nav,
    Container,
    Row,
    Col,
    NavLink
} from "reactstrap";
import agent from '../../services/api-endpoint'
import {connect} from 'react-redux'
import storeIcon from '../../assets/img/store_icon.png'
import myAccountIcon from '../../assets/img/account.png'
import faqIcon from '../../assets/img/faq.png';


class CustomNavbar extends React.Component {
    state = {
        navbarColor: "navbar-transparent",
        // isLoggedIn: false
    };
    componentDidMount() {
        window.addEventListener("scroll", this.changeNavbarColor);
    }
    componentWillUnmount() {
        window.removeEventListener("scroll", this.changeNavbarColor);
    }
    changeNavbarColor = () => {
        if (
            document.documentElement.scrollTop > 9 ||
            document.body.scrollTop > 9
        ) {
            this.setState({
                navbarColor: "bg-white"
            });
        } else if (
            document.documentElement.scrollTop < 10 ||
            document.body.scrollTop < 10
        ) {
            this.setState({
                navbarColor: "navbar-transparent"
            });
        }
    };
    render() {
        const {site_slug} = agent;
        // const isLoggedIn = this.state.isLoggedIn;
        return (
            <div className="custom-navbar">
                <Navbar className={"fixed-top campuswear-nav " + this.state.navbarColor} expand="lg">
                    <Container className="menu-content">
                        <div className="navbar-translate">
                            <NavbarBrand to="/" tag={Link}>
                                <img
                                    src={require("assets/img/campus_wear_logo.png")}
                                    className="logo"
                                    alt="..."
                                />
                            </NavbarBrand>
                            <button className="navbar-toggler" id="navigation">
                                <span className="navbar-toggler-bar bar1" />
                                <span className="navbar-toggler-bar bar2" />
                                <span className="navbar-toggler-bar bar3" />
                            </button>
                        </div>
                        <UncontrolledCollapse navbar toggler="#navigation">
                            <div className="navbar-collapse-header">
                                <Row>
                                    <Col className="collapse-brand" xs="6">
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            BLK• <span>PRO React</span>
                                        </a>
                                    </Col>
                                    <Col className="collapse-close text-right" xs="6">
                                        <button className="navbar-toggler" id="navigation">
                                            <i className="tim-icons icon-simple-remove" />
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            <Nav className="ml-auto" navbar>
                                <NavItem>
                                    <NavLink to="/faq" tag={Link}>
                                        {/* <i className="fas fa-question-circle" /> */}
                                        <img src={faqIcon} />
                                        FAQ
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink to={site_slug.STORE_OWNER_SIGN_UP} tag={Link}>
                                        <i className="fa fa-address-book" />
                                        REGISTER
                                    </NavLink>
                                </NavItem> */}
                                <NavItem>
                                    {   this.props.isLogged ?
                                        <NavLink to={this.props.reditectTo} tag={Link}>
                                            <i className="fa fa-user-circle" />
                                            My Account
                                        </NavLink> :
                                        <NavLink to={site_slug.CUSTOMER_SIGN_UP} tag={Link}>
                                            {/* <i className="fas fa-user" /> */}
                                            <img src={myAccountIcon} />
                                            My Account
                                        </NavLink>
                                        // <NavLink to={site_slug.LOGIN} tag={Link}>
                                        //     <i className="fa fa-lock" />
                                        //     LOGIN
                                        // </NavLink>
                                    }
                                </NavItem>
                                <NavItem>
                                    <NavLink className="build-store" to={site_slug.CREATE_STORE} tag={Link}>
                                        {/* <i className="fas fa-store" /> */}
                                        <img src={storeIcon} />
                                        BUILD YOUR STORE
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </UncontrolledCollapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}
const mapStateToProps = state => {
	return {
        isLogged: state.auth.isLogged,
        reditectTo: state.common.redirect_to
	}
}
// const mapDispatchToProps = dispatch => ({
// 	onLoginAttempt: payload =>
//         dispatch({ type: LOGIN, payload }),
//     clearNotification: payload =>
//         dispatch({ type: CLEAR_NOTIFICATION_MSG, payload }),
// });
export default connect(mapStateToProps, null)(CustomNavbar);
