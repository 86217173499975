
import React from "react";
import AppRoute from 'routes/index.js';


class App extends React.Component {
    render() {
        return (
            <AppRoute />
        );
    }
}

export default App;