import { Button } from "reactstrap";

import React from "react";
import TreeView from '@material-ui/lab/TreeView';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TreeItem from '@material-ui/lab/TreeItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isTemplateMiddle } from 'typescript';

import ColorPicker from "components/Custom/color-picker/index.jsx";

class ColorFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: [
                "#f44336",
                "#e91e63",
                "#9c27b0",
                "#673ab7",
                "#3f51b5",
                "#2196f3",
                "#03a9f4",
                "#00bcd4",
                "#009688",
                "#4caf50",
                "#8bc34a",
                "#cddc39",
            ],
            expanded: ["1"],
            selected: ["1"],
            // selected: [],
        };
    }
    handleSelect = (e, color, index) => {
        this.props.handleSelect({ color, index }, "color_filter");
    };
    handleClearFilter = () => {
        this.props.handleClearFilter("color_filter");
    };
    handleToggle = (event, nodeIds) => {
        this.setState({
            expanded: nodeIds,
        });
    };
    handleSelectAccordion = (event, nodeIds) => {
        this.setState({
            selected: nodeIds
        })
    }

    render() {
        return (
            <div className="color-filter-sec">
                <TreeView
                    defaultCollapseIcon={<RemoveIcon />}
                    defaultExpandIcon={<AddIcon />}
                    expanded={this.state.expanded}
                    selected={this.state.selected}
                    onNodeToggle={this.handleToggle}
                    onNodeSelect={this.handleSelectAccordion}
                >
                    <TreeItem nodeId="1" label="Color">
                        <div className="color-picker">
                            <ColorPicker
                                color_pack={this.state.color}
                                selected={this.props.selected}
                                handleSelect={this.handleSelect}
                            />
                        </div>
                    </TreeItem>
                </TreeView>
            </div>
        );
    }
}
export default ColorFilter;
