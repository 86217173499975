import React from "react";
import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import { Container, Row, Col } from "reactstrap";

class Faq extends React.Component {
    
    componentDidMount() {
        document.body.classList.add("page");
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.wrapper.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.remove("page");
    }

    render(){
        return (
            <>
            <CustomNavbar />

            <div className="wrapper" ref="wrapper">
                <div className ="main">
                    <div className="space-100" />
                    <section className="faq-page all-pages section">
                        <Container>
                            <Row>
                                <Col>
                                    <div className="all-pages-header">
                                        <h1><span>FAQ</span></h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>What is your return policy?</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>All products purchased on site are custom made and printed just for you. Returns/exchanges can not be made unless the product is defective or damaged. Please contact us right away with your order number and email and we will remedy this by replacing the defective/damaged item.</p>
                                       
                                        <p>If something you received is not up to our usual standards please contact us and we will gladly review this.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>When will my order ship?</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>All products purchased on site are custom made and printed just for you. Returns/exchanges can not be made unless the product is defective or damaged. Please contact us right away with your order number and email and we will remedy this by replacing the defective/damaged item.</p>
                                        
                                        <p>If something you received is not up to our usual standards please contact us and we will gladly review this.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>Can I ship to an address outside of the United States?</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>Yes. We do ship internationally and the list of countries we ship to will be in the dropdown at checkout. Please note that the order recipient is the importer of record and must comply with all laws and regulations of the destination country. We are not responsible for import taxes, customs duties, and fees levied by the destination country. Additional charges for custom clearance must be borne by the recipient; we have no control over these charges and cannot predict what they may be.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>Do products run "true to size"?</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>Sizes vary from brand to brand, product to product. A large in one brand may be equal to an XL in another brand. Each item includes its own size chart.</p>
                                        <p>You can find the size chart located near the drop-down where you select the size you would like. We recommend taking a like-garment you currently wear and obtaining its measurements as a helpful comparison.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>How will my order ship?</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>All orders ship via United States Postal Service.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>What can I use as a form of payment?</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>We accept American Express, Discover, MasterCard, and Visa. We do not accept payment by Purchase Order or check.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>How can I check the status of my order?</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>Click the Track Order link at the bottom of the page. If you checked out as a guest, input your order number, the last name on the shipping address and the zip code of the shipping address. If you have an account, you can pull up your full order history.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>How do I cancel my order?</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>Products purchased on website are custom made and printed just for you. Therefore, cancellations must be made within 2 hours of placing your order. Click on Help then Track Order link at the bottom left of the page to pull up your order. In the order details on the lower left, click Cancel This Order. You will see the status of the order change to Cancelled.</p>
                                        <p>You may also contact us for help with cancelling your order. However, please contact us within 2 hours of placing your order for us to guarantee cancellation of your order.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>Will I get an order confirmation?</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>Yes! After you place your order, you will receive an order confirmation email that will provide your order number, price, item description(s), size(s), and quantity.</p>
                                        <p>If you accidentally ordered the wrong item, size or quantity, you have 2 hours to cancel your order before we start to custom-produce it.</p>
                                        <p>If you do not receive the email, please check your spam folder. We will send a separate shipping confirmation email once the order ships from our facility.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="all-pages-title">
                                        <h3>How do I change my order?</h3>
                                    </div>
                                    <div className="all-pages-content">
                                        <p>All products purchased from site are custom made and printed just for you. Because your order is custom made, we hold your order for 2 hours for you to contact us with any changes to your address/billing, shipping, or the product size or color. You may contact Customer Service using our chat system from the home page in the lower right corner (shows in orange).</p>
                                        <p>You can also cancel your order up until 2 hours after the order is placed and place a new order with the changes you would like made. If it has been more than 2 hours contact us right away. If the order has gone to production for printing, unfortunately, we will not be able to make changes to your order or cancel your order.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </div>

            <CustomFooter />
            </>
        )
    }
            
}

export default Faq;