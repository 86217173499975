export const APP_LOAD = 'APP_LOAD';

export const FETCH_BASECAT_WITH_STYLE = 'FETCH_BASECAT_WITH_STYLE';
export const SAVE_SELECTED_BASECATEGORY = 'SAVE_SELECTED_BASECATEGORY';
export const FETCH_PRODUCT_BY_STYLE = 'FETCH_PRODUCT_BY_STYLE';




// store owner auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
// export const STORE_OWNER_REGISTER = 'STORE_OWNER_REGISTER';
export const CUSTOMER_REGISTER = 'CUSTOMER_REGISTER';
export const SEND_EMAIL_FOR_RESET_PASSWORD = 'SEND_EMAIL_FOR_RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_OLD_PASSWORD = 'CHANGE_OLD_PASSWORD';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const ADD_SHIPPING_ADDRESS = 'ADD_SHIPPING_ADDRESS';
export const EDIT_SHIPPING_ADDRESS = 'EDIT_SHIPPING_ADDRESS';
export const CLEAR_EDIT_SHIPPING_ADDRESS = 'CLEAR_EDIT_SHIPPING_ADDRESS';
export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS';
export const DELETE_SHIPPING_ADDRESS = 'DELETE_SHIPPING_ADDRESS';


export const LOGIN_OTP_REQUEST = 'LOGIN_OTP_REQUEST';
export const REG_OTP_REQUEST = 'REG_OTP_REQUEST';


export const CLEAR_NOTIFICATION_MSG = 'CLEAR_NOTIFICATION_MSG';


// style selection actions
export const CHANGE_CREATE_STORE_STEP = 'CHANGE_CREATE_STORE_STEP';
export const SET_CURRENT_STYLE_ITEM = 'SET_CURRENT_STYLE_ITEM';
export const SET_STYLE_FILTER = 'SET_STYLE_FILTER';
export const RESET_STYLE_FILTER = 'RESET_STYLE_FILTER';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const ALL_PRODUCT_SELECTED = 'ALL_PRODUCT_SELECTED';
export const ALL_PRODUCT_UNSELECTED = 'ALL_PRODUCT_UNSELECTED';
export const SET_STYLE_SORT_KEY = 'SET_STYLE_SORT_KEY';
export const SET_VISIBLE_STYLE_ITEM_NUMBER = 'SET_VISIBLE_STYLE_ITEM_NUMBER';



/***add logo actions***/
export const SET_LOGO_DETAILS = 'SET_LOGO_DETAILS';
export const EDIT_LOGO_DETAILS = 'EDIT_LOGO_DETAILS';




// deprecated actions
export const STEP_BACK_TO_CATEGORY_SELECTION = 'STEP_BACK_TO_CATEGORY_SELECTION';
export const STEP_BACK_TO_STYLE_SELECTION = 'STEP_BACK_TO_STYLE_SELECTION';
export const SHOW_STYLE_TO_ADD_LOGO = 'SHOW_STYLE_TO_ADD_LOGO';
