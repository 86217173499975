import React from "react";
import CustomFooter from "components/Footers/CustomFooter.js";
import CustomNavbar from "components/Navbars/CustomNavbar.js";
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { Link } from "react-router-dom";
import agent from '../../services/api-endpoint'
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import MyMapComponent from '../../components/Custom/google-map/index';

class ContactUs extends React.Component {
    
    componentDidMount() {
        document.body.classList.add("page");
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.wrapper.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.remove("page");
    }

    render(){
        const {site_slug} = agent;
        return (
            <>
            <CustomNavbar />

            <div className="wrapper" ref="wrapper">
                <div className ="main">
                    <div className="space-100" />
                    <section className="section all-pages contact-page-header-section">
                        <Container>
                                <Row>
                                    <Col>
                                        <div className="all-pages-header">
                                            <h1><span>Contact</span> <span>Us</span></h1>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                    </section>
                    <section className="section all-pages contact-us-page">
                        <Container className="contact-page-first-section">
                            <Row>
                                <Col md="5">
                                    <div className="contact-form-section">
                                        <h5 className="sub-title">Please drop us a line if you have any query</h5>
                                        <h1 className="title">Get in touch</h1>
                                        <form>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item sm={12}>
                                                    <TextField
                                                        id="input-with-icon-grid"
                                                        label="User Name"
                                                        placeholder="Enter User Name"
                                                        type="text"
                                                        fullWidth
                                                        name="first_name"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item sm={12}>
                                                    <TextField
                                                        id="input-with-icon-grid"
                                                        label="Email"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        fullWidth
                                                        name="first_name"
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item sm={12}>
                                                    <TextField
                                                        id="input-with-icon-grid"
                                                        label="Subject"
                                                        placeholder="Enter Subject"
                                                        type="text"
                                                        fullWidth
                                                        name="first_name"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item sm={12}>
                                                    <div className="custom-material-textarea-section">
                                                        <textarea className="custom-material-textarea" id="message" placeholder="Enter Message" rows="4"></textarea>
                                                        <div className="form-element-bar"></div>
                                                        <label htmlFor="message" className="form-element-label">Message</label>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} alignItems="flex-end">
                                                <Grid item sm={12}>
                                                    <Button
                                                        className="btn-round contact-btn"
                                                        color="primary"
                                                        onClick={this.handleRegister}
                                                        size="lg"
                                                    >
                                                    Submit
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </div>
                                </Col>
                                <Col md="7">
                                    <div className="contact-header-left-sec">
                                        <div className="main-title-sec">
                                            <h5 className="sub-title">24-HOUR SUPPORT</h5>
                                            <h1 className="main-title">Contact Us Details</h1>
                                            <div className="namaste-logo-section">
                                                <img src={require('assets/img/Namaste_logo.png')} />
                                            </div>
                                        </div>
                                        
                                        <div className="contact-details-sec-group">
                                            <div className="single-item">
                                                <div className="img-sec">
                                                    <img src ={require('assets/img/adress.png')} />
                                                </div>
                                                <div className="des">
                                                    <h5 className="title">Corporate Office</h5>
                                                    <p>6441 Inkster Road <br/>Suite 232, Bloomfield Hills, MI 48301.</p>
                                                </div>
                                            </div>
                                            <div className="single-item">
                                                <div className="img-sec">
                                                    <img src ={require('assets/img/return.png')} />
                                                </div>
                                                <div className="des">
                                                    <h5 className="title">Returns</h5>
                                                    <p>Campus Wear Direct</p>
                                                </div>
                                            </div>
                                            <div className="single-item">
                                                <div className="img-sec">
                                                    <img src ={require('assets/img/email.png')} />
                                                </div>
                                                <div className="des">
                                                    <h5 className="title">Email</h5>
                                                    <p>support@campuswearoutfitter.com</p>
                                                </div>
                                            </div>
                                            <div className="single-item">
                                                <div className="img-sec">
                                                    <img src ={require('assets/img/phone.png')} />
                                                </div>
                                                <div className="des">
                                                    <h5 className="title">Phone</h5>
                                                    <p>******</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section google-map-form-section">
                        <div className="google-map-form-container">
                            <Row>
                                <Col md="12">
                                    <div className="map-section">
                                        <MyMapComponent/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </div>
            </div>

            <CustomFooter />
            </>
        )
    }
            
}

export default ContactUs;