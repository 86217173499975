import React, { Component } from 'react'
import mergeImages from 'merge-images'

import axios from 'axios'

// import Sharp from 'sharp'
// const sharp = require('sharp');

// import head from '../image/head1.png';
// import eyes from '../image/eyes1.png';
// import mouth from '../image/mouth1.png';

// import tshirt from '../assets/img/tshirt.png';
import logo1 from '../../assets/img/logo/logo1.jpg';
import logo2 from '../../assets/img/logo/logo2.jpg';
import logo3 from '../../assets/img/logo/logo3.jpg';
import logo4 from '../../assets/img/logo/logo4.jpg';




class ImageFilter extends Component {
    constructor(){
        super()
        this.state = {
            // src: tshirt,
            imgSrc: 'https://www.ssactivewear.com/Images/Color/52290_f_fm.jpg', 
            sku: null,
            err: '',
            logoX: '100',
            logoY: '135',
            logo: [logo1, logo2, logo3, logo4],
            activeLogo: null,
            logoH: '',
            logoW: '',
            resizedLogo: null,
            imgText: 'Demo',
            showTextImg: false,
            textLogo: null,
            fontSize: 20,
            fontColor: '#000',
            textColor: '#000000',
        }
        this.baseState = this.state
    }
    componentDidMount = () => {
        // this.fetchImg()

        // this.toDataUrl(this.state.imgSrc, function(Base64) {
        //     this.setState({ sku: Base64 })
        // })

        // var trace = new potrace.Potrace()
        // trace.setParameters({
        //     threshold: 128,
        //     color: '#880000'
        // });
           
        // trace.loadImage('../assets/img/logo4.jpg', function(err) {
        //     if (err) throw err;
           
        //     trace.getSVG(); // returns SVG document contents
        //     trace.getPathTag(); // will return just <path> tag
        //     trace.getSymbol('traced-image'); // will return <symbol> tag with given ID
        // });
        // potrace.trace('../assets/img/tshirt.jpg', function(err, svg) {
        //     if (err) throw err;
        //     console.log('potrace')
        //     fs.writeFileSync('../assets/img/output.svg', svg);
        // });
    }

    handleChange = e => {
        let {name, value} = e.target
        this.setState({
            [name]: value
        })
    }
    fetchImg = () => {
        const self = this
        this.toDataUrl(this.state.imgSrc, Base64 => {
            let {logo, logoX, logoY, activeLogo, imgSrc, resizedLogo, textLogo, showTextImg} = self.state
            const target = showTextImg ? textLogo : resizedLogo || logo[activeLogo]
            mergeImages([
                {src: Base64},
                {src: target, x: logoX, y: logoY}
            ])
            .then(src => {
                self.setState({ sku: src })
            })
            .catch(err => {
                self.setState({ err: err.toString() })
            });
        });
    }
    toDataUrl = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
    
    handleSubmit = e => {
        this.fetchImg()
    }
    handleLogoSelect = i => {
        if(i !== this.state.activeLogo){
            this.setState({ activeLogo: i, resizedLogo: null }, () => {
                this.fetchImg()
            })
        }
    }
    handleResize = () => {
        const self = this
        if(this.state.activeLogo || this.state.activeLogo === 0){
            const postdata = {
                height: this.state.logoH,
                width: this.state.logoW,
                img: this.state.logo[this.state.activeLogo]
            }
            // console.log(postdata)
            axios.post('http://localhost:8000/api/resizeImg', postdata)
            .then(res => {
                const convertedRes = `data:image/jpeg;base64,${res.data}`
                this.setState({ resizedLogo: convertedRes })
                
                self.fetchImg()
            })
            .catch(err =>  err.response.data)
        }
    }
    handleReset = () => this.setState(this.baseState)
    handleTextImage = () => {
        const postdata = {
            text: this.state.imgText,
            fontSize: this.state.fontSize,
            color: this.state.textColor
        }
        axios.post('http://localhost:8000/api/textImg', postdata)
        .then(res => {
            const convertedRes = `data:image/jpeg;base64,${res.data}`
            this.setState({
                textLogo: convertedRes,
                showTextImg: true,
            })
            this.fetchImg()
        })
        .catch(err =>  err.response.data)
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="img-show-box row">
                        <h4>Image filter</h4>
                        <img src={this.state.sku || this.state.imgSrc} alt="avatar.png"/*  width="350" */ />
                        {this.state.err && <p>{this.state.err} </p>}
                    </div>
                    <div className="col-md-5 logo-section">
                        <h4>Logo</h4>
                        <div className="img-group">
                        {
                            this.state.logo.map((item, i) => 
                                <img 
                                    key={i}
                                    src={item} 
                                    alt="avatar.png" 
                                    width="350"
                                    onClick={() => this.handleLogoSelect(i)}
                                />
                            )
                        }
                        </div>
                        <div>
                            <h4>Create Text Image:</h4>
                            <input 
                                name="imgText"
                                onChange={this.handleChange}
                                value={this.state.imgText}
                            />
                            <br/>
                            <input 
                                name="textColor"
                                onChange={this.handleChange}
                                value={this.state.textColor}
                            />
                            <br/>
                            <input 
                                name="fontSize"
                                onChange={this.handleChange}
                                value={this.state.fontSize}
                            />
                            <br/>
                            <button onClick={this.handleTextImage}>Set logo</button>
                            {/* {
                                this.state.textLogo &&
                                <img src={this.state.textLogo} />
                            } */}
                        </div>
                    </div>
                </div>

                <div>
                    <button onClick={this.handleReset}>Reset</button>
                </div>

                <div>
                    Logo X: <input 
                        type="text"
                        name="logoX"
                        value={this.state.logoX}
                        onChange={this.handleChange}
                    />
                    <br/>
                    Logo Y: <input 
                        type="text"
                        name="logoY"
                        value={this.state.logoY}
                        onChange={this.handleChange}
                    />
                    <br/>
                    <button
                        onClick={this.handleSubmit}
                    >submit</button>
                </div>
                <div>
                    Logo Width: <input 
                        type="text"
                        name="logoH"
                        value={this.state.logoH}
                        onChange={this.handleChange}
                    />
                    <br/>
                    Logo Height: <input 
                        type="text"
                        name="logoW"
                        value={this.state.logoW}
                        onChange={this.handleChange}
                    />
                    <br/>
                    <button
                        onClick={this.handleResize}
                    >resize</button>
                </div>
            </div>
        )
    }
}

export default ImageFilter
