import React from 'react';
import {
    Row,
    Col,
    Button,
    Modal, ModalHeader, ModalBody,
  } from "reactstrap";
import UploadMascotCondition from './upload-mascot-condition'
import EditUploadMascot from './upload-mascot-edit'
import FinalizeUploadMascot from './upload-mascot-finalize'



class UploadMascot extends React.Component{
    state = {
        mascotModal: false,
        uploadMascotCondition: true,
        editUploadMascot: false,
        finalizeUploadMascot: false
    }
    toggleMascot = () => {
        this.setState({
            mascotModal: !this.state.mascotModal
        });
    }

    uploadMascotcodition = () => {
        this.setState({
            uploadMascotCondition: true,
            editUploadMascot: false,
            finalizeUploadMascot: false
        })
    }
    edituploadmascot = () => {
        this.setState({
            uploadMascotCondition: false,
            editUploadMascot: true,
            finalizeUploadMascot: false
        })
    }

    finalizeuploadmascot = () =>{
        this.setState({
            uploadMascotCondition: false,
            editUploadMascot: false,
            finalizeUploadMascot: true
        })
    }

    render(){
        const {uploadMascotCondition, editUploadMascot, finalizeUploadMascot} = this.state
        const {showMascotModal, toggleMascot, modalClass} = this.props

        return (
            <Modal isOpen={showMascotModal} toggle={toggleMascot} className={modalClass + " personalize-mascot-modal" } >
                <ModalHeader toggle={toggleMascot}>Image Upload</ModalHeader>
                <ModalBody>
                    {
                        uploadMascotCondition && !editUploadMascot && !finalizeUploadMascot && 
                        <UploadMascotCondition 
                            edituploadmascot={this.edituploadmascot} 
                        />
                    }
                    {
                        !uploadMascotCondition && editUploadMascot && !finalizeUploadMascot && 
                        <EditUploadMascot 
                            finalizeuploadmascot = {this.finalizeuploadmascot} 
                            uploadmascot={this.uploadMascotcodition} 
                        />
                    }
                    {
                        !uploadMascotCondition && !editUploadMascot && finalizeUploadMascot && 
                        <FinalizeUploadMascot 
                            edituploadmascot={this.edituploadmascot} 
                        />
                    }
                    
                </ModalBody>
            </Modal>
        )
    }
}

export default UploadMascot