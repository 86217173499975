import {
    Container,
    Row,
    Col
} from "reactstrap";

import React from 'react';

class ColorPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39"],
            // selected: [1, 4, 5]
        }
    }

    componentDidMount = () => {
        const { color_pack } = this.props;
        console.log(color_pack)
        if (color_pack !== undefined && color_pack.length > 0) {
            this.setState({
                color: color_pack
            })
        }
    }
    colorSelect = (e, color, index) => {
        // console.log(index)
        // e.preventDefault();
        // if (this.state.selected.indexOf(index) === -1) {
        //     this.setState(prevState => ({
        //         selected: [...prevState.selected, index]
        //     }));
        // } else {
        //     const { selected } = this.state;
        //     const new_selected = selected.filter(clr_index => clr_index !== index);
        //     this.setState({
        //         selected: new_selected
        //     })
        // }
        this.props.handleSelect(e, color, index);
    }
    render() {
        
        return (
            <div className="color-picker-sec">
                <Container>
                    <Row className="justify-content-md-center"> 
                        {
                            this.state.color.map((color, index) => {
                                return (

                                    <Col md={2} lg={2} key={index} className="color-picker-col">
                                        <div
                                            className="color-picker-wrap"
                                            onClick={e => this.colorSelect(e, color, index)}
                                        >
                                            <div
                                                className="color"
                                                style={{
                                                    msBoxShadow: `inset 0 0 0 14px ${color}`,
                                                    MozBoxShadow: `inset 0 0 0 14px ${color}`,
                                                    OBoxShadow: `inset 0 0 0 14px ${color}`,
                                                    WebkitBoxShadow: `inset 0 0 0 14px ${color}`,
                                                    boxShadow: `inset 0 0 0 14px ${color}`,
                                                    msTransition: "100ms box-shadow ease",
                                                    MozTransition: "100ms box-shadow ease",
                                                    OTransition: "100ms box-shadow ease",
                                                    WebkitTransition: "100ms box-shadow ease",
                                                    transition: "100ms box-shadow ease",
                                                }}
                                            >
                                                {
                                                    (this.props.selected || []).includes(color) &&
                                                    (
                                                        <svg viewBox="0 0 24 24">
                                                            <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" fill="white"></path>
                                                        </svg>
                                                    )
                                                    // this.state.selected.indexOf(index) !== -1 &&
                                                    // (
                                                    //     <svg viewBox="0 0 24 24">
                                                    //         <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" fill="white"></path>
                                                    //     </svg>
                                                    // )
                                                }
                                            </div>
                                        </div>
                                    </Col>

                                )
                            })
                        }
                    </Row>
                </Container>
            </div>
        )
    }
}

export default ColorPicker;