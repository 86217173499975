import React, { useEffect, useState } from "react";

const Notofication = props => {
  const [visible, setVisible] = useState(true);

  var timeOut = null

  useEffect(() => {
    timeOut = setTimeout(() => {
      setVisible(false);
      props.onExpire()
    }, props.delay);

    return () => clearTimeout(timeOut)
  }, [props.delay]);

  return visible ? <div className="notification-msg">{props.children}</div> : <div />;
};

export default Notofication;