import { Container, Row, Col } from "reactstrap";

import "assets/css/nucleo-icons.css";

import React from "react";
import Pagination from "@material-ui/lab/Pagination";

import Select from "react-select";
// import CategoryFilter from "./filter/category";
// import GenderFilter from "./filter/gender";
import SizeFilter from "./filter/size";
import ColorFilter from "./filter/color";
import CategoryFilter from "./filter/category";
import ManufacturerFilter from "./filter/manufacturer";
import LogoImageFilter from "./filter/logo-image";
import LogoLocationFilter from "./filter/logo-location";
import LogoTypeFilter from "./filter/logo-type";
import ProductItem from "components/Custom/product-item";
import CatalogCategory from "./catalog-category";

import agent from '../../services/api-endpoint';

class Catalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: ["3", "2", "1"],
      selected: ["3"],
      checked: true,
      color: ["#f44336", "#e91e63", "#9c27b0", "#673ab7"],
      singleSelect: null,
      filterItem: [],
      category: [],
      gender: [],
      size: [],
      features: [],
      manufacturer: [],
      logo_image: [],
      logo_location: [],
      logo_type: [],
      color_filter: [],
      products: [],
    };
  }
  componentDidMount() {
    document.body.classList.add("page");
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
  }
  componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate', prevProps, this.props)
    if(this.props.active)
    {
      let isEqual = (prevProps.seletedCat.length === this.props.seletedCat.length) && (prevProps.seletedCat.every(val => this.props.seletedCat.includes(val)));

      if(this.props.active && !isEqual){
        this.fetchProducts().then(res => {
          console.log('products', res)
          this.setState({ products: res.data })
        })
      }
    }
  }
  componentWillUnmount() {
    document.body.classList.remove("page");
  }
  fetchProducts = () => {
    let param = [
      {
        key: 'page',
        value: 1,
        in: 'query'
      },
      {
        key: 'style',
        value: (this.props.seletedCat || []).join(),
        in: 'query'
      },
    ];
    return new Promise((resolve, reject) => {
        agent.Product.getProductByStyle(param)
        .then(data => {
            console.log(data);
            if( data && ("data" in data )){
                resolve(data);
            }else{
                reject(false);
            }
        });
    });
  }

  handleFilter = (item, exists) => {
    let { filterItem } = this.state;

    if (!exists) filterItem.push(item);
    else filterItem = filterItem.filter((v) => v.value !== item.value);

    this.setState({ filterItem });
  };
  handleSelect = (item, type) => {
    let selected = this.state[type],
      exists;

    if (type === "category" || type === "gender") {
      if (selected.includes(item.nodeID)) {
        selected = selected.filter((v) => v !== item.nodeID);
        exists = true;
      } else {
        selected.push(item.nodeID);
        exists = false;
      }
    } else if (type === "color_filter") {
      if (selected.includes(item.color)) {
        selected = selected.filter((clr) => clr !== item.color);
        exists = true;
      } else {
        selected.push(item.color);
        exists = false;
      }
    } else {
      if (selected.some((v) => v.value === item.value)) {
        selected = selected.filter((v) => v.value !== item.value);
        exists = true;
      } else {
        selected.push(item);
        exists = false;
      }
    }

    this.setState(
      {
        [type]: selected,
      },
      () => {
        if (type === "color_filter") {
          let item_obj = {
            label: item.color,
            value: item.color,
            type: type,
          };
          this.handleFilter(item_obj, exists);
        } else {
          item["type"] = type;
          this.handleFilter(item, exists);
        }
      }
    );
  };
  handleClearFilter = (type) => {
    let { filterItem } = this.state;
    filterItem = filterItem.filter((v) => v.type !== type);
    this.setState({ filterItem, [type]: [] });
  };
  handleFilterChange = (item) => {
    let { filterItem } = this.state,
      selected = this.state[item.type];

    filterItem = filterItem.filter((v) => v.value !== item.value);

    if (item.type === "category" || item.type === "gender") {
      selected = selected.filter((v) => v !== item.nodeID);
    } else if (item.type === "color_filter") {
      selected = selected.filter((v) => v !== item.value);
    } else {
      selected = selected.filter((v) => v.value !== item.value);
    }
    this.setState({ filterItem, [item.type]: selected });
  };

  // for search box
  handleSearchChange = (index, item) => {};

  render() {
    return (
      <>
        <section className="section section-pills catalog" ref="wrapper">
          <Container fluid>
            <Row>
              <Col lg="3" md="3">
                <div className="refine-search-sec">
                  <CategoryFilter
                    selected={this.state.features}
                    handleSelect={this.handleSelect}
                  />
                  <hr />
                  <ManufacturerFilter
                    selected={this.state.manufacturer}
                    handleSelect={this.handleSelect}
                  />
                  <hr />
                  <SizeFilter
                    selected={this.state.size}
                    handleSelect={this.handleSelect}
                  />
                  <hr />
                  <LogoImageFilter
                    selected={this.state.logo_image}
                    handleSelect={this.handleSelect}
                  />
                  <hr />
                  <ColorFilter
                    selected={this.state.color_filter}
                    handleSelect={this.handleSelect}
                    handleClearFilter={this.handleClearFilter}
                  />
                  <hr />
                  <LogoLocationFilter
                    selected={this.state.logo_location}
                    handleSelect={this.handleSelect}
                  />
                  <hr />
                  <LogoTypeFilter
                    selected={this.state.logo_type}
                    handleSelect={this.handleSelect}
                  />
                </div>
              </Col>
              <Col lg="9" md="9">
                <div className="catalog-right-sec">
                  <div className="search-item-filter-count-sec">
                    <Row className="justify-content-md-between align-items-md-center">
                      <Col md="8">
                        <div>
                          <p>
                            Items <span>1 - 20</span> of <span>324</span>{" "}
                            results
                            {this.state.filterItem.length > 0 ? " for" : ""}
                          </p>
                          {this.state.filterItem.map((item, index) => (
                            <span
                              className="filter-tag"
                              key={index}
                              style={{
                                backgroundColor:
                                  item.type === "color_filter"
                                    ? item.value
                                    : "#242F67",
                              }}
                            >
                              {item.label}
                              <i
                                className="tim-icons icon-simple-remove"
                                onClick={() => this.handleFilterChange(item)}
                              ></i>
                            </span>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="search-item-sec">
                    <CatalogCategory
                      items={this.state.products}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Catalog;
